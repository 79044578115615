<template>
  <div class="ExamQuestionItemContainer">
    <div class="leftCenter vmg16">
      <span>{{ qIndex + 1 }}、{{ data.description }}</span>
    </div>
    <nut-row class="answerBtns">
      <template v-for="(a,aIndex) in options" :key="aIndex">
        <nut-row class="answerBtn leftCenter"
                 v-if="optionState[aIndex] === 0"
                 @click="onOptionClick(aIndex)"
        >
          <span>{{ optionNames[aIndex] }} {{ a }}</span>
        </nut-row>
        <nut-row class="activeBtn leftCenter"
                 v-if="optionState[aIndex] === 1"
                 @click="onOptionClick(aIndex)"
        >
          <span>{{ optionNames[aIndex] }} {{ a }}</span>
        </nut-row>
        <nut-row class="answerBtn leftCenter rightAnswer" v-if="optionState[aIndex] === 2">
          <nut-col :span="22">
            <span>{{ optionNames[aIndex] }} {{ a }}</span>
          </nut-col>
          <nut-col :span="2">
            <img src="https://cdn.waityou.online/fdda4e98-5f61-6c97-012e-b96534b54759.png"/>
          </nut-col>
        </nut-row>
        <nut-row class="answerBtn leftCenter wrongAnswer" v-if="optionState[aIndex] === 3">
          <nut-col :span="22">
            <span>{{ optionNames[aIndex] }} {{ a }}</span>
          </nut-col>
          <nut-col :span="2">
            <img src="https://cdn.waityou.online/ca60c980-c1c0-7140-ba99-b63882f28ae3.png"/>
          </nut-col>
        </nut-row>
      </template>
    </nut-row>

    <nut-row class="judge" v-if="judgeMode">
      <span>正确答案:</span>
      <span class="greenSpan">{{ formatOptions(data.answers) }}</span>
    </nut-row>
    <nut-row class="judge" v-if="judgeMode">
      <span>你的答案:</span>
      <span :class="judgeResult?'greenSpan':'redSpan'">
          {{ yourAnswers }}
        </span>
    </nut-row>
    <nut-row class="judge" v-if="judgeMode && analysisVisible">
      <span style="color: #999999">解析: {{ data.analysis }}</span>
    </nut-row>
<!--    <nut-row class="correct leftCenter" v-if="judgeMode">-->
<!--      <nut-col :span="12" class="leftCenter">-->
<!--        <span @click="analysisVisible=true">{{ data.analysis ? '查看解析' : '' }}</span>-->
<!--      </nut-col>-->
<!--      <nut-col :span="12" class="rightCenter">-->
<!--        <img src="https://cdn.waityou.online/27f87f4f-5aa1-b638-9c15-a4b12c0c8b702"/>-->
<!--        <span @click="$emit('correct')">题目纠错</span>-->
<!--      </nut-col>-->
<!--    </nut-row>-->
    <nut-row class="qSource leftCenter">
      <span>来源：{{ data.source }}</span>
    </nut-row>
  </div>
</template>

<script>
export default {
  name: "ExamQuestionItem",
  data() {
    return {
      analysisVisible: true,
      judgeMode: false,
      singleChoice: false,//单选题
      judgeResult: false,
      answered: false,
      yourAnswers: '',
      optionNames: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'],
      options: [],
      optionState: {
        //0未选、1选中、2正确、3错误
      },
      yourSelected: []
    }
  },
  props: {
    qIndex: {
      type: Number,
      default: 0
    },
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  methods: {
    getQuestionId() {
      return this.data.id;
    },
    getYourAnswer() {
      if (this.yourSelected && this.yourSelected.length > 0) {
        return this.yourSelected;
      }
      let us = []
      for (let i in this.options) {
        if (this.optionState[i] == 1) {
          us.push(this.optionNames[i])
        }
      }
      return us;
    },
    isAnswered() {
      return this.answered;
    },
    judgeAnswer() {
      if (this.judgeMode) {
        //已经完成判题
        return true;
      }
      //原地判题
      this.judgeMode = true;
      this.judgeResult = true;

      let answers = JSON.parse(this.data.answers);
      this.yourSelected = [];
      for (let i in this.options) {
        let op = this.optionNames[i]
        if (this.optionState[i] == 1) {
          this.yourSelected.push(this.optionNames[i])
          //已选择
          if (answers.includes(op)) {
            this.optionState[i] = 2;
          } else {
            this.optionState[i] = 3;
            this.judgeResult = false;
          }
        }
      }
      if (this.yourSelected.length != answers.length) {
        this.judgeResult = false;
      } else {
        for (let s of this.yourSelected) {
          if (!answers.includes(s)) {
            this.judgeResult = false;
          }
        }
      }
      this.yourAnswers = this.yourSelected.join(',')
      this.$emit('updateJudgeState', this.qIndex, this.judgeResult ? 0 : 1)
      return this.judgeResult;
    },
    onOptionClick(i) {
      if (this.judgeMode) {
        //已经判题，不支持再答题
        return;
      }
      let currentState = this.optionState[i];
      if (this.singleChoice) {
        //非多选清除其他已选择的
        for (let j in this.options) {
          if (this.optionState[j] === 1) {
            this.optionState[j] = 0;
          }
        }
      }else{
        this.optionState[i] = 0;
      }
      this.answered = false;
      if (currentState == 0) {
        this.optionState[i] = 1;
        this.answered = true;
        this.$emit('updateChooseState', this.qIndex, 1)
      } else {
        this.$emit('updateChooseState', this.qIndex, 0)
      }
    },
    initOptions() {
      this.options = JSON.parse(this.data.options)
      for (let i in this.options) {
        this.optionState[i] = 0;
      }
    },
    formatOptions(ops) {
      return JSON.parse(ops).join(',')
    },
  },
  mounted() {
    this.initOptions()
    this.singleChoice = this.data.type != 'MULTIPLE';
  }
}
</script>

<style lang="scss" scoped>
.redSpan {
  color: #FF202E;
}

.greenSpan {
  color: #009A5E;
}

.ExamQuestionItemContainer {
  display: flex;
  flex-direction: column;
  width: 100%;


  .qSource {
    margin: 20px 0;

    span {
      background-color: #E6F8ED;
      color: #009A5E;
      font-size: $miniFontSize;
      padding: 2px 4px;
      border-radius: 4px;

    }
  }

  .correct {
    color: #BAC4D0;
    font-size: $smallerFontSize;
    margin-top: 20px;

    img {
      width: 14px;
      margin-right: 5px;
    }
  }

  .judge {
    margin-top: 10px;
    font-size: $smallerFontSize;

    span {
      padding-right: 5px;
    }
  }

  .answerBtns {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    font-size: $smallerFontSize;

    .activeBtn {
      padding: 8px 0;
      border: none;
      //height: 36px;
      margin: 5px 0;
      width: 100%;
      color: #1B36D2;
      background-color: rgba(27, 54, 210, 0.1);
      border-radius: 8px;

      span {
        margin-left: 10px;
      }

      img {
        width: 14px;
      }
    }

    .answerBtn {
      padding: 8px 0;
      color: black;
      margin: 5px 0;
      width: 100%;
      border: none;
      background-color: #F9F9F9;
      border-radius: 8px;

      span {
        margin-left: 10px;
      }

      img {
        width: 14px;
      }
    }

    .rightAnswer {
      background-color: #E6F8ED;
      color: #009A5E;
    }

    .wrongAnswer {
      background-color: #FFEAEB;
      color: #FF202E;
    }
  }
}
</style>