<template>
  <div class="SettingViewContainer">
    <nut-row className="wbg brd16 pd16 vmg8 bothCenter">
      <nut-col :span="6" class="leftCenter">
        <span class="baseFont">头像</span>
      </nut-col>
      <nut-col :span="18" class="rightCenter">
        <CustomOssUploader @onUploadedFiles="onUpdateAvatar" :key="avatarVersion">
          <img :src="avatar" style="width: 30px;border-radius: 15px;" class="omg8">
        </CustomOssUploader>
        <img src="https://cdn.waityou.online/cf1f1d55-c597-eb30-1b08-625fb7decc5e.png" style="width: 6px"/>
      </nut-col>
    </nut-row>
    <nut-row className="wbg brd16 pd16 vmg8 bothCenter">
      <nut-col :span="6" class="leftCenter">
        <span class="baseFont">名字</span>
      </nut-col>
      <nut-col :span="18" class="rightCenter" @click="$router.push('/my/setting/name')">
        <span class="baseFont omg8" style="color:#999999;">{{ userName }}</span>
        <img src="https://cdn.waityou.online/cf1f1d55-c597-eb30-1b08-625fb7decc5e.png" style="width: 6px"/>
      </nut-col>
    </nut-row>
    <nut-row className="wbg brd16 pd16 vmg8 bothCenter">
      <nut-col :span="6" class="leftCenter">
        <span class="baseFont">手机号</span>
      </nut-col>
      <nut-col :span="18" class="rightCenter" @click="$router.push('/my/setting/phone')">
        <span class="baseFont omg8" style="color:#999999;">{{ phone }}</span>
        <img src="https://cdn.waityou.online/cf1f1d55-c597-eb30-1b08-625fb7decc5e.png" style="width: 6px"/>
      </nut-col>
    </nut-row>
    <nut-row className="wbg brd16 pd16 vmg8 bothCenter">
      <nut-col :span="6" class="leftCenter">
        <span class="baseFont">修改密码</span>
      </nut-col>
      <nut-col :span="18" class="rightCenter" @click="$router.push('/my/setting/pass')">
        <img src="https://cdn.waityou.online/cf1f1d55-c597-eb30-1b08-625fb7decc5e.png" style="width: 6px"/>
      </nut-col>
    </nut-row>
  </div>
</template>

<script>
import api from "@/api";
import CustomOssUploader from "@/components/CustomOssUploader"

export default {
  name: "SettingView",
  components: {CustomOssUploader},

  data() {
    return {
      avatar: 'https://smarket.oss-cn-beijing.aliyuncs.com/default/avatar.png',
      userName: '-',
      avatarVersion:0,
      phone:null,
    }
  },
  methods: {
    getUserInfo() {
      api.getUserInfo().then(ret => {
        this.phone = ret.phone;
        this.avatar = ret.avatar;
        this.userName = ret.nickName;
        this.realName = ret.nickName;
        this.level = ret.level;
        this.nextLevel = ret.nextLevel;
        this.percent = ret.levelPercent;
        this.todayIncrease = ret.todayIncrease;
        this.avatarVersion++;
      })
    },
    onUpdateAvatar(files) {
      api.updateInfo({
        avatar: files[0],
        nickName: this.userName
      }).then(() => {
        this.getUserInfo();
      })
    },
  },
  mounted() {
    this.getUserInfo()
  }
}
</script>

<style lang="scss" scoped>
.SettingViewContainer {
  display: flex;
  flex-direction: column;
  background-color: #F4F6F9;
  padding: 16px;
  min-height: 100vh;
}
</style>