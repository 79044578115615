<template>
  <div class="BatterVisitViewContainer">
    <div id="mapContainer" style="width: 100%;height: 100vh"></div>
  </div>
  <SearchBar class="visitSearchBar" @search="onSearch"/>
  <!--  横排-->
  <div class="stationCards" v-if="!listStationVisible">
    <swiper
        @swiper="onSwiper"
        slides-per-view="2"
        :space-between="spaceBetween"
    >
      <swiper-slide v-for="st in stations"
                    :key="st.id">
        <StationCard class="StationCardItem"
                     @upClick="showStationDetail(st)"
                     @onAction="onAction"
                     :data="st"
                     :myLocation="myLocation"
                     :menus="menus" :action-btn="actionBtn"/>
      </swiper-slide>
    </swiper>
  </div>
  <nut-popup position="bottom"
             round
             :overlay-style="{background:'transparent'}"
             v-model:visible="stationSimpleDataVisible">
    <StationSimpleInfo :menus="menus"
                       v-if="stationSimpleDataVisible"
                       :data="currentStation"
                       :myLocation="myLocation"
                       :evaluate="evaluate"
                       @like="likeStation"
                       @disLike="uncollect"
                       @collect="collectStation"
                       @disCollect="uncollect"
                       :action-btn="actionBtn" @onAction="onAction"/>
  </nut-popup>
  <!--  竖排-->
  <nut-popup position="bottom"
             round
             style="max-height: 70vh"
             :overlay-style="{background:'transparent'}"
             v-model:visible="listStationVisible">
    <div class="listStationContainer">
      <nut-row class="bothCenter smallFont vpd8">
        <nut-col :span="12" class="bothCenter">
          <div class="selector bothCenter" @click="departmentVisible=true;">
            <span>{{ selectedRegionName }}</span>
            <nut-icon name="triangle-down" size="14"></nut-icon>
          </div>
        </nut-col>
        <nut-col :span="12" class="bothCenter">
          <nut-popover v-model:visible="distanceSelectorVisible" @choose="onChooseDistanceType" :list="distanceList">
            <template #reference>
              <div class="selector bothCenter">
                <span>站点距离</span>
                <nut-icon name="triangle-down" size="14"></nut-icon>
              </div>
            </template>
          </nut-popover>
        </nut-col>
      </nut-row>
      <div class="divider"></div>
      <div style="height: 70vh;overflow-y: scroll">
        <ListStationItem v-for="(s,si) in stationsWithDistance"
                         :my-location="myLocation"
                         @click="toRowModeAction(s.station,si)"
                         :data="s" :key="s.id"/>
      </div>
    </div>
  </nut-popup>
  <nut-picker
      :visible="departmentVisible"
      @close="departmentVisible=false"
      :columns="departments"
      @confirm="onDepartmentSelected"
      title="区划选择"
  />
  <img class="showModeSwitchBtn"
       v-if="!listStationVisible"
       @click="toListMode"
       src="https://cdn.waityou.online/2c435056-29ee-6df1-c4a6-d9395f233423.png"/>
  <img class="showModeSwitchBtn2"
       v-if="listStationVisible"
       @click="toRowMode"
       src="https://cdn.waityou.online/98223f8a-6174-a536-7f89-fab4f345979c.png"/>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";
import SearchBar from "@/components/visit/SearchBar";
import StationCard from "@/components/station/StationCard";
import StationSimpleInfo from "@/components/station/StationSimpleInfo";
import ListStationItem from "@/components/station/ListStationItem";
import api from "@/api";
import {Toast} from "@nutui/nutui";
import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/css';

export default {
  name: "StationMapView",
  components: {StationCard, SearchBar, StationSimpleInfo, ListStationItem, Swiper, SwiperSlide},
  props: {
    stationType: {
      type: Number,
      default: 3,
    },
    menus: {
      type: Array,
      default: () => {
        return []
      }
    },
    actionBtn: {
      type: String,
      default: '-'
    },
    evaluate: {
      type: Object,
      default: () => {
        return {
          type: 'STATION_VISIT',
          name: '参观评价'
        }
      }
    },
  },
  computed: {
    stationsWithDistance() {
      if (this.myLocation == null) {
        return this.stations;
      }
      switch (this.distanceType) {
        case 0:
          return this.stations;
        case 1:
          return this.stations.filter(s => s?.distance <= 500);
        case 2:
          return this.stations.filter(s => s?.distance <= 2000);
        case 3:
          return this.stations.filter(s => s?.distance <= 5000);
      }
      return this.stations
    }
  },
  data() {
    return {
      swiperInstance:null,
      distanceType: 0,
      departments: [],
      departmentVisible: false,
      distanceList: [
        {name: '不限'},
        {name: '500米'},
        {name: '2千米'},
        {name: '5千米'}
      ],
      regionId: null,
      selectedRegionName: '行政区划',
      regionSelectorVisible: false,
      distanceSelectorVisible: false,

      currentStation: null,
      stationSimpleDataVisible: false,
      listStationVisible: false,
      stations: [],
      myLocation: null,
      spaceBetween: 340,
    }
  },

  methods: {
    onSwiper(swiper) {
      console.log('初始化成功')
      this.swiperInstance = swiper;
    },
    onChooseDistanceType(d) {
      this.distanceType = this.distanceList.findIndex(t => t.name === d.name);
    },
    onDepartmentSelected(s) {
      this.selectedRegionName = s.selectedOptions[0].text;
      this.departmentVisible = false;
      this.onSearch({
        departmentId: s.selectedOptions[0].value
      })
    },

    showStationDetail(station) {
      api.getStationDetail(station.station.id)
          .then(ret => {
            this.currentStation = ret;
            this.stationSimpleDataVisible = true;
          })
    },
    onSearch(query) {
      query.stationType = this.stationType;
      api.getStationByQuery(query)
          .then(list => {
            if (!list || list.length < 1) {
              Toast.warn('您选择的区域暂时没有可供选择站点')
              return
            }
            this.stations = list;
            this.listStationVisible = true;
            this.showNearStation()
          })
    },
    likeStation() {
      api.collect({
        refId: this.currentStation.station.id,
        collectType: 'LIKE_STATION',
      }).then(() => {
        api.getStationDetail(this.currentStation.station.id)
            .then(ret => {
              this.currentStation = ret;
            });
      })
    },
    uncollect(d) {
      api.uncollect({
        id: d.id
      }).then(() => {
        api.getStationDetail(this.currentStation.station.id)
            .then(ret => {
              this.currentStation = ret;
            });
      })
    },
    collectStation() {
      api.collect({
        refId: this.currentStation.station.id,
        collectType: 'STATION',
      }).then(() => {
        api.getStationDetail(this.currentStation.station.id)
            .then(ret => {
              this.currentStation = ret;
            });
      })
    },
    toRowModeAction(station, idx) {
      // this.$emit('action', station)
      // eslint-disable-next-line no-undef
      this.map.panTo(new AMap.LngLat(station.longitude, station.latitude))
      this.toRowMode();
      this.gotoSlide(idx)
    },
    gotoSlide(idx) {
      if (this.swiperInstance) {
        console.log('滑动到',idx)
        this.swiperInstance.slideTo(idx,1000);
      } else {
        console.log('稍后执行...')
        setTimeout(() => {
          this.gotoSlide(idx);
        }, 200)
      }
    },
    onAction(station) {
      this.$emit('action', station)
    },
    toListMode() {
      this.swiperInstance = null;
      this.stationSimpleDataVisible = false;
      this.listStationVisible = true;
      console.log('to list mode!')
    },
    toRowMode() {
      this.stationSimpleDataVisible = false;
      this.listStationVisible = false;
      console.log('to row mode!')
    },
    initMap() {
      api.getMapKey()
          .then(key => {
            this.initMapWithKey(key)
          })
    },
    initMapWithKey(mapKey) {
      AMapLoader.load({
        key: mapKey,             // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0",      // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ['AMap.Geolocation'],       // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap) => {
        this.map = new AMap.Map("mapContainer", {  //设置地图容器id
          mapStyle: "amap://styles/macaron",
          viewMode: "2D",    //是否为3D地图模式
          zoom: 16,           //初始化地图级别
          center: [120.140656, 30.301501], //初始化地图中心点位置
        });
        this.addMarkers(AMap);
        this.initPlugins(AMap);
      }).catch(e => {
        console.log(e);
      })
    },
    onLocateComplete(data) {
      console.log('onLocateComplete', data);
      this.myLocation = data.position;
      this.map.panTo(data.position);
      this.showNearStation();
    },
    onLocateError(data) {
      console.log('onLocateError', data);
      Toast.hide()
      Toast.fail('定位失败')
      // eslint-disable-next-line no-undef
      // this.myLocation = new AMap.LngLat(120.140656,30.301501) debug模拟逻辑
      this.showNearStation();
    },
    showNearStation() {
      let center = this.myLocation
      if (center != null) {
        console.log('排序前', this.stations)
        this.stations.forEach(s => {
          // eslint-disable-next-line no-undef
          s.distance = this.calDistance(center, new AMap.LngLat(s.station.longitude, s.station.latitude));
        })
        this.stations.sort((a, b) => {
          return a.distance - b.distance;
        })
        console.log('排序后', this.stations)
      }
      let first = this.stations[0].station
      // eslint-disable-next-line no-undef
      this.map.panTo(new AMap.LngLat(first.longitude, first.latitude))
    },
    calDistance(from, to) {
      // eslint-disable-next-line no-undef
      return parseInt(AMap.GeometryUtil.distance(from, to))
    },
    initPlugins(AMap) {
      let thiz = this;
      let icon = new AMap.Icon({
        size: new AMap.Size(32, 40),    // 图标尺寸
        image: 'https://cdn.waityou.online/cf2834ef-e66f-d84e-17b2-f3a8a2bc76e2.png',  // Icon的图像
        imageSize: new AMap.Size(32, 40)
      });
      this.map.plugin([], function () {
        var geolocation = new AMap.Geolocation({
          enableHighAccuracy: true, // 是否使用高精度定位，默认：true
          timeout: 30000, // 设置定位超时时间，默认：无穷大
          offset: [20, 60],  // 定位按钮的停靠位置的偏移量
          zoomToAccuracy: false,  //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
          position: 'LB', //  定位按钮的排放位置,  RB表示右下
          showButton: true,
          panToLocation: false,
          markerOptions: {
            'icon': icon
          },
        })
        thiz.map.addControl(geolocation);
        thiz.geolocation = geolocation;
        AMap.Event.addListener(geolocation, 'complete', thiz.onLocateComplete);//返回定位信息
        AMap.Event.addListener(geolocation, 'error', thiz.onLocateError);      //返回定位出错信息
        geolocation.getCurrentPosition();
      })
    },
    addMarkers(AMap) {
      let icon = new AMap.Icon({
        size: new AMap.Size(44, 45),    // 图标尺寸
        image: 'https://cdn.waityou.online/1bbbd2b8-2180-d51d-b455-0e72defc43eb.png',  // Icon的图像
        imageSize: new AMap.Size(44, 45)
      });
      for (let st of this.stations) {
        let m = st.station;
        let marker = new AMap.Marker({
          position: new AMap.LngLat(m.longitude, m.latitude),
          title: m.name,
          'icon': icon,
          label: {
            content: m.name,
            direction: 'bottom'
          },
          extData: m,
        });
        marker.on('click', this.onMarkerClick)
        this.map.add(marker)
      }
    },
    onMarkerClick(e) {
      let stationId = e.target.getExtData().id;
      api.getStationDetail(stationId)
          .then(ret => {
            this.currentStation = ret;
            this.stationSimpleDataVisible = true;
          })
    }
  },
  mounted() {
    this.spaceBetween = parseInt(window.innerWidth * 0.9);
    api.getStationByRegionId({
      stationType: this.stationType,
    }).then(ret => {
      this.stations = ret;
      this.initMap();
    })
    api.getSubDepartments()
        .then(ret => {
          this.departments = ret.map(s => {
            return {
              value: s.id,
              text: s.name
            }
          });
        })
  }
}
</script>
<style>
.amap-marker-label {
  border: none !important;
  background: transparent !important;
  text-shadow: -2px -2px 0 white,
  2px -2px 0 white,
  -2px 2px 0 white,
  2px 2px 0 white; /* 设置描边效果，可以调整阴影的位置和颜色 */
}
</style>
<style lang="scss" scoped>
.BatterVisitViewContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  font-size: $baseFontSize;
}

.listStationContainer {
  display: flex;
  flex-direction: column;
}

.showModeSwitchBtn {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 16px;
  bottom: 45%;
}

.showModeSwitchBtn2 {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 16px;
  top: 20%;
}

.stationCards {
  position: absolute;
  bottom: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.visitSearchBar {
  position: absolute;
  top: 10px;
}
</style>