<template>
  <div class="AskFinishDialogContainer bothCenter baseFont">
    <img src="https://cdn.waityou.online/069ed780-d439-e5b0-dcbe-dbc6f0202adf.png"/>
    <span>感谢你</span>
    <span>完成了本次问卷</span>
    <nut-row class="bothCenter" style="margin-top: 20px">
      <nut-col :span="12" class="bothCenter">
        <nut-button class="btn1" @click="$router.replace('/study')">继续答题</nut-button>
      </nut-col>
      <nut-col :span="12" class="bothCenter">
        <nut-button class="btn2" @click="$emit('close')">关闭</nut-button>
      </nut-col>
    </nut-row>
  </div>
</template>

<script>
export default {
  name: "AskFinishDialog"
}
</script>

<style lang="scss">
.AskFinishDialogContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 16px;
  border-radius: 16px;
  width: 70vw;

  .btn1{
    color:#2F438C;
    background: rgba(0, 100, 255, 0.1);
    border-radius: 4px;
    border: none;
    width: 80%;
  }
  .btn2{
    color:white;
    background: #2F438C;
    border-radius: 4px;
    border: none;
    width: 80%;
  }
  img{
    width: 80%;
  }
}
</style>