<template>
  <div class="BatterVisitDateViewContainer">
    <nut-row class="bothCenter wbg">
      <nut-col :span="1" class="leftCenter">
        <div class="dot"/>
      </nut-col>
      <nut-col :span="23" class="colFlex">
        <span class="title">预约日期</span>
        <span class="subTitle">至少提前1天预约</span>
      </nut-col>
    </nut-row>
    <IceCalendar :station-id="stationId" @fresh="onIceCalendarFresh" :plans="plans"/>
    <nut-row class="bothCenter wbg">
      <nut-col :span="1" class="leftCenter">
        <div class="dot"/>
      </nut-col>
      <nut-col :span="23" class="colFlex">
        <span class="title">选择时间段</span>
        <span class="subTitle">少于10人可以创建拼团，或参与已创建的拼团</span>
      </nut-col>
    </nut-row>
    <DateSelector :station-id="stationId" :plans="filteredPlans"/>
    <div class="pinGroups" v-if="pinList && pinList.length>0">
      <span class="title">当前拼团</span>
      <PinGroup v-for="p in pinList"
                @click="joinPin(p)"
                :key="p.id" :data="p"/>
    </div>
  </div>
</template>

<script>
import DateSelector from "@/components/date/DateSelector";
import PinGroup from "@/components/date/PinGroup";
import api from "@/api";
import {Toast} from "@nutui/nutui";
import IceCalendar from "@/components/IceCalendar";

export default {
  name: "BatterVisitDateView",
  components: {IceCalendar, DateSelector, PinGroup},
  data() {
    return {
      stationId: this.$route.params.stationId,
      pinList: [],
      date:null,
      plans:[],
      filteredPlans:[],
    }
  },
  methods: {
    init() {
      api.getPinGroups(this.stationId)
          .then(list => {
            this.pinList = list;
          })
    },
    joinPin(p) {
      if (p.type != 'GROUPING') {
        Toast.fail('抱歉，该拼团暂时无法加入')
        return
      }
      if (p.myCreate || p.myJoin) {
        Toast.warn('你已经在此拼团中了')
        return
      }
      this.$router.push('/date/pin/join/' + p.id)
    },
    onIceCalendarFresh(date) {
      this.date = date;
      api.getVisitPlan({
        stationId: this.stationId,
        date: date,
      }).then(ret => {
        this.plans = ret;
        this.filteredPlans = ret.filter(r=>r.dateIndex === parseInt(date.replaceAll('-','')));
      })
    },
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.BatterVisitDateViewContainer {
  display: flex;
  flex-direction: column;
  background-color: #F4F6F9;
  min-height: 100vh;
  font-size: $baseFontSize;

  .pinGroups {
    padding: 16px;

    .title {
      font-weight: bold;
    }
  }

  .title {
    font-size: $baseFontSize;
  }

  .subTitle {
    font-size: $minFontSize;
    color: #999999;
  }

  .dot {
    width: 4px;
    height: 36px;
    background: #2F438C;
  }
}
</style>