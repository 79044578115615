<template>
  <div class="ExamViewContainer">
    <CommonExamView
        ref="examView"
        :judge-right-now="false"
        :question-list="questions"
        @submit="submitAnswer"/>
  </div>
  <nut-popup position="center" round v-model:visible="popupVisible">
    <AskFinishDialog @close="$router.back()"/>
  </nut-popup>
</template>

<script>
import api from "@/api";
import CommonExamView from "@/components/exam/CommonExamView";
import AskFinishDialog from "@/components/ask/AskFinishDialog";

export default {
  name: "AskPaperView",
  components: {AskFinishDialog, CommonExamView},
  data() {
    return {
      popupVisible: false,
      questions: [],
      answerStates: [],
      startTime: Date.now(),
      repo: {},
    }
  },
  methods: {
    submitAnswer() {
      api.completeQuestionnaire()
          .then(() => {
            this.popupVisible = true;
          })
    },
    init() {
      api.getRandQuestionnaire()
          .then(list => {
            this.questions = list;
          })
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.ExamViewContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
}
</style>