<template>
  <div class="ConsultSubListViewContainer">
    <div class="wbg pd16 brd16 smallFont mb8" v-for="item in listMap[typeId]" :key="item.link">
      <nut-row class="bothCenter" @click="goto(item.link)">
        <nut-col :span="22" class="leftCenter">
          <span class="baseFont">{{ item.name }}</span>
        </nut-col>
        <nut-col :span="2" class="rightCenter">
          <nut-icon name="right" size="10"></nut-icon>
        </nut-col>
      </nut-row>
    </div>
  </div>
</template>

<script>
import {Toast} from "@nutui/nutui";

export default {
  name: "ConsultSubListView",
  data() {
    return {
      typeId: this.$route.params.typeId,
      listMap: {
        1:[
          {
            "name": "对消防安全重点单位履行法定消防安全职责情况的监督抽查",
            "link": "b2664385-8cc8-4b1e-a733-f79112c7c375"
          },
          {
            "name": "对高层民用建筑消防安全的行政检查",
            "link": "6814c134-ed38-4f06-aa2d-be68aeb81c51"
          },
          {
            "name": "对居住出租房消防安全的行政检查",
            "link": "08ba8fe1-4f3c-451b-ba04-01734f5ac50d"
          },
          {
            "name": "对城市道路以外的电动车消防安全的行政检查",
            "link": "d366e5e5-6e95-4e32-8b80-85f857e778e8"
          },
          {
            "name": "对使用领域消防产品质量的监督抽查",
            "link": "213e18fb-dc3b-4eb1-ba0b-6ddd82f6e70d"
          },
          {
            "name": "对被查封场所、部位消防安全的行政检查",
            "link": "77423456-1931-4db1-aac2-78d8f1c6bab3"
          },
          {
            "name": "对消防技术服务机构的行政检查",
            "link": "40b8f618-a4e7-42fc-b839-5e19f80c6b7a"
          },
          {
            "name": "对火灾事故现场的行政检查",
            "link": "807517f9-36cc-4839-be13-541ab9d260a2"
          },
          {
            "name": "对公众聚集场所投入使用、营业前消防安全检查的行政检查",
            "link": "d8dbd497-11f2-4498-9e85-3da76226c05e"
          },
          {
            "name": "对消防一般单位履行法定消防安全职责情况的监督抽查",
            "link": "922afe28-6c9a-4907-b380-1b51c2ea6bbf"
          },
          {
            "name": "对施工现场消防安全的行政检查",
            "link": "7591c10b-1281-4d02-a184-845e64cd7005"
          },
          {
            "name": "对一级注册消防工程师的注册和执业活动的行政检查",
            "link": "dd2676fa-0287-46b1-878c-c9c97c067e84"
          }
        ],
        2:[
          {
            "name": "对非沿城市道路的人员密集场所门窗设置影响逃生、灭火救援的障碍物的行政...",
            "link": "1a3b0568-21d2-4938-85f0-a3eedc61822f"
          },
          {
            "name": "对占用、堵塞、封闭城市道路以外的消防车通道的行政处罚",
            "link": "feb0937c-a667-482f-9844-87beba73e992"
          },
          {
            "name": "对未经注册擅自以注册消防工程师名义执业的行政处罚",
            "link": "19810284-540c-428a-998b-11945373f027"
          },
          {
            "name": "对消防设施、器材、消防安全标志未保持完好有效的行政处罚",
            "link": "30fa8805-2854-40d6-a5e2-fe3b0ac52ad6"
          },
          {
            "name": "对消防安全技术文件未经注册消防工程师签名、加盖执业印章的行政处罚",
            "link": "3b09d115-ca65-4110-8bc3-665a7b687711"
          },
          {
            "name": "对人员密集场所使用不合格、国家明令淘汰的消防产品逾期未改的行政处罚",
            "link": "953466ad-18a7-49a0-bd8f-92a1d400e3f2"
          },
          {
            "name": "对承租人违反消防安全要求改变房屋使用功能、结构的行政处罚",
            "link": "61815e1d-76cc-4916-a64a-0298231b0048"
          },
          {
            "name": "对公众聚集场所未经消防救援机构许可擅自投入使用、营业的行政处罚",
            "link": "d0abf34a-cd5e-48cd-80de-5d417fc9e798"
          },
          {
            "name": "对未按国家标准、行业标准开展执业活动的行政处罚",
            "link": "0440efb3-5f1a-4558-b18b-afab099cafa1"
          },
          {
            "name": "对聘用单位为申请人提供虚假注册申请材料的行政处罚",
            "link": "84b0f256-879d-4477-aadb-bc4a67b9f82f"
          },
          {
            "name": "对消防技术服务机构不具备从业条件从事消防技术服务活动的行政处罚",
            "link": "2672a848-4cad-4417-b727-a6372ef1ba29"
          },
          {
            "name": "对高层建筑超负荷用电逾期未改的行政处罚",
            "link": "83d31fb1-2997-4a59-9232-253e558638bb"
          },
          {
            "name": "对擅自拆除、停用消防设施、器材的行政处罚",
            "link": "617291c8-3791-473c-87b6-dd2d20bdff04"
          },
          {
            "name": "对占用、堵塞、封闭城市道路以外的消防登高场地的行政处罚",
            "link": "9d765795-eae1-4356-ab74-f251054b2f0c"
          },
          {
            "name": "对占用、堵塞、封闭城市道路上的消防登高场地的行政处罚",
            "link": "fcd5fee7-4728-4347-891a-8c62836467a6"
          },
          {
            "name": "对在城市道路以外私拉电线和插座给电动车充电的行政处罚",
            "link": "a8d2c24b-776a-4928-adee-5647aee611f6"
          },
          {
            "name": "对非人员密集场所使用不符合市场准入、不合格、国家明令淘汰的消防产品逾期未改...",
            "link": "eb0142bb-5984-4c1a-b228-2ffcfd2dd462"
          },
          {
            "name": "对人员密集场所使用不符合市场准入的消防产品逾期未改的行政处罚",
            "link": "e37bdaa4-eedb-4ffa-98d1-52575b5e4e89"
          },
          {
            "name": "对执业活动质量不符合国家标准、行业标准的行政处罚",
            "link": "83562d7e-8a74-4b28-b1d0-16c35216f718"
          },
          {
            "name": "对未经准予变更注册而继续执业的行政处罚",
            "link": "3765d6b6-8ea6-48f4-858e-dd731eadfbe5"
          },
          {
            "name": "对埋压、圈占、遮挡城市道路以外的消火栓的行政处罚",
            "link": "946a9997-dc01-43b6-891f-624ed7bf84b1"
          },
          {
            "name": "对拒不执行火灾现场指挥员指挥的行政处罚",
            "link": "7115e9ac-374e-4b23-8aa7-4b2f2a671e7b"
          },
          {
            "name": "对施工现场未落实消防安全措施逾期未改的行政处罚",
            "link": "ebb23ca6-929e-4d93-bdea-faa9fae9e244"
          },
          {
            "name": "对违反规定使用、委托自动消防系统检测人员逾期未改的行政处罚",
            "link": "e6777d30-c34c-4821-8180-0a1efc4ac551"
          },
          {
            "name": "对易燃易爆危险品场所未与居住场所保持安全距离的行政处罚",
            "link": "bc1e1d3c-3cc2-497e-af02-06e232ccd433"
          },
          {
            "name": "对易燃易爆危险品场所与居住场所设置在同一建筑物内的行政处罚",
            "link": "9a762fcd-81a4-4e5c-a88f-6d0b484ca6e6"
          },
          {
            "name": "对其他场所与居住场所设置在同一建筑物内不符合消防技术标准的行政处罚",
            "link": "be40a9fc-7156-472f-a24d-d21cdac23fec"
          },
          {
            "name": "对其他妨碍安全疏散行为的行政处罚",
            "link": "c060fd8e-5ce9-4ec5-ac30-6f2c44a64ee6"
          },
          {
            "name": "对因危害消防安全的行为引起火灾或者造成火灾危害扩大的行政处罚",
            "link": "087752b1-873f-4333-8fe0-32debc9e4616"
          },
          {
            "name": "对用于居住的出租房屋不符合消防安全要求的行政处罚",
            "link": "dce32240-afd6-4fc2-b03a-c901bbc7c999"
          },
          {
            "name": "对未按规定保存检测报告的行政处罚",
            "link": "19859961-c09b-4010-bcc4-fc8bbbecc554"
          },
          {
            "name": "对故意破坏、伪造火灾现场的行政处罚",
            "link": "0f81ccf9-aa36-41c6-9f77-2094dc411991"
          },
          {
            "name": "对电器线路的设计、敷设、维护保养、检测不符合规定的行政处罚",
            "link": "600f19eb-77d0-499e-813c-8b301e85bdde"
          },
          {
            "name": "对擅自拆封、使用被查封场所、部位的行政处罚",
            "link": "13dc2abc-3999-4142-a5c6-79a2d42afd93"
          },
          {
            "name": "对阻拦、不及时报告火警的行政处罚",
            "link": "ef5ad371-a078-460d-9ea1-afa6e64c03dd"
          },
          {
            "name": "对燃气管路的设计、敷设、维护保养、检测不符合规定的行政处罚",
            "link": "a4440c55-7edb-49ff-b639-09048648b538"
          },
          {
            "name": "对占用防火间距的行政处罚",
            "link": "f32b5ea9-6ff5-42b6-9868-a832806b2d22"
          },
          {
            "name": "对燃气用具的安装、使用不符合规定的行政处罚",
            "link": "661b9fdd-e380-47ca-abd9-92941f01b3fd"
          },
          {
            "name": "对扰乱火灾现场秩序的行政处罚",
            "link": "937ea540-9496-4138-a349-090fd9a6c930"
          },
          {
            "name": "对违反规定使用、委托自动消防系统操作人员逾期未改的行政处罚",
            "link": "eb416a8b-a733-46c7-9407-9a58d3b7aa36"
          },
          {
            "name": "对在具有火灾、爆炸危险的场所吸烟、使用明火的行政处罚",
            "link": "13f0e068-6782-476d-8dad-c55f96132837"
          },
          {
            "name": "对电器产品的安装、使用不符合规定的行政处罚",
            "link": "395aed3d-85e1-466d-923e-f735ebb0e31a"
          },
          {
            "name": "对不如实提供火灾情况的行政处罚",
            "link": "f6fc26aa-a78d-4ca3-898e-47d495d05776"
          },
          {
            "name": "对违规使用明火作业的行政处罚",
            "link": "a5c55b22-1620-488c-928f-e7294d0de972"
          },
          {
            "name": "对在高层民用建筑内进行电焊、气焊等明火作业，未履行动火审批手续、进行公告的...",
            "link": "6d9c0ed9-df45-4794-95d3-2ce00828bdb8"
          },
          {
            "name": "对不按要求保护火灾现场的行政处罚",
            "link": "9af8d03e-57c4-4bbb-8167-d8320f664fa5"
          },
          {
            "name": "对占用、堵塞、封闭疏散通道、安全出口的行政处罚",
            "link": "375b185a-5dcf-4554-8dff-36bd5080dc3c"
          },
          {
            "name": "对指使、强令他人冒险作业的行政处罚",
            "link": "dc7a4ff7-0281-4f88-a58b-0c76c40845af"
          },
          {
            "name": "对违规进入生产、储存易燃易爆危险品场所的行政处罚",
            "link": "8da3ddbe-d9ac-40a2-b82e-b30618d3b12d"
          },
          {
            "name": "对消防设施、器材、消防安全标志配置、设置不符合标准的行政处罚",
            "link": "155db0df-1ac6-4748-8000-c955763c9456"
          },
          {
            "name": "对占用、堵塞、封闭避难层（间）的行政处罚",
            "link": "43ab5196-137c-4c62-93e9-d919a1a9d3d6"
          },
          {
            "name": "对拒绝履行消防安全职责、义务的行政处罚",
            "link": "c2149f3b-da1c-40c2-9682-53258d6200ac"
          },
          {
            "name": "对高层建筑统一管理机构不履行消防安全职责逾期未改的行政处罚",
            "link": "dbb237b0-2d6b-414c-83de-6f693f8b5145"
          },
          {
            "name": "对过失引起火灾的行政处罚",
            "link": "6c44d7ce-f800-4647-ad9d-5319694da837"
          },
          {
            "name": "对自动消防系统未定期检测的行政处罚",
            "link": "7cbc807a-741a-4418-9d28-2a9b2220bb53"
          },
          {
            "name": "对不履行消防安全职责逾期未改的行政处罚",
            "link": "fba922f0-e030-4868-8fa5-1a562511bd21"
          },
          {
            "name": "对消防控制室未实行二十四小时值班制度的行政处罚",
            "link": "f651cb39-ded7-4e15-8ac1-9251f42b30f5"
          },
          {
            "name": "对不及时消除火灾隐患的行政处罚",
            "link": "7d4ec0b9-6f39-46d9-8b8d-5023a9eab091"
          },
          {
            "name": "对损坏、挪用消防设施、器材的行政处罚",
            "link": "5655c2a1-9b43-4bf3-af0b-858234107420"
          },
          {
            "name": "对擅自进入火灾现场或擅自清理、移动火灾现场物品的行政处罚",
            "link": "a00775db-ec66-4888-86e9-ec82e55a4c78"
          },
          {
            "name": "对在疏散通道、安全出口、楼梯间等影响消防通道畅通的区域停放电动自行车、电动...",
            "link": "85cf78c9-8cbf-4d82-a6a6-09b27d80f21e"
          },
          {
            "name": "对高层民用建筑设置的户外广告牌、外装饰妨碍防烟排烟、逃生和灭火救援的行政处罚",
            "link": "ce729604-9aad-4699-84b9-55a65a65771c"
          },
          {
            "name": "对高层民用建筑未设置外墙外保温材料提示性和警示性标识的行政处罚",
            "link": "72469203-db57-491f-82ff-6eb1f43e7a2e"
          },
          {
            "name": "对高层民用建筑未按照规定落实消防控制室值班制度的行政处罚",
            "link": "8b7dcd56-d6a6-4239-8d0a-ecb64a49fd2a"
          },
          {
            "name": "对在高层民用建筑公共门厅、疏散走道、楼梯间、安全出口为电动自行车充电拒不改...",
            "link": "90d4aa08-1afe-49b7-a12e-fee921882bb1"
          },
          {
            "name": "对高层民用建筑未按照规定建立专职消防队、志愿消防队等消防组织的行政处罚",
            "link": "8e90093f-84ed-4f78-a7bb-cb58fef3f920"
          },
          {
            "name": "对高层民用建筑设置的户外广告牌、外装饰改变、破坏建筑立面防火结构的行政处罚",
            "link": "a04786e6-b40c-45f2-bb0b-3e2a0fb62971"
          },
          {
            "name": "对在高层民用建筑公共门厅、疏散走道、楼梯间、安全出口停放电动自行车拒不改正...",
            "link": "b95ff74c-bfbf-493c-90b1-9eac4e772776"
          },
          {
            "name": "对管理单位未制止电动自行车、电动摩托车在不符合规定要求的室内场所充电的行政...",
            "link": "d555617b-caf5-4dde-bc22-dd6b32409040"
          },
          {
            "name": "对物业服务企业未履行消防安全职责的行政处罚",
            "link": "b1eb5314-d732-43d4-a480-3ba3a039c03b"
          },
          {
            "name": "对高层民用建筑安排不具备相应条件的人员在消防控制室值班的行政处罚",
            "link": "b98b91f7-75d5-4e1b-a12f-d148132b46e8"
          },
          {
            "name": "对高层民用建筑未及时修复破损、开裂、脱落外墙外保温系统的行政处罚",
            "link": "5f33815b-c24b-4a7c-87cf-ecaf1357419a"
          },
          {
            "name": "对在高层民用建筑内进行电焊、气焊等明火作业，未落实消防现场监护措施的行政处罚",
            "link": "5fb107cc-9723-408e-86e6-e54ad98a5d8f"
          },
          {
            "name": "对高层民用建筑停用建筑消防设施未进行公告、未制定应急预案、未落实防范措施的...",
            "link": "59c45788-4eb8-42bd-b670-6706118d3fb6"
          },
          {
            "name": "对经核查发现场所使用、营业情况与承诺内容不符的行政处罚",
            "link": "9de3353b-7bf9-41b9-8d01-ec1b879d4d69"
          }
        ],
        3:[
          {
            "name": "对疏散通道、安全出口严重不足或者严重堵塞，不具备安全疏散条件的行政强制",
            "link": "e24f788c-ee79-4e11-b4cd-98058bd28459"
          },
          {
            "name": "对公众聚集场所违反消防技术标准，采用易燃、可燃材料装修，可能导致重大人员伤...",
            "link": "d106a28b-23a3-4aa1-8fe3-c9e9230c9718"
          },
          {
            "name": "对人员密集场所违反消防安全规定，使用、储存易燃易爆危险品的行政强制",
            "link": "b6456de5-b8a1-46a6-9924-6529f293d89d"
          },
          {
            "name": "对建筑消防设施严重损坏，不具备防火灭火功能的行政强制",
            "link": "79cc10b2-3726-4bc8-8040-4753e191dde8"
          }
        ]
      }
    }
  },
  methods: {
    goto(link){
      Toast.loading('请稍等...')
      window.location.href = 'https://www.zjzwfw.gov.cn/zjservice/item/detail/index.do?localInnerCode='+link
    },
    init() {

    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.ConsultSubListViewContainer {
  display: flex;
  flex-direction: column;
  background-color: #F4F6F9;
  padding: 16px;
  min-height: 100vh;
}
</style>