<template>
  <div class="StationSimpleInfoContainer">
    <div style="padding: 0 16px">
      <div class="bothCenter">
        <nut-icon name="arrow-up" @click="commentVisible=true"
                  v-if="!commentVisible"></nut-icon>
        <nut-icon name="arrow-down" @click="commentVisible=false"
                  v-if="commentVisible"></nut-icon>
      </div>
      <nut-row class="bothCenter">
        <nut-col :span="24" class="colFlex">
          <div class="leftCenter">
            <span class="stationName">{{ data?.station?.name }}</span>
          </div>
          <div>
            <span class="distance">距你距离{{ calDistance() }}</span>
            <span class="address">{{ data?.station?.address }}</span>
          </div>
        </nut-col>
<!--        <nut-col :span="3" class="clickIcon bothCenter colFlex">-->
<!--          <img v-if="data?.iCollect"-->
<!--               @click="$emit('disCollect',data.iCollect)"-->
<!--               src="https://cdn.waityou.online/2e072cf9-9a8f-c492-a20b-196c04dfa46e.png"/>-->
<!--          <img v-else @click="$emit('collect')"-->
<!--               src="https://cdn.waityou.online/fd61adbe-bd85-68d2-920d-a7ef42f64a81.png"/>-->
<!--          <span>{{ data?.collectCount }}</span>-->
<!--        </nut-col>-->
<!--        <nut-col :span="3" class="clickIcon bothCenter colFlex">-->
<!--          <img v-if="data?.iLike" @click="$emit('disLike',data.iLike)"-->
<!--               src="https://cdn.waityou.online/0acc3589-9729-f6d5-ddb0-8563fae2e913.png"/>-->
<!--          <img v-else @click="$emit('like')" src="https://cdn.waityou.online/b43e74d6-7b7a-9baf-8d22-0ec331791653.png"/>-->
<!--          <span>{{ data?.likeCount }}</span>-->
<!--        </nut-col>-->
      </nut-row>
      <nut-swiper :is-stop-propagation="false"
                  v-if="data?.station?.dataUrls"
                  :is-prevent-default="false" :pagination-visible="false"
                  width="100%"
                   height="auto" pagination-color="#426543" auto-play="3000">
        <nut-swiper-item v-for="(m,mi) in data?.station?.dataUrls"
                         @click="playVideo(m)"
                         :key="mi">
          <img v-if="m.endsWith('mp4')" :src="`${m}?x-oss-process=video/snapshot,t_1000,m_fast`"
               style="width: calc(100vw - 32px);object-fit: contain;"/>
          <img v-else :src="m"
               style="width: calc(100vw - 32px);object-fit: contain;"/>
        </nut-swiper-item>
      </nut-swiper>
      <div class="videoCover bothCenter" v-else>
        <img src="https://cdn.waityou.online/cf83afbe-b7c6-a20f-2cf3-457902fcf037.png"/>
      </div>
      <nut-row class="stationNumbers bothCenter">
        <nut-col :span="8" class="bothCenter">
          <span>参观人次:{{ data?.station?.visitPeopleCount }}</span>
        </nut-col>
        <nut-col :span="8" class="bothCenter">
          <span>参观次数:{{ data?.station?.visitCount }}</span>
        </nut-col>
        <nut-col :span="8" class="bothCenter">
          <span>浏览数:{{ data?.station?.browseCount }}</span>
        </nut-col>
      </nut-row>
      <nut-row class="leftCenter mb8">
        <span class="smallFont mr8">评价指数</span>
        <nut-rate active-color="orange" icon-size="12"
                  checked-icon="https://cdn.waityou.online/a7daaf55-aeae-d157-9eb6-e9554fdeb5f7.png"
                  unchecked-icon="https://cdn.waityou.online/6caf8edf-e720-47fa-8652-976426ee5b62.png"
                  readonly
                  v-model="avgScore"/>
      </nut-row>
<!--      <nut-row class="bothCenter" :gutter="16">-->
<!--        <nut-col :span="12">-->
<!--          <div class="reputation bothCenter">-->
<!--            <img src="https://cdn.waityou.online/7142f4cc-1c89-daf6-5c87-fab80d5ef513.png"/>-->
<!--            <span>收到锦旗</span>-->
<!--            <span style="color:#FF4747">{{ data?.pennantCount }}面</span>-->
<!--          </div>-->
<!--        </nut-col>-->
<!--        <nut-col :span="12">-->
<!--          <div class="reputation bothCenter">-->
<!--            <img src="https://cdn.waityou.online/9cfe88d1-9d2e-f61b-9828-ab9da13c1794.png"/>-->
<!--            <span>收到鲜花</span>-->
<!--            <span style="color:#FFB35F">{{ data?.flowerCount }}朵</span>-->
<!--          </div>-->
<!--        </nut-col>-->
<!--      </nut-row>-->
      <div v-if="commentVisible">
        <div>
          <span class="evaluate">{{ evaluate.name }}</span>
        </div>
        <div class="commentContainer">
          <InfiniteLoadingList :query-id="2" :init-query="evaluateQuery">
            <template v-slot="data">
              <nut-row class="comment">
                <nut-col :span="4" class="leftCenter">
                  <img class="avatar" :src="data.data.userAvatar"/>
                </nut-col>
                <nut-col :span="22" class="colFlex">
                  <span class="nickName">{{ data.data.userName }}</span>
                  <nut-ellipsis
                      class="content"
                      direction="end"
                      :rows="2"
                      expandText="展开"
                      collapseText="收起"
                      :content="data.data.content"></nut-ellipsis>
                  <span class="postTime">{{ data.data.createdTime }}</span>
                  <nut-row>
                    <img v-for="(img,imgIndex) in data.data.imgs" :key="imgIndex"
                         @click="previewImage(data.data.imgs)"
                         :src="img" style="width: 80px;height: 80px;object-fit: cover;margin-right: 8px;"/>
                  </nut-row>
                  <div class="divider"></div>
                </nut-col>
              </nut-row>
            </template>
          </InfiniteLoadingList>
        </div>
      </div>
    </div>
    <nut-imagepreview :show="showPreview"
                      @close="showPreview=false"
                      :images="imgData"/>
    <view class="bottomBtns bothCenter">
      <nut-col :span="3" class="stationFunction bothCenter" v-for="(m,i) in menus" :key="i">
        <img :src="m.img"/>
        <span>{{ m.name }}</span>
      </nut-col>
      <nut-col :span="6" class="bothCenter">
        <span class="btn1" @click="goNavigation">导航</span>
      </nut-col>
      <nut-col :span="6" class="bothCenter">
        <span class="btn2" @click="$emit('onAction',data.station)">{{ actionBtn }}</span>
      </nut-col>
    </view>
  </div>

  <nut-overlay
      class="bothCenter"
      @close="playerVisible=false"
      :close-on-click-overlay="false"
      v-model:visible="playerVisible">
    <div class="colFlex bothCenter">
      <video v-if="playerVisible" :src="videoUrl" controls style="width: 100%" @ended="onPlayEnd"/>
      <div class="whiteFont smallFont bothCenter vmg16" @click="playerVisible=false">
        <nut-icon name="close" size="12"></nut-icon>
        <span>关闭视频</span>
      </div>
    </div>
  </nut-overlay>
</template>

<script>
import InfiniteLoadingList from "@/components/InfiniteLoadingList"
import {Toast} from "@nutui/nutui";

export default {
  name: "StationSimpleInfo",
  components: {InfiniteLoadingList},

  props: {
    myLocation: {
      type: Object,
      default: () => {
        return null
      }
    },
    data: {
      type: Object,
      default: () => {
        return {
          station: {}
        }
      }
    },
    evaluate: {
      type: Object,
      default: () => {
        return {
          type: 'STATION_VISIT',
          id: 0,
          name: '参观评价'
        }
      }
    },
    menus: {
      type: Array,
      default: () => {
        return []
      }
    },
    actionBtn: {
      type: String,
      default: '约参观'
    },
  },
  data() {
    return {
      avgScore: 5,
      videoUrl: null,
      imgData: [],
      showPreview: false,
      playerVisible: false,
      commentVisible: true,
      evaluateQuery: {
        type: this.evaluate.type,
        id: this.data.station.id
      }
    }
  },
  methods: {
    goNavigation() {
      if (!this.myLocation) {
        Toast.warn('请注意您的定位可能不准确')
      }
      window.open(`https://uri.amap.com/navigation?to=${this.data.station.longitude},${this.data.station.latitude},${this.data.station.name}&mode=car&policy=&src=xfbm&coordinate=gaode&callnative=1`)
    },
    onPlayEnd() {

    },
    previewImage(imgs) {
      this.imgData = imgs.map(s => {
        return {
          src: s
        }
      });
      this.showPreview = true;
    },
    playVideo(url) {
      if(!url.endsWith('mp4')){
        return
      }
      this.videoUrl = url;
      this.playerVisible = true;
    },
    calDistance() {
      if (!this.myLocation) {
        return '未知'
      }
      // eslint-disable-next-line no-undef
      return parseInt(AMap.GeometryUtil.distance(this.myLocation,
          // eslint-disable-next-line no-undef
          new AMap.LngLat(this.data.station.longitude, this.data.station.latitude))) + '米'
    },
    init(){
      this.avgScore = this.data.station.avgScore;
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.StationSimpleInfoContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  font-size: $baseFontSize;

  .divider {
    background-color: #F2F2F2;
    height: 1px;
    width: 100%;
    margin-top: 16px;
  }

  .evaluate {
    font-size: $baseFontSize;
    font-weight: bold;
  }

  .commentContainer {
    max-height: 30vh;
    overflow-y: scroll;
    scrollbar-width: thin; /* 创建一个细的滚动条，适用于某些浏览器 */
    scrollbar-color: transparent transparent; /* 设置滚动条的颜色为透明，适用于某些浏览器 */

    .comment {
      padding: 8px 0;
      display: flex;
      flex-direction: row;

      .avatar {
        width: 40px;
        height: 40px;
        border-radius: 20px;
      }

      .nickName {
        color: #999999;
        font-size: $miniFontSize;

      }

      .content {
        font-size: $miniFontSize;
      }

      .postTime {
        color: #999999;
        font-size: $minFontSize;
      }
    }
  }

  .commentContainer::-webkit-scrollbar {
    width: 0.5em; /* 设置滚动条的宽度，适用于 Chrome、Safari 和 Opera */
  }

  .commentContainer::-webkit-scrollbar-track {
    background-color: transparent; /* 设置滚动条的背景颜色为透明，适用于 Chrome、Safari 和 Opera */
  }

  .commentContainer::-webkit-scrollbar-thumb {
    background-color: transparent; /* 设置滚动条的滑块颜色为透明，适用于 Chrome、Safari 和 Opera */
  }

  .bottomBtns {
    box-shadow: lightgray 0 -2px 10px;
    padding: 8px;
  }

  .btn1 {
    color: #2F438C;
    background: rgba(0, 100, 255, 0.1);
    border-radius: 4px;
    border: none;
    width: 90%;
    text-align: center;
    line-height: 32px;
  }

  .btn2 {
    color: white;
    background: #2F438C;
    border-radius: 4px;
    border: none;
    width: 90%;
    text-align: center;
    line-height: 32px;
  }

  .stationFunction {
    display: flex;
    flex-direction: column;

    img {
      width: 24px;
      height: 24px;
    }

    span {
      font-size: $miniFontSize;
    }

  }

  .reputation {
    background-color: #F2F2F2;
    border-radius: 16px;
    height: 32px;
    margin: 8px 0;

    img {
      width: 13.75px;
      height: 16px;
    }

    span {
      margin: 4px;
      font-size: $miniFontSize;
    }
  }

  .stationNumbers {
    font-size: $miniFontSize;
    color: #999999;
    padding: 8px 0;
  }

  .videoCover {
    height: 40vw;
    background-image: url("https://hzxfbm.oss-cn-hangzhou.aliyuncs.com/2023/06/12/35d5e240-9ad0-4841-a279-c6f74969c50b.jpg");
    border-radius: 12px;
    background-size: cover;

    img {
      width: 32px;
      height: 32px;
    }
  }

  .stationName {
    font-size: $biggerFontSize;
    font-weight: bold;
  }

  .distance {
    font-size: $miniFontSize;
    color: #2F438C;
  }

  .address {
    margin-left: 5px;
    font-size: $miniFontSize;
  }

  .clickIcon {
    img {
      width: 24px;
      height: 24px;
    }

    span {
      font-size: $miniFontSize;
    }
  }
}
</style>