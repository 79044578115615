<template>
  <div class="ExamScoreContainer bothCenter">
    <img src="https://cdn.waityou.online/3b1ea160-d53d-d434-61e1-a119060d20c9.png" style="width: 60%"/>
    <div class="baseFont">
      <span>本次答题测试成绩</span>
      <span style="color:#2F438C">{{ data.score }}</span>
      <span>分</span>
    </div>
    <span>再接再厉哦！</span>
  </div>
</template>

<script>
export default {
  name: "ExamScore",
  props: {
    data: {
      type: Object,
      default: ()=>{
        return {

        }
      }
    },
  },
  methods: {
    init() {

    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.ExamScoreContainer {
  display: flex;
  flex-direction: column;
}
</style>