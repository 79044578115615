<template>
  <div class="IceCalendarContainer ft14">
    <div class="header bothCenter">
      <!-- 显示年份和月份，可以添加按钮来切换月份 -->
      <img src="https://cdn.waityou.online/b775865c-1235-587a-f0c9-4517e0298e09.svg"
           @click="previousMonth" class="sqr14 mr8"/>
      <span>{{ currentYear }}年{{ currentMonth + 1 }}月</span>
      <img src="https://cdn.waityou.online/63660e81-22ae-8e25-0a32-59e673e99716.svg"
           @click="nextMonth" class="sqr14 ml8"/>
    </div>
    <div class="weekdays mt8 ft14" style="color: #b4b4b4">
      <!-- 显示星期标题 -->
      <div class="weekday bothCenter" v-for="day in weekdays" :key="day">{{ day }}</div>
    </div>
    <div class="days mt6">
      <!-- 使用v-for循环来显示每一天 -->
      <div class="day vpd6 bothCenter colFlex" v-for="day in daysOfMonth" :key="day.date"
           :class="{ today: day.isToday, notCurrentMonth:!day.currentMonth, }">
        <span :class="{selected: isSelected(day),canDate:canDate(day),cantDate:cantDate(day)}"
              @click="onChangeDay(day.date.getDate())">{{ day.date.getDate() }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IceCalendar",
  props: {
    stationId: {
      type: Number,
      default: 0
    },
    plans:{
      type:Array,
      default:()=>{
        return []
      }
    }
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
      currentMonth: new Date().getMonth(),
      currentDay: 1,
      weekdays: ['日', '一', '二', '三', '四', '五', '六'],
    };
  },
  computed: {
    daysOfMonth() {
      const days = [];
      const firstDayOfMonth = new Date(this.currentYear, this.currentMonth, 1);
      const lastDayOfMonth = new Date(this.currentYear, this.currentMonth + 1, 0);

      let day = new Date(firstDayOfMonth);
      while (day <= lastDayOfMonth) {
        days.push({
          currentMonth: true,
          date: new Date(day),
          isToday: this.isToday(day),
          isSelected: false,
        });
        day.setDate(day.getDate() + 1);
      }

      // 填充月份开始之前的空格
      const startDay = firstDayOfMonth.getDay();
      for (let i = 0; i < startDay; i++) {
        days.unshift({
          currentMonth: false,
          date: new Date(firstDayOfMonth.setDate(firstDayOfMonth.getDate() - 1)),
          isToday: false,
          isSelected: false
        });
      }

      return days;
    }
  },
  methods: {
    canDate(day){
      let d = day.date.getFullYear()
      + "-" + (day.date.getMonth()+1).toString().padStart(2, '0')
      + "-" + day.date.getDate().toString().padStart(2, '0');
      for(let p of this.plans){
        if(p.timeStart.startsWith(d)){
          return true;
        }
      }
      return false;
    },
    cantDate(day){
      let d = day.date.getFullYear()
      + "-" + (day.date.getMonth()+1).toString().padStart(2, '0')
      + "-" + day.date.getDate().toString().padStart(2, '0');
      let filtered = this.plans.filter(p=>p.timeStart.startsWith(d))
      if(filtered.length < 1){
        return false;
      }
      return filtered.filter(f=>f.visit == null).length < 1;
    },
    onChangeDay(day){
      this.currentDay = day;
      this.fresh();
    },
    isSelected(day){
      return day.date.getFullYear() === this.currentYear
          && day.date.getMonth() === this.currentMonth
          && day.date.getDate() === this.currentDay;
    },
    isToday(day) {
      const today = new Date();
      return day.getDate() === today.getDate() && day.getMonth() === today.getMonth() && day.getFullYear() === today.getFullYear();
    },
    previousMonth() {
      if (this.currentMonth === 0) {
        this.currentYear--;
        this.currentMonth = 11;
      } else {
        this.currentMonth--;
      }
      this.currentDay = 1;
      this.fresh();
    },
    fresh(){
      this.$emit('fresh', this.currentYear
          + "-" + (this.currentMonth+1).toString().padStart(2, '0')
          + "-" + this.currentDay.toString().padStart(2, '0'));
    },
    nextMonth() {
      if (this.currentMonth === 11) {
        this.currentYear++;
        this.currentMonth = 0;
      } else {
        this.currentMonth++;
      }
      this.currentDay = 1;
      this.fresh();
    },
    init() {
      this.currentDay = new Date().getDate();
      this.fresh();
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.IceCalendarContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 16px;

  .calendar {
    /* 样式设置 */
  }

  .header {
    /* 样式设置 */
  }

  .weekdays {
    display: flex;
    flex-direction: row;

    .weekday {
      width: 14%;
    }
  }

  .days {
    display: flex;
    flex-direction: row;

    width: 100%;
    flex-wrap: wrap;
  }

  .day {
    width: 14%;
  }

  .today {

  }

  .today::after {
    content: '';
    display: block;
    margin: 2px auto;
    height: 5px;
    width: 5px;
    background-color: #3D92FD; /* 或者你喜欢的颜色 */
    border-radius: 50%;
  }

  .selected {
    width: 26px;
    padding-top: 4px;
    padding-bottom: 4px;
    text-align: center;
    border-radius: 30px;
    background-color: #3D92FD;
    color: white;
  }

  .canDate{
    width: 25px;
    padding-top: 3px;
    padding-bottom: 3px;
    border: #3D92FD 1px solid;
    border-radius: 30px;
    text-align: center;
  }
  .cantDate{
    width: 25px;
    padding-top: 3px;
    padding-bottom: 3px;
    border: gray 1px solid;
    border-radius: 30px;
    text-align: center;
  }
  .notCurrentMonth {
    color: white;
  }
}
</style>