<template>
  <div class="MyCollectionViewContainer">
    <nut-row className="switcher brd8">
      <nut-col :span="12"
               @click="tab=0"
               :class="tab==0?'actived bothCenter brd8':'bothCenter'">
        <span class="baseFont pd8">站点</span>
      </nut-col>
      <nut-col :span="12"
               @click="tab=1"
               :class="tab==1?'actived bothCenter brd8':'bothCenter'">
        <span class="baseFont">学习</span>
      </nut-col>
    </nut-row>

    <StationCollection v-if="tab==0" :more="true"/>
    <StationCollection v-if="tab==0"/>

    <nut-row style="margin-top: 16px" v-if="tab==1">
      <StudyItem v-for="i in [1,2,3,4]"
                 @click="$router.push('/study/detail/'+i)"
                 :key="i"/>
    </nut-row>
  </div>
</template>

<script>
import StudyItem from "@/components/study/StudyItem";
import StationCollection from "@/components/collection/StationCollection";
export default {
  name: "MyCollectionView",
  components: {StationCollection,StudyItem},
  data() {
    return {
      tab: 0
    }
  },
}
</script>

<style lang="scss" scoped>
.MyCollectionViewContainer {
  display: flex;
  flex-direction: column;
  background-color: #F4F6F9;
  padding: 16px;
  min-height: 100vh;
  .switcher{
    background-color: white;
    padding: 2px;
    display: flex;
    flex-direction: row;

    .actived{
      background-color: #2F438C;
      color:white;
    }
  }
}
</style>