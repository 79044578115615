<template>
  <div class="BatterVisitViewContainer">
    <StationMapView :menus="menus"
                    :station-type="2"
                    @action="onAction"
                    :evaluate="{
                      type: 'STATION_TRAIN',
                      name: '培训评价'
                    }"
                    action-btn="预约培训"></StationMapView>
  </div>
</template>

<script>
import StationMapView from "../map/StationMapView"
export default {
  name: "BatterVisitView",
  components: {StationMapView},
  data() {
    return {
      menus:[
        {
          img:'https://cdn.waityou.online/6137ea4c-d23a-6dc0-371d-a3b7d28d885e.png',
          name:'参观',
          link:'/visit'
        },
        {
          img:'https://cdn.waityou.online/4b9948f5-57b7-5c07-6dc2-b97f7dc81c31.png',
          name:'演练',
          link:'/drill/'
        },
        {
          img:'https://cdn.waityou.online/21ea60b1-bc20-6cdc-3c34-84f9b185bdfb.png',
          name:'学习',
          link:'/study'
        },
        {
          img:'https://cdn.waityou.online/c323b885-0d6b-70b9-6eed-d5d1a51afd6c.png',
          name:'咨询',
          link:'/consult'
        },
      ]
    }
  },
  methods: {
    onAction(station) {
      // Toast.warn('即将上线,敬请期待');
      this.$router.push('/teach/date/'+station.id)
    }
  },
}
</script>

<style lang="scss" scoped>
.BatterVisitViewContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
}
</style>