<template>
  <div class="DateHistoryDetailViewContainer">
    <span style="font-size: 24px" class="whiteFont boldFont prd8 vmg4">{{ stateMap[train.state] }}</span>
    <span class="whiteFont smallFont pd8">期望时间：{{ train.expectStartTime }} ~ {{ train.expectEndTime }}</span>
    <div class="wbg pd16 colFlex brd16 vmg16">
      <span class="baseFont boldFont mb8">预约信息</span>
      <nut-row class="bothCenter vmg4">
        <nut-col :span="6" class="leftCenter">
          <span class="smallFont" style="color:#999999;">活动内容</span>
        </nut-col>
        <nut-col :span="18" class="leftCenter">
          <span class="smallFont boldFont">培训</span>
        </nut-col>
      </nut-row>
      <nut-row class="bothCenter vmg4">
        <nut-col :span="6" class="leftCenter">
          <span class="smallFont" style="color:#999999;">培训单位</span>
        </nut-col>
        <nut-col :span="18" class="leftCenter">
          <span class="smallFont boldFont">{{train.stationName}}</span>
        </nut-col>
      </nut-row>
      <nut-row class="bothCenter">
        <nut-col :span="6" class="leftCenter">
          <span class="smallFont" style="color:#999999;">培训地址</span>
        </nut-col>
        <nut-col :span="18" class="leftCenter">
          <span class="smallFont boldFont">{{ train.address }}</span>
        </nut-col>
      </nut-row>
    </div>
    <div class="wbg pd16 colFlex brd16 mb16">
      <span class="baseFont boldFont mb8">联系人信息</span>
      <nut-row class="bothCenter vmg4">
        <nut-col :span="6" class="leftCenter">
          <span class="smallFont" style="color:#999999;">联系人</span>
        </nut-col>
        <nut-col :span="18" class="leftCenter">
          <span class="smallFont boldFont">{{ train.teamLeader }}</span>
        </nut-col>
      </nut-row>
      <nut-row class="bothCenter vmg4">
        <nut-col :span="6" class="leftCenter">
          <span class="smallFont" style="color:#999999;">联系号码</span>
        </nut-col>
        <nut-col :span="18" class="leftCenter">
          <span class="smallFont boldFont">{{ train.teamLeaderPhone }}</span>
        </nut-col>
      </nut-row>
      <nut-row class="bothCenter vmg4">
        <nut-col :span="6" class="leftCenter">
          <span class="smallFont" style="color:#999999;">申请单位</span>
        </nut-col>
        <nut-col :span="18" class="leftCenter">
          <span class="smallFont boldFont">{{ train.applyUnitName }}</span>
        </nut-col>
      </nut-row>
      <nut-row class="bothCenter vmg4">
        <nut-col :span="6" class="leftCenter">
          <span class="smallFont" style="color:#999999;">所在区划</span>
        </nut-col>
        <nut-col :span="18" class="leftCenter">
          <span class="smallFont boldFont">{{ train.addressArea }}</span>
        </nut-col>
      </nut-row>
      <nut-row class="bothCenter vmg4">
        <nut-col :span="6" class="leftCenter">
          <span class="smallFont" style="color:#999999;">单位地址</span>
        </nut-col>
        <nut-col :span="18" class="leftCenter">
          <span class="smallFont boldFont">{{ train.address }}</span>
        </nut-col>
      </nut-row>
      <nut-row class="bothCenter vmg4">
        <nut-col :span="6" class="leftCenter">
          <span class="smallFont" style="color:#999999;">培训人数</span>
        </nut-col>
        <nut-col :span="18" class="leftCenter">
          <span class="smallFont boldFont">{{ train.personNum }}</span>
        </nut-col>
      </nut-row>
    </div>
    <div class="wbg pd16 colFlex brd16">
      <nut-row class="bothCenter">
        <nut-col :span="6" class="leftCenter">
          <span class="smallFont" style="color:#999999;">申请时间</span>
        </nut-col>
        <nut-col :span="18" class="leftCenter">
          <span class="smallFont boldFont">{{ train.createdTime }}</span>
        </nut-col>
      </nut-row>
      <nut-row class="bothCenter mt16" v-if="train.auditTime">
        <nut-col :span="6" class="leftCenter">
          <span class="smallFont" style="color:#999999;">审核时间</span>
        </nut-col>
        <nut-col :span="18" class="leftCenter">
          <span class="smallFont boldFont">{{ train.auditTime }}</span>
        </nut-col>
      </nut-row>
      <nut-row class="bothCenter mt16" v-if="train.confirmStartTime">
        <nut-col :span="6" class="leftCenter">
          <span class="smallFont" style="color:#999999;">确定时间</span>
        </nut-col>
        <nut-col :span="18" class="leftCenter">
          <span class="smallFont boldFont">{{ train.confirmStartTime }}</span>
        </nut-col>
      </nut-row>
      <nut-row class="bothCenter mt16" v-if="train.actualStartTime">
        <nut-col :span="6" class="leftCenter">
          <span class="smallFont" style="color:#999999;">开始时间</span>
        </nut-col>
        <nut-col :span="18" class="leftCenter">
          <span class="smallFont boldFont">{{ train.actualStartTime }}</span>
        </nut-col>
      </nut-row>
      <nut-row class="bothCenter mt16" v-if="train.actualEndTime">
        <nut-col :span="6" class="leftCenter">
          <span class="smallFont" style="color:#999999;">完成时间</span>
        </nut-col>
        <nut-col :span="18" class="leftCenter">
          <span class="smallFont boldFont">{{ train.actualEndTime }}</span>
        </nut-col>
      </nut-row>
      <nut-row class="bothCenter mt16 lightRed" v-if="train.state == 'CANCEL'">
        <nut-col :span="6" class="leftCenter">
          <span class="smallFont">驳回时间</span>
        </nut-col>
        <nut-col :span="18" class="leftCenter">
          <span class="smallFont boldFont">{{ train.auditTime }}</span>
        </nut-col>
      </nut-row>
      <div class="reject smallFont lightRed pd8 brd8 mt16" v-if="train.state == 'CANCEL'">
        <span>理由：{{ train.reason }}</span>
      </div>
    </div>
    <div class="pd16 colFlex mt8 bothCenter" v-if="train.needEvaluate">
      <nut-button class="commentBtn" @click="$router.push('/train/evaluate/'+train.id)">我要评价</nut-button>
    </div>
  </div>
</template>

<script>
import api from "@/api";

export default {
  name: "TrainDateHistoryDetailView",
  data() {
    return {
      dateId: this.$route.params.dateId,
      train: {},
      trainState: '-',
      leader: {},
      stateMap: {
        INIT: "未提交",
        COMMIT: "待审核",
        CANCEL: "已取消",
        PASS: "待培训",
        PROCESSING: "培训中",
        FINISH: "已完成",
      }
    }
  },
  methods: {
    init() {
      api.getMyTrainDateList({
        dateId: this.dateId,
      }).then(ret => {
        this.train = ret[0];
      })
    },
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.DateHistoryDetailViewContainer {
  display: flex;
  flex-direction: column;
  background-color: #F4F6F9;
  padding: 16px;
  background-image: url("https://cdn.waityou.online/37cb1d79-9515-335c-c596-fceff43c4a7c.png");
  background-size: 100%;
  background-position: top;
  background-repeat: no-repeat;
  min-height: calc(100vh - 32px);

  .commentBtn{
    width: 50%;
    border: none;
    color:white;
    height: 44px;
    background: #2F438C;
    border-radius: 12px;
  }

  .reject {
    background-color: rgba(255, 30, 50, 0.1);;
  }

  .lightRed {
    color: #FF1E32;
  }

  .joiner {
    width: 28px;
    height: 28px;
    border-radius: 14px;
  }

  .coverLeft {
    margin-left: -14px;
  }
}
</style>