<template>
  <div class="IndexContainer">
    <div style="background-image: url('https://cdn.waityou.online/a286c6cc-46f0-451a-d934-944f40b9de83.png');
    background-repeat: no-repeat;background-size: 100%;
    width: 100%;height: 100%">
      <div class="user" @click="$router.push('/my')">
        <img :src="user.avatar"/>
        <span class="smallFont">&nbsp;你好！{{ user.nickName }}</span>
        <nut-icon name="right" size="10" color="#999999"></nut-icon>
      </div>
      <div class="moreAboutUs leftCenter">
        <span>已有{{ receptionCnt }}名市民走进服务平台。</span>
      </div>
      <div class="whatWeDo">
        <nut-row class="bothCenter" :gutter="16">
          <nut-col :span="12" class="leftIndexBox">
            <WhatWeDoItemNew :data="menus[0]" @click="goToView('/visit')"/>
          </nut-col>
          <nut-col :span="12" class="rightIndexBox">
            <WhatWeDoItemNew :data="menus[1]" @click="goToView('/train')"/>
          </nut-col>
        </nut-row>
        <div class="indexDivider"/>
        <nut-row class="bothCenter" :gutter="16">
          <nut-col :span="12" class="leftIndexBox">
            <WhatWeDoItemNew :data="menus[2]" @click="goToView('/drill')"/>
          </nut-col>
          <nut-col :span="12" class="rightIndexBox">
            <WhatWeDoItemNew :data="menus[3]" @click="goToView('/study')"/>
          </nut-col>
        </nut-row>
        <div class="indexDivider"/>
        <nut-row class="bothCenter" :gutter="16">
          <nut-col :span="12" class="leftIndexBox">
            <WhatWeDoItemNew :data="menus[4]" @click="xlgx"/>
          </nut-col>
          <nut-col :span="12" class="rightIndexBox">
            <WhatWeDoItemNew :data="menus[5]" @click="goToView('/consult')"/>
          </nut-col>
        </nut-row>
      </div>
    </div>
<!--    <div style="position: fixed;right: 13%;top: 50%">-->
<!--      <nut-popover v-model:visible="animationVisible" targetId="popid" location="left-start">-->
<!--        <template #content>-->
<!--          <div class="colFlex pd16 smallFont">-->
<!--            <span>欢迎使用，杭州消防救援</span>-->
<!--            <span>便民服务平台！</span>-->
<!--          </div>-->
<!--        </template>-->
<!--      </nut-popover>-->
<!--    </div>-->
<!--    <div style="position: fixed;right: 0;top: 50%">-->
<!--      <img v-show="!animationVisible" @click="playAnimation"-->
<!--           src="https://cdn.waityou.online/212931e8-4594-ae26-add4-ef606229d44a.png" style="height: 150px"/>-->
<!--      <img v-show="animationVisible" @click="animationVisible=false"-->
<!--           id="popid"-->
<!--           :src="`https://cdn.waityou.online/8c483617-149e-fe36-4ce9-57c6570bf584.png?v=${animationVersion}`"-->
<!--           style="height: 150px"/>-->
<!--    </div>-->
  </div>
</template>


<script>
import api from "@/api";
import WhatWeDoItemNew from "@/components/index/WhatWeDoItemNew"
import {Dialog} from "@nutui/nutui";

export default {
  name: "IndexView",
  components: {WhatWeDoItemNew},
  data() {
    return {
      needAsk: false,
      receptionCnt: 0,
      animationVisible: false,
      animationVersion: 0,
      user: {},
      banners: [],
      scrollHeight: 0,
      menus: [
        {
          icon: 'https://cdn.waityou.online/b93293ea-6910-4766-c669-9d8fb7411e92.png',
          bgColor: '#F3F9FF',
          title: '我要参观',
          subTitle: '亲手摸摸消防车',
        },
        {
          icon: 'https://cdn.waityou.online/aa63a069-830c-895a-de79-0cf9225e14b3.png',
          bgColor: '#FFF6E9',
          title: '我要培训',
          subTitle: '我的安全我做主',
        },
        {
          icon: 'https://cdn.waityou.online/9cb53f4d-5d6d-62c5-1c82-28f5711b9324.png',
          bgColor: '#EEFFF7',
          title: '我要演练',
          subTitle: '假如真的着火了',
        },
        {
          icon: 'https://cdn.waityou.online/07f423d4-c4f7-9f5b-5196-f383c2601554.png',
          bgColor: '#FFF4F0',
          title: '我要学习',
          subTitle: '学消防为自己',
        }, {
          icon: 'https://cdn.waityou.online/da140bf5-9731-cd78-108b-1916b505071d.png',
          bgColor: '#FFFBF2',
          title: '我要娱乐',
          subTitle: '消了个消',
        },
        {
          icon: 'https://cdn.waityou.online/f26194b6-cd0d-ffd9-3fe6-59e57c6bdad1.png',
          bgColor: '#F3F9FF',
          title: '我要咨询',
          subTitle: '为民服务每一天',
        },
      ]
    }
  },
  methods: {
    goToView(url) {
      if(this.needAsk){
        Dialog({
          title: '知晓率问卷',
          content:'花您2分钟时间回答消防安全知晓率问卷。谢谢参与！',
          okText:'去回答',
          noCancelBtn:true,
          onCancel: () => {
          },
          onOk: () => {
            this.$router.push('/ask')
          }
        });
      }else{
        this.$router.push(url)
      }
    },
    playAnimation() {
      this.animationVisible = true;
      setTimeout(() => {
        this.animationVisible = false;
        this.animationVersion++;
      }, 4200)
    },
    xlgx() {
      window.location.href = 'https://xlgx.isafety365.com/index.php?s=/game/index/cocos';
    },
  },
  mounted() {
    api.getUserInfo().then(ret => {
      this.user = ret;
    });
    api.isNeedAskPaper()
        .then(ret => {
          this.needAsk = ret;
          // if (ret) {
          //   this.$router.push('/ask')
          // }
        })
    api.getReceptionCnt()
        .then(ret => {
          this.receptionCnt = ret;
        })
  }
}
</script>

<style scoped lang="scss">
.IndexContainer {
  padding: 16px;
  background: linear-gradient(to bottom, #FFF, #f5f6f8,#485A89);
  min-height: calc(100vh - 32px);

  .whatWeDo {
    display: flex;
    background-color: white;
    flex-direction: column;
    border-radius: 16px;
    padding: 16px;

    .tip {
      padding-bottom: 12px;
    }

    .indexDivider {
      height: 16px;
    }

    .leftIndexBox {
      display: flex;
    }

    .rightIndexBox {
      display: flex;
    }
  }

  .user {
    display: inline-flex;
    width: auto;
    height: 32px;
    background: linear-gradient(to bottom, #EEDEE3, #d0d0ea);

    color: #001734;
    border-radius: 16px;
    padding: 0 8px;
    align-items: center;

    img {
      width: 24px;
      height: 24px;
      border-radius: 12px;
      box-shadow: 0px 2px 24px 0px rgba(222, 237, 255, 0.8);
    }
  }

  .moreAboutUs {
    width: 60%;
    background-color: #23245e;
    border-radius: 16px 16px 0px 0px;
    padding: 8px 16px;
    margin-top: 55%;

    img {
      width: 20px;
      height: 20px;
    }

    span {
      font-size: $miniFontSize;
      color: #fcf3df;
      margin-left: 5px;
    }
  }
}
</style>