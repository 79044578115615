<template>
  <div class="WhatWeDoItemContainer bothCenter">
    <img :src="data.icon"/>
  </div>
</template>

<script>
export default {
  name: "WhatWeDoItemNew",
  props: {
    data: {
      type: Object,
      default: () => {
        return {
          icon:'https://cdn.waityou.online/6137ea4c-d23a-6dc0-371d-a3b7d28d885e.png',
        }
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.WhatWeDoItemContainer {
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  border-radius: 16px;
  width: 100%;
  background-color: #f8f8fb;

  .title{
    padding: 4px 0;
  }
  img{
    height: 90px;
    width: 115px;
  }

  .subTitle {
    font-size: $miniFontSize;
    color: #999999;
  }
}
</style>