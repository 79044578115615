<template>
  <nut-row class="errorPage">
    <svg-icon icon-class="error" class-name="big"/>
    <span>哎呀，遇到错误了</span>
    <router-link to="/" style="margin-top: 5%">
      <nut-button>返回首页</nut-button>
    </router-link>
  </nut-row>
</template>

<script>
import SvgIcon from "@/components/SvgIcon";
export default {
  name: "ErrorPage",
  components: {SvgIcon}
}
</script>

<style lang="scss" scoped>
.errorPage{
  display: flex;
  flex-direction: column;
  font-size: 25px;
  color: #498ce3;
  justify-items: center;
  align-items: center;
  margin-top: 30%;
}
</style>