<template>
  <div class="ExamResultContainer bothCenter">
    <CertReward :cert="cert" v-if="cert"/>
    <ExamScore v-else :data="record" class="mb16"/>
    <div class="bothCenter wd100">
      <nut-button class="returnBtn" @click="$router.replace('/')">返回首页</nut-button>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import CertReward from "@/components/exam/CertReward";
import ExamScore from "@/components/exam/ExamScore";

export default {
  name: "ExamResult",
  components: {ExamScore, CertReward},
  data() {
    return {
      record: {},
      cert: null,
      recordId: this.$route.params.recordId,
    }
  },
  methods: {
    init() {
      api.getExamResult(this.recordId)
          .then(ret => {
            this.record = ret.record;
            this.cert = ret.cert;
          })
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.ExamResultContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  min-height: 100vh;

  .returnBtn {
    height: 44px;
    background: rgba(0, 100, 255, 0.1);
    border-radius: 12px;
    color: #2F438C;
    border: none;
    width: 50%;
  }
}
</style>