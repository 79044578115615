<template>
  <div class="ConsultRegionInfoViewContainer">
    <template v-for="(r,ri) in regions" :key="ri">
      <span class="baseFont boldFont vmg16">{{r.region}}</span>
      <div class="colMenuContainer">
        <nut-row @click="navigation(r.location)">
          <nut-col :span="2" class="leftCenter">
            <img src="https://cdn.waityou.online/4996594f-c09e-2a6c-e1d0-9665c7e56730.png"
                 style="width: 14px;height: 14px;margin-right: 5px"/>
          </nut-col>
          <nut-col :span="22" class="colFlex">
            <span class="smallFont">{{ r.building }}</span>
            <span class="miniFont"
                  style="color:#999999;">{{ r.office }}</span>
          </nut-col>
        </nut-row>
        <div class="divider vmg16"></div>
        <nut-row>
          <nut-col :span="2" class="leftCenter">
            <img src="https://cdn.waityou.online/469eedda-ff26-f029-15c8-4e65dae16d2c.png"
                 style="width: 14px;height: 14px;margin-right: 5px"/>
          </nut-col>
          <nut-col :span="22" class="colFlex">
            <span class="smallFont">{{ r.workDate }}</span>
            <span class="miniFont" style="color:#999999;">{{ r.workTime }}</span>
          </nut-col>
        </nut-row>
        <div class="divider vmg16"></div>
        <a :href="`tel:${r.phone}`">
          <nut-row >
            <nut-col :span="2" class="leftCenter">
              <img src="https://cdn.waityou.online/beae4020-6fa2-3659-695b-c5beff597c41.png"
                   style="width: 14px;height: 14px;margin-right: 5px"/>
            </nut-col>
            <nut-col :span="22" class="colFlex">
              <span class="smallFont" style="color: black">{{ r.phone }}</span>
              <span class="miniFont" style="color:#999999;">外地号码请加区号0571</span>
            </nut-col>
          </nut-row>
        </a>
      </div>
    </template>
  </div>
</template>

<script>
import {Toast} from "@nutui/nutui";

export default {
  name: "ConsultRegionInfoView",
  data() {
    return {
      regions: [
        {
          region: '上城区',
          building: '杭州市上城区行政服务中心',
          office: '杭州市上城区四季青街道运河东路200号（凤起东路888号）237办公室',
          workDate: '法定工作日',
          workTime: '上午9:00-12:00，下午13:30-17：00，法定节假日按国家假期安排调整办理时间',
          phone: '0571-87925976',
          location:'120.22532,30.26953',
        },
        {
          region: '拱墅区',
          building: '杭州市拱墅区政务服务中心',
          office: '杭州市拱墅区文晖街道香积寺东路58号2楼259室消防窗口',
          workDate: '法定工作日',
          workTime: '上午9:00-12:00，下午13:30-17：00，法定节假日按国家假期安排调整办理时间',
          phone: '0571-87939765',
          location:'120.177771,30.308074',
        },
        {
          region: '西湖区',
          building: '西湖区政务服务中心',
          office: '杭州市西湖区蒋村街道文一西路858号',
          workDate: '法定工作日',
          workTime: '上午9:00-12:00，下午13:30-17：00（夏令时14:00-17:00），法定节假日按国家假期安排调整办理时间',
          phone: '0571-88156320、0571-81109647',
          location:'120.065643,30.287767',
        },
        {
          region: '滨江区',
          building: '杭州高新区（滨江）政务服务中心江南办事大厅',
          office: '杭州市滨江区泰安路200号一楼B区B1号窗口',
          workDate: '法定工作日',
          workTime: '上午9:00-12:00，下午13:30-17：00（夏令时14:00-17:00），法定节假日按国家假期安排调整办理时间',
          phone: '0571-89521350',
          location:'120.211981,30.208332',
        },
        {
          region: '萧山区',
          building: '杭州市萧山区行政服务中心',
          office: '杭州市萧山区北干街道市心中路1069号（科技创新中心B楼）三楼112-117号投资项目审批综合受理窗口',
          workDate: '法定工作日',
          workTime: '上午9:00-12:00，下午13:30-17：00，法定节假日按国家假期安排调整办理时间',
          phone: '0571-82899295',
          location:'120.265649,30.187406',
        },
        {
          region: '余杭区',
          building: '余杭区行政服务中心',
          office: '杭州市余杭区余杭街道凤新路366号三楼306室',
          workDate: '法定工作日',
          workTime: '上午8：30-12:00，下午13:30-17:00',
          phone: '0571-89395028',
          location:'119.955233,30.259799',
        },
        {
          region: '富阳区',
          building: '杭州市富阳区行政服务中心',
          office: '杭州市富阳区鹿山街道江连街27号A幢二楼F4、5、7、9、11、13号窗口',
          workDate: '法定工作日',
          workTime: '上午08:30-11:30，下午13:00-16:30（法定节假日按国家假期安排调整办理时间）',
          phone: '0571-63155983',
          location:'119.930141,30.011183',
        },
        {
          region: '临安区',
          building: '临安区行政服务中心',
          office: '杭州市临安区锦北街道科技大道4398号行政服务中心A座二楼A222',
          workDate: '法定工作日',
          workTime: '周一至周五：夏季：上午8:30-11：30，下午:14:00-17:00；冬季：上午8:30-11:30，下午13:00-16:30（法定节假日按国家假期安排调整办理时间）',
          phone: '0571-23616119',
          location:'119.826344,30.2699',
        },
        {
          region: '桐庐县',
          building: '桐庐县行政服务中心',
          office: '杭州市桐庐县城南街道迎春南路258号3楼综合服务区（C厅）22号消防窗口',
          workDate: '法定工作日',
          workTime: '工作日星期一至星期五：上午8:30-12：00，下午13:30-17:00（夏令时14:00-17:30）（法定节假日按国家假期安排调整办理时间）',
          phone: '0571-64217181',
          location:'119.69431,29.790861',
        },
        {
          region: '淳安县',
          building: '淳安县行政服务中心',
          office: '杭州市淳安县千岛湖镇环湖北路377号淳安县行政服务中心二楼J区4号窗口',
          workDate: '法定工作日',
          workTime: '星期一至星期五：上午08:30—12:00，下午（夏令时14:00—17:30）（冬令时：13:30—17:00）(法定节假日按国家假期安排调整办理时间）',
          phone: '0571-64819840',
          location:'淳安县行政服务中心',
        },
        {
          region: '建德市',
          building: '建德市行政服务中心',
          office: '杭州市建德市洋溪街道洋安社区荷映路113号2楼C29号窗口',
          workDate: '法定工作日',
          workTime: '星期一至星期五： 上午8:30-11:30;下午:13:30-17:00（法定节假日按国家假期安排调整办理时间）',
          phone: '0571-64780211',
          location:'119.314248,29.501738',
        },
        {
          region: '风景名胜区',
          building: '杭州西湖风景名胜区行政服务中心',
          office: '杭州市西湖区赵公堤18号1楼7号消防窗口',
          workDate: '法定工作日',
          workTime: '星期一至星期五，上午8:30-11:45,下午14:00-17:00（法定节假日按国家假期安排调整办理时间）',
          phone: '0571-87977955',
          location:'120.124664,30.246946',
        },
        {
          region: '钱塘区',
          building: '钱塘区政务服务中心',
          office: '钱塘区下沙街道金沙大道600号东楼一楼B02号窗口',
          workDate: '法定工作日',
          workTime: '9：00-12:00，13：30-17:00（法定节假日按国家假期安排调整办理时间）',
          phone: '0571-89898541',
          location:'120.324883,30.301816',
        },
        {
          region: '临平区',
          building: '临平区行政服务中心',
          office: '杭州市临平区南苑街道时代社区南大街265号2楼G区27号综合窗口',
          workDate: '法定工作日',
          workTime: '冬季：上午8：30-12:00，下午13:00-16:30；夏季：上午8:30-12:00，下午13:30-17:00',
          phone: '0571-89163908',
          location:'120.30984,30.407381',
        },
      ]
    }
  },
  methods: {
    navigation(loc){
      Toast.loading('正在跳转，请确认开启定位')
      window.open(`https://uri.amap.com/navigation?to=${loc}&mode=car&policy=&src=xfbm&coordinate=gaode&callnative=1`)
    },
    init() {

    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.ConsultRegionInfoViewContainer {
  display: flex;
  flex-direction: column;
  background-color: #F4F6F9;
  padding: 0 16px 16px 16px;

  .colMenuContainer {
    background-color: white;
    border-radius: 16px;
    padding: 16px;
  }
}
</style>