<template>
  <div class="SearchBarContainer">
    <div class="searchContainer">
      <div class="searchInput leftCenter">
        <nut-icon name="search" style="color:black"></nut-icon>
        <input type="text" placeholder="请输入地点..." v-model="query.name" @change="onSearchName">
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "SearchBar",
  data() {
    return {
      query: {
        name:null,
        departmentId:null,
      },
      regionMap: {},
    }
  },
  methods: {
    onSearchName(){
      this.$emit('search', this.query)
    }
  },
  mounted() {

  }
}
</script>

<style lang="scss" scoped>
input:focus {
  outline: none;
}

.SearchBarContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: $baseFontSize;

  .searchContainer {
    margin: 16px;
    background-color: white;
    border-radius: 16px;
    padding: 8px 16px;
    display: flex;
    flex-direction: column;


    .selector {
      font-size: $smallerFontSize;
    }

    .divider {
      height: 1px;
      background-color: #eae9e9;
      width: 100%;
      margin: 8px 0;
    }

    .searchInput {
      input {
        margin-left: 5%;
        width: 95%;
        border: none;
      }
    }
  }
}
</style>