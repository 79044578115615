<template>
  <div class="CreatePinGroupViewContainer">
    <nut-row className="bothCenter smallFont opd16 mt16"
             @click="gotoTeacherList"
             v-if="teacherListVisible">
      <nut-col :span="20" class="leftCenter vpd8"  style="background-color: rgba(19,86,255,0.2);color: #2F438C;border-radius: 8px 0 0 8px">
        <span class="ml8">预约查看所有讲师</span>
      </nut-col>
      <nut-col :span="4" class="rightCenter vpd8"  style="background-color: rgba(19,86,255,0.2);color: #2F438C;border-radius: 0 8px 8px 0">
        <span class="mr8">查看</span>
      </nut-col>
    </nut-row>
    <div class="pd16" style="padding-bottom: 0">
      <div class="wbg pd16 br16 colFlex">
        <nut-row class="leftCenter mb16">
          <span class="baseFont boldFont">预约信息</span>
        </nut-row>
        <nut-row class="bothCenter mb16">
          <nut-col :span="6" class="leftCenter">
            <span class="smallFont">申请单位<span class="redDot miniFont">*</span></span>
          </nut-col>
          <nut-col :span="18" class="leftCenter">
            <input class="smallFont" style="width: 80%" placeholder="请输入单位名称" v-model="dateInfo.applyUnitName"/>
          </nut-col>
        </nut-row>
        <div class="divider mb16"></div>
        <nut-row class="bothCenter mb16">
          <nut-col :span="6" class="leftCenter">
            <span class="smallFont">单位地址<span class="redDot miniFont">*</span></span>
          </nut-col>
          <nut-col :span="18" class="leftCenter">
            <input class="smallFont" style="width: 80%" placeholder="请输入单位地址" v-model="dateInfo.address"/>
          </nut-col>
        </nut-row>
        <div class="divider mb16"></div>
        <nut-row class="bothCenter mb16">
          <nut-col :span="6" class="leftCenter">
            <span class="smallFont">所在区划<span class="redDot miniFont">*</span></span>
          </nut-col>
          <nut-col :span="18" class="leftCenter" @click="cascadeVisible=true">
            <span class="smallFont" style="color:#999999;">{{ dateInfo.addressArea }}</span>
          </nut-col>
        </nut-row>
        <div class="divider mb16"></div>
        <nut-row class="bothCenter mb16">
          <nut-col :span="6" class="leftCenter">
            <span class="smallFont">培训主题</span>
          </nut-col>
          <nut-col :span="18" class="leftCenter" @click="topicVisible=true">
            <span class="smallFont" style="color:#999999;">{{ dateInfo.trainTopicName }}</span>
          </nut-col>
        </nut-row>
        <div class="divider mb16"></div>
        <nut-row class="bothCenter mb16">
          <nut-col :span="6" class="leftCenter">
            <span class="smallFont">培训时间<span class="redDot miniFont">*</span></span>
          </nut-col>
          <nut-col :span="18" class="leftCenter">
            <span class="smallFont"  @click="expectStartTimeVisible=true"
                  style="color:#999999;">{{ dateInfo.expectStartTime ? dateInfo.expectStartTime : '请选择' }}</span>
            <span style="width: 50px;text-align: center">~</span>
            <span class="smallFont"  @click="expectEndTimeVisible=true"
                  style="color:#999999;">{{ dateInfo.expectEndTime ? dateInfo.expectEndTime : '请选择' }}</span>
          </nut-col>
        </nut-row>
        <div class="divider mb16"></div>
        <nut-row class="bothCenter mb16">
          <nut-col :span="6" class="leftCenter">
            <span class="smallFont">培训人数<span class="redDot miniFont">*</span></span>
          </nut-col>
          <nut-col :span="18" class="leftCenter">
            <input class="smallFont" style="width: 80%" placeholder="请输入报名人数" v-model="dateInfo.personNum"/>
          </nut-col>
        </nut-row>
      </div>
      <div class="wbg pd16 br16 mt16 colFlex">
        <nut-row class="bothCenter mb16">
          <nut-col :span="16" class="leftCenter">
            <span class="baseFont boldFont">联系人信息</span>
          </nut-col>
          <nut-col :span="8" class="rightCenter">
            <span class="oftenContact boldFont smallFont" @click="myContactVisible=true">常用联系人</span>
          </nut-col>
        </nut-row>
        <nut-row class="bothCenter mb16">
          <nut-col :span="6" class="leftCenter">
            <span class="smallFont">联系人<span class="redDot miniFont">*</span></span>
          </nut-col>
          <nut-col :span="18" class="leftCenter">
            <input class="smallFont" style="width: 80%" placeholder="请输入姓名" v-model="dateInfo.teamLeader"/>
          </nut-col>
        </nut-row>
        <div class="divider mb16"></div>

        <nut-row class="bothCenter mb16">
          <nut-col :span="6" class="leftCenter">
            <span class="smallFont">联系号码<span class="redDot miniFont">*</span></span>
          </nut-col>
          <nut-col :span="18" class="leftCenter">
            <input class="smallFont" style="width: 80%" placeholder="请输入联系号码" v-model="dateInfo.teamLeaderPhone"/>
          </nut-col>
        </nut-row>
      </div>
      <nut-row class="bothCenter vpd16">
        <nut-button class="submitBtn" @click="save">预约培训</nut-button>
      </nut-row>
    </div>
  </div>
  <nut-overlay :close-on-click-overlay="false" v-model:visible="myContactVisible">
    <MyContactList @close="myContactVisible=false"
                   v-if="myContactVisible"
                   @use="useContact"/>
  </nut-overlay>
  <nut-cascader
      title="区划选择"
      v-model:visible="cascadeVisible"
      text-key="label"
      @change="regionChange"
      lazy
      :lazy-load="loadRegion"
  ></nut-cascader>
  <nut-picker
      v-model:visible="topicVisible"
      :columns="topics"
      title="城市选择"
      @confirm="onTopicSelect"
  >
  </nut-picker>
  <nut-datepicker
      v-model="expectStartTime"
      title="期望培训开始日期选择"
      type="date"
      :min-date="minDate"
      @confirm="onexpectStartTimeConfirm"
      v-model:visible="expectStartTimeVisible"
  ></nut-datepicker>
  <nut-datepicker
      v-model="expectEndTime"
      title="期望培训结束日期选择"
      type="date"
      :min-date="minDate"
      @confirm="onexpectEndTimeConfirm"
      v-model:visible="expectEndTimeVisible"
  ></nut-datepicker>
</template>

<script>
import MyContactList from "@/components/contact/MyContactList";
import {Toast} from "@nutui/nutui";
import api from "@/api";

export default {
  name: "TeachDateView",
  components: {MyContactList},
  data() {
    return {
      minDate: this.afterDays(7),
      teacherListVisible: false,
      expectStartTimeVisible: false,
      expectEndTimeVisible: false,
      topicVisible: false,
      cascadeVisible: false,
      myContactVisible: false,
      stationId: this.$route.params.stationId,
      expectStartTime: null,
      expectEndTime: null,
      dateInfo: {
        stationId: this.$route.params.stationId,
        applyUnitName: null,
        address: null,
        addressAreaId: null,
        addressArea: '请选择',
        personNum: null,
        expectStartTime: null,
        expectEndTime: null,
        // trainTopicId: null,
        trainTopicName: '请选择',
        teamLeader: null,
        teamLeaderPhone: null,
      },
      topics: [],
      regionMap: {},
      defaultImg: 'https://cdn.waityou.online/7e5216bd-b764-2b2e-4a97-19613b2ed6e5.png'
    }
  },
  methods: {
    afterDays(d){
      // 创建当前日期的Date对象
      var currentDate = new Date();
      // 获取当前日期的天数
      var day = currentDate.getDate();
      // 将日期设置为当前日期加7天
      currentDate.setDate(day + d);
      return currentDate;
    },
    gotoTeacherList(){
      this.$router.push('/teach/date/teacher/list/'+this.stationId)
    },
    save() {
      for(let k of Object.keys(this.dateInfo)){
        if(!this.dateInfo[k]){
          Toast.fail("请填写完整")
          return
        }
      }
      this.dateInfo.teamName =this.dateInfo.applyUnitName;
      Toast.loading('请稍等...')
      api.dateTrain(this.dateInfo)
          .then(() => {
            Toast.hide()
            Toast.success('预约成功')
            this.$router.replace('/my/date?t=1')
          })
    },
    onexpectStartTimeConfirm(time) {
      let t = time.selectedValue;
      this.dateInfo.expectStartTime = `${t[0]}-${t[1]}-${t[2]}`;
    },
    onexpectEndTimeConfirm(time) {
      let t = time.selectedValue;
      this.dateInfo.expectEndTime = `${t[0]}-${t[1]}-${t[2]}`;
    },
    onTopicSelect(e) {
      this.dateInfo.trainTopicId = e.selectedOptions[0].value;
      this.dateInfo.trainTopicName = e.selectedOptions[0].label;
    },
    regionChange(e) {
      this.dateInfo.addressArea = e.map(el => this.regionMap[el]).join('/');
      this.dateInfo.addressAreaId = e[e.length - 1];
    },
    loadRegion(node, callback) {
      if (node.root) {
        api.getRegionList(1)
            .then(ret => {
              this.parseRegions(ret, callback)
            })
      } else if (!node.leaf) {
        api.getRegionList(node.value)
            .then(ret => {
              this.parseRegions(ret, callback)
            })
      } else {
        callback([]);
      }
    },
    parseRegions(list, callback) {
      for (let r of list) {
        this.regionMap[r.value] = r.label;
      }
      callback(list);
    },

    useContact(c) {
      this.dateInfo.teamLeader = c.name;
      this.dateInfo.teamLeaderPhone = c.phone;
    },
    init() {
      api.getTrainTopics()
          .then(ret => {
            this.topics = ret;
          })
      api.getTeacherListVisible()
          .then(ret=>{
            this.teacherListVisible = ret;
          })
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>

.CreatePinGroupViewContainer {
  display: flex;
  flex-direction: column;
  background-color: #F4F6F9;
  min-height: 120vh;

  .submitBtn {
    border: none;
    color: white;
    width: 40%;
    height: 44px;
    background: #2F438C;
    border-radius: 12px;
  }

  .uploader {
    width: 80px;
    height: 45px;
  }

  .redDot {
    color: red;
  }

  input {
    border: none;
  }

  input:focus {
    outline: none;
  }

  .oftenContact {
    color: #2F438C;
    background-color: rgba(0, 100, 255, 0.1);
    border-radius: 8px;
    padding: 6px 4px;
  }

  .blueBgNotice {
    display: flex;
    flex-direction: column;
    height: 196px;
    background: #2F438C;
    border-radius: 0px 0px 24px 24px;
  }
}
</style>