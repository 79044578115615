<template>
  <nut-uploader :multiple="false" :maximum="limit" :accept="accept"
                @delete="handleUploadImageRemove"
                @success="handleImageUploadSuccess"
                :maximize="1024 * 1024 * 20"
                :is-preview="false"
                :before-xhr-upload="uploadRequest">
    <slot/>
  </nut-uploader>
</template>

<script>
import api from "@/api";
import {Toast} from "@nutui/nutui";

export default {
  name: "CustomOssUploader",
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: 1
    },
    accept: {
      type: String,
      default: 'image/*'
    },
    files: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      ossInitState: false,
      uploading: false,
      ossDomain: null,
      ossFolder: null,
      uploaded:[],
    }
  },
  methods: {
    initOssClint() {
      if (this.ossInitState) {
        console.log('OSS已经完成初始化!')
        return
      }
      this.ossInitState = true;
      let OSS = require('ali-oss');
      api.getOssKeySecret().then(cfg => {
        this.ossClient = new OSS({
          region: cfg.region,
          accessKeyId: cfg.accessKeyId,
          accessKeySecret: cfg.accessKeySecret,
          stsToken: cfg.securityToken,
          bucket: cfg.bucket,
          endpoint: cfg.endpoint,
          secure: true
        });
        this.ossDomain = cfg.domain;
        this.ossFolder = cfg.folder;
        console.log('OSS初始化完成!')
      })
    },
    guid() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
            v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    },
    uploadRequest(xhr, options) {
      try {
        let file = options.sourceFile; // 拿到 file
        let fileType = file.name.substr(file.name.lastIndexOf('.'))
        let fileName = (this.ossFolder ? this.ossFolder + '/' : 'user-upload/') + this.guid() + fileType;
        Toast.loading('上传中...')
        // 上传文件,这里是上传到OSS的 uploads文件夹下
        this.ossClient.put(fileName, file).then(res => {
          Toast.hide()
          if (res.res.statusCode === 200) {
            options.onSuccess(res)
          } else {
            options.onError("上传失败")
          }
        })
      } catch (e) {
        options.onError("上传失败")
      }
    },
    handleImageUploadSuccess(resp) {
      // console.log('上传成功1个文件', resp.responseText);
      this.uploaded.push(resp.responseText.url);
      this.$emit('onUploadedFiles', this.uploaded);
    },
    handleUploadImageRemove(del) {
      this.uploaded.splice(del.index, 1);
      this.$emit('onUploadedFiles', this.uploaded);
    }
  },
  mounted() {
    this.initOssClint();
  }
}
</script>

<style scoped>

</style>