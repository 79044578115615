<template>
  <div class="ListStationItemContainer">
    <div>
      <span class="distance">距你距离{{calDistance()}}</span>
    </div>
    <div class="leftCenter">
      <span class="stationName">{{ data?.station.name }}</span>
      <span class="pinTag">拼团</span>
    </div>
    <div>
      <span class="address">{{ data?.station.address }}</span>
    </div>
    <nut-row class="leftCenter mb8">
      <span class="miniFont mr8">评价指数</span>
      <nut-rate active-color="orange" icon-size="12"
                checked-icon="https://cdn.waityou.online/a7daaf55-aeae-d157-9eb6-e9554fdeb5f7.png"
                unchecked-icon="https://cdn.waityou.online/6caf8edf-e720-47fa-8652-976426ee5b62.png"
                readonly
                v-model="avgScore"/>
    </nut-row>
<!--    <nut-row class="bothCenter" :gutter="16">-->
<!--      <nut-col :span="12">-->
<!--        <div class="reputation bothCenter">-->
<!--          <img src="https://cdn.waityou.online/7142f4cc-1c89-daf6-5c87-fab80d5ef513.png"/>-->
<!--          <span>收到锦旗</span>-->
<!--          <span style="color:#FF4747">{{ data?.pennantCount }}面</span>-->
<!--        </div>-->
<!--      </nut-col>-->
<!--      <nut-col :span="12">-->
<!--        <div class="reputation bothCenter">-->
<!--          <img src="https://cdn.waityou.online/9cfe88d1-9d2e-f61b-9828-ab9da13c1794.png"/>-->
<!--          <span>收到鲜花</span>-->
<!--          <span style="color:#FFB35F">{{data?.flowerCount}}朵</span>-->
<!--        </div>-->
<!--      </nut-col>-->
<!--    </nut-row>-->
    <div class="divider"/>
  </div>
</template>

<script>

export default {
  name: "ListStationItem",
  props: {
    data: {
      type: Object,
      default: () => {
      }
    },
    myLocation:{
      type:Object,
      default:()=>{
        return null
      }
    },
  },
  data() {
    return {
      avgScore:5,
    }
  },
  methods: {
    init(){
      this.avgScore = this.data.station.avgScore;
    },
    calDistance() {
      if(!this.myLocation){
        return '未知'
      }
      // eslint-disable-next-line no-undef
      return  parseInt(AMap.GeometryUtil.distance(this.myLocation,
          // eslint-disable-next-line no-undef
          new AMap.LngLat(this.data.station.longitude, this.data.station.latitude)))+'米'
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.ListStationItemContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0 16px;
  font-size: $baseFontSize;
  .divider{
    background-color: #DEEDFF;
    height: 1px;
    width: 100%;
    margin-bottom: 8px;
  }

  .btn1{
    color:#2F438C;
    background: rgba(0, 100, 255, 0.1);
    border-radius: 4px;
    border: none;
    width: 90%;
    text-align: center;
    line-height: 32px;
  }
  .btn2{
    color:white;
    background: #2F438C;
    border-radius: 4px;
    border: none;
    width: 90%;
    text-align: center;
    line-height: 32px;
  }
  .stationFunction{
    display: flex;
    flex-direction: column;
    img{
      width: 24px;
      height: 24px;
    }
    span{
      font-size: $miniFontSize;
    }

  }
  .reputation {
    background-color: #F2F2F2;
    border-radius: 16px;
    height: 32px;
    margin: 8px 0;

    img {
      width: 13.75px;
      height: 16px;
    }

    span {
      margin: 4px;
      font-size: $miniFontSize;
    }
  }

  .address {
    font-size: $miniFontSize;
  }

  .stationName {
    font-size: $baseFontSize;
  }

  .pinTag {
    font-size: $minFontSize;
    color: #2FC25B;
    background-color: rgba(222, 237, 255, 0.5);
    padding: 1px;
    border-radius: 2px;
    margin-left: 2px;
  }

  .distance {
    font-size: $miniFontSize;
    color: #2F438C;
  }
}
</style>