<template>
  <div class="VisitMustKnowContainer">
    <span class="title">消防站预约参观须知</span>
    <nut-row class="subTitle">
      <nut-col :span="2" class="leftCenter">
        <img src="https://cdn.waityou.online/52dc1edf-ab6e-d7f7-7274-41265e628d5d.png"/>
      </nut-col>
      <nut-col :span="22" class="colFlex">
        <span>持有效证件实名预约</span>
        <p>中国居民身份证、港澳居民来往内地通行证、台湾居民来往大陆通行证、港澳台居民居住证、护照、外国人永久居留身份证；</p>
      </nut-col>
    </nut-row>
    <nut-row class="subTitle">
      <nut-col :span="2" class="leftCenter">
        <img src="https://cdn.waityou.online/7a2a47f8-b1c0-e9a0-bf22-abf79a1a7a13.png"/>
      </nut-col>
      <nut-col :span="22" class="colFlex">
        <span>人数限制</span>
        <p>
          参观有最少人数和最多人数限制，满足人数要求才可预约；支持自由拼团形式预约，可创建团队也可加入他人团队，组团参观；</p>
      </nut-col>
    </nut-row>
    <nut-row class="subTitle">
      <nut-col :span="2" class="leftCenter">
        <img src="https://cdn.waityou.online/7a337b08-ec9f-18f7-fbac-6bc779b04592.png"/>
      </nut-col>
      <nut-col :span="22" class="colFlex">
        <span>预约时间</span>
        <p>
          各站点开放最近2周可预约时间，参观时间如遇有国事、外事等重大活动，将无法满足您的参观需求，请您谅解。如需帮助，请与工作人员联系或拨打预约咨询服务电话
          <a href="tel:057189580761"><span style="word-break: keep-all">(0571)8958 0761</span></a>。</p>
      </nut-col>
    </nut-row>

    <p class="alert">
      温馨提示：消防站站点参观为免费提供给市民体验，请各位预约者注意自身的人身安全及财产安全，无民事行为能力人或限制行为能力人需在监护人陪同下进行体验。如在体验中因预约体验者自身原因所造成的人身及财产损害后果，本单位概不承担。
    </p>
    <div class="accept">
      <input type="checkbox"/>
      <span>我已阅读并接受预约须知</span>
    </div>
    <div class="bothCenter">
      <nut-button shape="rect" :class="time<0?'activedBtn':'waitBtn'"
                  @click="onBtnClick"
      >预约详情{{ time >= 0 ? `(${time})` : '' }}
      </nut-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "VisitMustKnow",
  data() {
    return {
      time: 5,
      countDown: null,
      stationId:this.$route.params.stationId
    }
  },
  methods: {
    onBtnClick() {
      if(this.time>0){
        return
      }
      this.$router.push('/date/visit/'+this.stationId)
    }
  },
  mounted() {
    this.countDown = setInterval(() => {
      if (this.time <= 0) {
        clearInterval(this.countDown)
      }
      this.time -= 1;
    }, 1000)
  }
}
</script>

<style lang="scss" scoped>
.VisitMustKnowContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 16px;
  font-size: $baseFontSize;

  .waitBtn {
    background-color: rgba(0, 100, 255, 0.3);
    color: white;
    border: 0;
    width: 40vw;
    margin-top: 10px;
    border-radius: 10px;
  }

  .activedBtn {
    background-color: #2F438C;
    color: white;
    border: 0;
    width: 40vw;
    margin-top: 10px;
    border-radius: 10px;
  }

  .accept {
    color: #FF9800;
    font-size: $smallerFontSize;
  }

  .alert {
    color: #2F438C;
    font-size: $smallerFontSize;
    background-color: rgba(177, 177, 177, 0.1);
    padding: 12px;
    border-radius: 8px;
  }

  .subTitle {
    margin: 8px 0;
    color: #2F438C;

    p {
      color: #000;
      margin: 8px 0;
    }

    img {
      width: 20px;
      height: 20px;
    }
  }

  .title {
    font-size: $biggerFontSize;
    font-weight: bold;
  }
}
</style>