<template>
  <div class="ConsultLicenseViewContainer">
    <div class="wbg brd16 pd16" style="padding-bottom: 50px">
      <nut-row class="bothCenter">
        <nut-col :span="20" class="leftCenter">
          <span class="smallFont">{{ infos[typeId].title }}</span>
        </nut-col>
        <nut-col :span="4" class="rightCenter">
          <img src="https://cdn.waityou.online/a4b7eeb1-0977-e963-e8ac-2321c6cccf90.png" @click="downloadFile"
               style="width: 80%"/>
        </nut-col>
      </nut-row>
      <img v-for="(img,imgIndex) in infos[typeId].imgs" :key="imgIndex" :src="img" style="width: 100%"/>
    </div>
  </div>
  <nut-row class="wd100 wbg vpd16 bothCenter" style="position:fixed;bottom: 0">
    <nut-button class="jumpBtn" @click="gotoWeb">查看政务网办理地址</nut-button>
  </nut-row>
</template>

<script>
import {Toast} from "@nutui/nutui";

export default {
  name: "ConsultLicenseView",
  data() {
    return {
      typeId: this.$route.params.typeId,
      infos: {
        1: {
          title: '公众聚集场所投入使用、营业前消防安全检查许可（宾馆、饭店）',
          file: 'https://cdn.waityou.online/7d2aebc4-b80c-0d45-798d-39d5a0eba6bb.doc',
          imgs: ['https://cdn.waityou.online/3567b6db-3125-dcdc-c2a0-b332989272ab.png', 'https://cdn.waityou.online/ff7718e4-09ea-289e-3a62-dde5ca1db8fb.png'],
          link: 'https://www.zjzwfw.gov.cn/zjservice/item/detail/index.do?localInnerCode=8d8d6f01-8c68-449e-b488-e94038d84fbb'
        },
        2: {
          title: '公众聚集场所投入使用、营业前消防安全检查许可（公共娱乐场所）',
          file: 'https://cdn.waityou.online/d7cad179-9902-9177-083f-2f57c627f5c3.doc',
          imgs: ['https://cdn.waityou.online/3567b6db-3125-dcdc-c2a0-b332989272ab.png', 'https://cdn.waityou.online/ff7718e4-09ea-289e-3a62-dde5ca1db8fb.png'],
          link: 'https://www.zjzwfw.gov.cn/zjservice/item/detail/index.do?localInnerCode=4ff8dce1-1f1f-412b-8b6b-922ad8e708d4'
        },
        3: {
          title: '公众聚集场所投入使用、营业前消防安全检查许可（体育场馆、会堂）',
          file: 'https://cdn.waityou.online/4d2fd61d-c1d8-4a8c-88a8-d7cf657d371b.doc',
          imgs: ['https://cdn.waityou.online/3567b6db-3125-dcdc-c2a0-b332989272ab.png', 'https://cdn.waityou.online/ff7718e4-09ea-289e-3a62-dde5ca1db8fb.png'],
          link: 'https://www.zjzwfw.gov.cn/zjservice/item/detail/index.do?localInnerCode=24633305-f03f-4ce8-b682-56da79ec812d'
        },
        4: {
          title: '公众聚集场所投入使用、营业前消防安全检查许可（商场、集贸市场）',
          file: 'https://cdn.waityou.online/540589a8-f8b2-7198-d95a-ab612d4e9114.doc',
          imgs: ['https://cdn.waityou.online/3567b6db-3125-dcdc-c2a0-b332989272ab.png', 'https://cdn.waityou.online/ff7718e4-09ea-289e-3a62-dde5ca1db8fb.png'],
          link: 'https://www.zjzwfw.gov.cn/zjservice/item/detail/index.do?localInnerCode=707d37bf-d5ef-4c96-86fd-b6a7a3d11b51'
        },
        5: {
          title: '公众聚集场所投入使用、营业前消防安全检查许可（客运车站候车室、客运码头候船厅、民用机场航站楼）',
          file: 'https://cdn.waityou.online/3bca68f9-3e1e-44e8-21bb-ea7d84d2e5ff.doc',
          imgs: ['https://cdn.waityou.online/3567b6db-3125-dcdc-c2a0-b332989272ab.png', 'https://cdn.waityou.online/ff7718e4-09ea-289e-3a62-dde5ca1db8fb.png'],
          link: 'https://www.zjzwfw.gov.cn/zjservice/item/detail/index.do?localInnerCode=c3cc7256-62dd-4369-8582-a7fd0ac32814'
        },
        6: {
          title: '火灾事故认定复核',
          file: 'https://cdn.waityou.online/1adada62-9341-9516-851c-c835cdfbc1fd.doc',
          imgs: ['https://cdn.waityou.online/e79270ae-ce56-c29d-1236-bdb9c789486b.jpeg'],
          link: 'https://www.zjzwfw.gov.cn/zjservice/item/detail/index.do?localInnerCode=8c471b34-1bf2-4b15-b069-aca22a12bd19'
        },
        7: {
          title: '火灾事故原因认定',
          file: 'https://cdn.waityou.online/4237f663-e7a2-df87-3cd4-8284709f6c6a.doc',
          imgs: ['https://cdn.waityou.online/05d262c4-a78c-9595-0765-dc181533e7e5.jpeg'],
          link: 'https://www.zjzwfw.gov.cn/zjservice/item/detail/index.do?localInnerCode=f458511a-9821-416a-96fa-c5795fa88607'
        },
        8: {
          title: '专职消防队验收',
          file: 'https://cdn.waityou.online/3f46148b-bc68-17da-9732-727cd262bf69.doc',
          imgs: ['https://cdn.waityou.online/a136f0e8-09a4-9380-22dc-69e15aef7c5f.jpeg'],
          link: 'https://www.zjzwfw.gov.cn/zjservice/item/detail/index.do?localInnerCode=dc12c4c6-bb6b-487d-a1dd-0d4d11cf078b'
        },
        9: {
          title: '恢复使用、生产、经营申请',
          file: 'https://cdn.waityou.online/939484b2-43e8-4971-f492-e2b80a8e8ae7.doc',
          imgs: ['https://cdn.waityou.online/f359b657-9f9a-6652-4c40-c0ea881bb3d8.png'],
          link: 'https://www.zjzwfw.gov.cn/zjservice/item/detail/index.do?localInnerCode=1d6acf1a-ef06-47eb-b83f-a2a47bddd475'
        },
        10: {
          title: '临时查封解除申请',
          file: 'https://cdn.waityou.online/3a4f44c1-4218-23e9-df7f-8c6b363f5c27.doc',
          imgs: ['https://cdn.waityou.online/29baebc9-0321-e11b-98ca-a3e11970d5f3.png'],
          link: 'https://www.zjzwfw.gov.cn/zjservice/item/detail/index.do?localInnerCode=b6c320d7-fe8d-493d-9741-e1a6df645f7e'
        },
      }
    }
  },
  methods: {
    downloadFile() {
      Toast.loading('请稍等...')
      window.open(this.infos[this.typeId].file,'_blank')
    },
    gotoWeb() {
      Toast.loading('请稍等...')
      window.location.href = this.infos[this.typeId].link
    }
  },

  mounted() {
  }
}
</script>

<style lang="scss" scoped>
.jumpBtn {
  height: 44px;
  background: #2F438C;
  border-radius: 12px;
  color: white;
  border: none;
  width: calc(100vw - 32px);
}

.ConsultLicenseViewContainer {
  display: flex;
  flex-direction: column;
  background-color: #F4F6F9;
  padding: 16px 16px 100px 16px;
  min-height: calc(100vh - 32px);
}
</style>