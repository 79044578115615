<template>
  <div class="DateSelectorContainer">

    <nut-row className="dateContainer" :gutter="12">
      <nut-col :span="12" class="bothCenter" v-for="(d,di) in plans" :key="`date_${d.id}`">
        <DateSelectorItem :data="d" :selected="selectedPid == di" @click="selectDate(di)"/>
      </nut-col>
    </nut-row>
    <nut-row className="dateContainer" :gutter="12" v-if="plans.length>0">
      <nut-col :span="12" class="bothCenter">
        <nut-button class="btn1" @click="createPin">创建拼团</nut-button>
      </nut-col>
      <nut-col :span="12" class="bothCenter">
        <nut-button class="btn2" @click="createGroupVisit">团队预约</nut-button>
      </nut-col>
    </nut-row>
  </div>
</template>

<script>
import DateSelectorItem from "@/components/date/DateSelectorItem";
import {Toast} from "@nutui/nutui";

export default {
  name: "DateSelector",
  components: {DateSelectorItem},
  props: {
    stationId: {
      type: Number,
      default: 0
    },
    plans:{
      type:Array,
      default:()=>{
        return []
      }
    }
  },
  data() {
    return {
      selectedPid: -1,
    }
  },
  methods: {
    createGroupVisit() {
      if (this.selectedPid < 0 || this.selectedPid >= this.plans.length) {
        Toast.fail('请先选择时段')
        return
      }
      this.$router.push(`/date/visit/group/create/${this.stationId}/${this.plans[this.selectedPid].id}`)
    },
    createPin() {
      if (this.selectedPid < 0 || this.selectedPid >= this.plans.length) {
        Toast.fail('请先选择时段')
        return
      }
      this.$router.push(`/date/pin/create/${this.stationId}/${this.plans[this.selectedPid].id}`)
    },
    selectDate(pid) {
      if (this.plans[pid].visit) {
        Toast.fail('该时段无法接待')
        return
      }
      this.selectedPid = pid;
    },
    init() {
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.DateSelectorContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding-bottom: 8px;
  border-radius: 0 0 24px 24px;

  .btn1 {
    width: 100%;
    height: 44px;
    color: #2F438C;
    background-color: rgba(0, 100, 255, 0.1);
    border-radius: 12px;
    border: none;
  }

  .btn2 {
    width: 100%;
    height: 44px;
    color: white;
    background: #2F438C;
    border-radius: 12px;
    border: none;
  }

  .checked {
    width: 23px;
    height: 23px;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .dateContainer {
    padding: 8px 16px;
    display: flex;
  }


}
</style>