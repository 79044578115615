<template>
  <div class="header">
    <div class="pd16 colFlex">
      <span class="baseFont boldFont">全市消防行政服务窗口</span>
      <nut-button class="lookup smallFont vmg16" @click="gotoZLB">立即查看</nut-button>
      <span class="baseFont boldFont vmg16">公众聚集场所投入使用营业前消防安全检查许可</span>
    </div>
  </div>
  <div class="ConsultViewContainer">
    <div class="menuContainer bothCenter">
      <div class="menuItem" @click="$router.push('/consult/license/1')">
        <img src="https://cdn.waityou.online/16c7f0a9-3d4a-8f71-1cd8-7c461ea9cbd3.png"/>
        <span>宾馆</span>
        <span>饭店</span>
      </div>
      <div class="menuItem" @click="$router.push('/consult/license/2')">
        <img src="https://cdn.waityou.online/c1fe4616-7e8f-3ee1-1a61-9c9c55696f24.png"/>
        <span>公共</span>
        <span>娱乐场所</span>
      </div>
      <div class="menuItem" @click="$router.push('/consult/license/3')">
        <img src="https://cdn.waityou.online/8e13f48b-4620-d125-3edf-36ab8c96db8c.png"/>
        <span>体育馆</span>
        <span>会堂</span>
      </div>
      <div class="menuItem" @click="$router.push('/consult/license/4')">
        <img src="https://cdn.waityou.online/aca776d8-0a3c-6a38-58bd-4138807aaefb.png"/>
        <span>商场</span>
        <span>集贸市场</span>
      </div>
      <div class="menuItem" @click="$router.push('/consult/license/5')">
        <img src="https://cdn.waityou.online/d82d79f8-bc99-3136-e7f7-c43d7ba72949.png"/>
        <span>客运</span>
        <span>候车室</span>
      </div>
    </div>
    <span class="baseFont boldFont vmg16">其他业务</span>
    <nut-row class="bothCenter" :gutter="10">
      <nut-col :span="12" class="bothCenter">
        <ConsultBusinessItem :data="items[0]" @click="$router.push(items[0].link)"/>
      </nut-col>
      <nut-col :span="12" class="bothCenter">
        <ConsultBusinessItem :data="items[1]" @click="$router.push(items[1].link)"/>
      </nut-col>
    </nut-row>
    <nut-row class="bothCenter" :gutter="10">
      <nut-col :span="12" class="bothCenter">
        <ConsultBusinessItem :data="items[2]" @click="$router.push(items[2].link)"/>
      </nut-col>
      <nut-col :span="12" class="bothCenter">
        <ConsultBusinessItem :data="items[3]" @click="$router.push(items[3].link)"/>
      </nut-col>
    </nut-row>
    <nut-row class="bothCenter" :gutter="10">
      <nut-col :span="12" class="bothCenter">
        <ConsultBusinessItem :data="items[4]" @click="$router.push(items[4].link)"/>
      </nut-col>
      <nut-col :span="12" class="bothCenter">

      </nut-col>
    </nut-row>
    <span class="baseFont boldFont vmg16">其他行政工作查看</span>
    <div class="colMenuContainer">
      <nut-row class="bothCenter" @click="$router.push('/consult/sub/1')">
        <nut-col :span="12" class="leftCenter">
          <img src="https://cdn.waityou.online/99a21e78-d06b-5c66-0c35-711569700374.png"
               style="width: 14px;height: 14px;margin-right: 5px"/>
          <span class="baseFont">行政检查</span>
        </nut-col>
        <nut-col :span="12" class="rightCenter">
          <nut-icon name="right" size="10"></nut-icon>
        </nut-col>
      </nut-row>
      <div class="divider vmg16"></div>
      <nut-row class="bothCenter" @click="$router.push('/consult/sub/2')">
        <nut-col :span="12" class="leftCenter">
          <img src="https://cdn.waityou.online/3b931943-02ce-97fa-aace-552d9960951f.png"
               style="width: 14px;height: 14px;margin-right: 5px"/>
          <span class="baseFont">行政处罚</span>
        </nut-col>
        <nut-col :span="12" class="rightCenter">
          <nut-icon name="right" size="10"></nut-icon>
        </nut-col>
      </nut-row>
      <div class="divider vmg16"></div>
      <nut-row class="bothCenter" @click="$router.push('/consult/sub/3')">
        <nut-col :span="12" class="leftCenter">
          <img src="https://cdn.waityou.online/e14e8db0-972d-f07b-b8ee-b67075441623.png"
               style="width: 14px;height: 14px;margin-right: 5px"/>
          <span class="baseFont">行政强制</span>
        </nut-col>
        <nut-col :span="12" class="rightCenter">
          <nut-icon name="right" size="10"></nut-icon>
        </nut-col>
      </nut-row>
    </div>
    <span class="baseFont boldFont vmg16">联系信息</span>
    <div class="colMenuContainer">
      <nut-row @click="navigation">
        <nut-col :span="2" class="leftCenter">
          <img src="https://cdn.waityou.online/4996594f-c09e-2a6c-e1d0-9665c7e56730.png"
               style="width: 14px;height: 14px;margin-right: 5px"/>
        </nut-col>
        <nut-col :span="22" class="colFlex">
          <span class="smallFont">杭州市行政服务中心（H楼）
            <span class="miniFont" style="background-color: rgba(47,67,140,0.1);color: #0064FF;padding: 1px 4px;border-radius: 4px">导航</span></span>
          <span class="miniFont"
                style="color:#999999;">浙江省杭州市上城区解放东路18号市民中心H楼2楼业务审批3号消防窗口</span>
        </nut-col>
      </nut-row>
      <div class="divider vmg16"></div>
      <nut-row>
        <nut-col :span="2" class="leftCenter">
          <img src="https://cdn.waityou.online/469eedda-ff26-f029-15c8-4e65dae16d2c.png"
               style="width: 14px;height: 14px;margin-right: 5px"/>
        </nut-col>
        <nut-col :span="22" class="colFlex">
          <span class="smallFont">工作日（周一至周五）</span>
          <span class="miniFont" style="color:#999999;">早上09:00-12:00
下午13:30-17:00(夏令时14:00-17:00)
(法定节假日按国家假期安排调整办理时间）</span>
        </nut-col>
      </nut-row>
      <div class="divider vmg16"></div>
      <a href="tel:057187008648">
        <nut-row>
          <nut-col :span="2" class="leftCenter">
            <img src="https://cdn.waityou.online/beae4020-6fa2-3659-695b-c5beff597c41.png"
                 style="width: 14px;height: 14px;margin-right: 5px"/>
          </nut-col>
          <nut-col :span="22" class="colFlex">
            <span class="smallFont" style="color: black">0571-87008648
              <span class="miniFont" style="background-color: rgba(47,67,140,0.1);color: #0064FF;padding: 1px 4px;border-radius: 4px">拨打</span> </span>
            <span class="miniFont" style="color:#999999;">外地号码请加区号0571</span>
          </nut-col>
        </nut-row>
      </a>
    </div>
    <div class="colMenuContainer vmg16">
      <nut-row class="bothCenter" @click="$router.push('/leave/message')">
        <nut-col :span="12" class="leftCenter">
          <img src="https://cdn.waityou.online/dc8b4ad9-4586-e8b0-77c0-9e5934467a02.png"
               style="width: 14px;height: 14px;margin-right: 5px"/>
          <span class="baseFont">留言</span>
        </nut-col>
        <nut-col :span="12" class="rightCenter">
          <nut-icon name="right" size="10"></nut-icon>
        </nut-col>
      </nut-row>
    </div>
  </div>

</template>

<script>
import ConsultBusinessItem from "@/components/consult/ConsultBusinessItem";
import {Toast} from "@nutui/nutui";

export default {
  name: "ConsultView",
  components: {ConsultBusinessItem},
  data() {
    return {
      items: [
        {
          icon: 'https://cdn.waityou.online/c121f41d-1981-99b8-c70a-10de3949efcf.png',
          name: '火灾事故认定复核',
          link: '/consult/license/6'
        },
        {
          icon: 'https://cdn.waityou.online/72e39d70-27a7-0a18-f0e8-5ede843d930d.png',
          name: '火灾事故认定认定',
          link: '/consult/license/7'
        },
        {
          icon: 'https://cdn.waityou.online/7abe847a-eff0-b328-ab53-df0b6037e4a2.png',
          name: '专职消防队验收',
          link: '/consult/license/8'
        },
        {
          icon: 'https://cdn.waityou.online/bb596f67-38ec-ddef-ef34-da019bc99910.png',
          name: '恢复使用、生产经营',
          link: '/consult/license/9'
        },
        {
          icon: 'https://cdn.waityou.online/4905342b-e811-47b9-d02b-a49778fb972e.png',
          name: '临时查封解除申请',
          link: '/consult/license/10'
        },
      ]
    }
  },
  methods: {
    navigation() {
      Toast.loading('正在跳转，请确认开启定位')
      window.open(`https://uri.amap.com/navigation?to=120.209474,30.24527&mode=car&policy=&src=xfbm&coordinate=gaode&callnative=1`)
    },
    gotoZLB() {
      this.$router.push("/consult/regions")
      // Toast.loading('请稍等...')
      // window.location.href = 'https://www.zjzwfw.gov.cn/zjservice/dept/deptQueryPage.do?deptId=001008001026117&webId=2'
    }
  },
}
</script>

<style lang="scss" scoped>
.header {
  background-image: url("https://cdn.waityou.online/ce3f218f-bd35-3ad0-085e-784911b21a25.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 152px;
  display: flex;
  flex-direction: column;

  .lookup {
    width: 30%;
    color: white;
    height: 32px;
    background: #2F438C;
    box-shadow: 0px 2px 10px 0px rgba(99, 128, 180, 0.3);
    border-radius: 16px;
  }
}

.ConsultViewContainer {
  display: flex;
  flex-direction: column;
  background-color: #F4F6F9;
  padding: 16px;
  min-height: 80vh;

  .colMenuContainer {
    background-color: white;
    border-radius: 16px;
    padding: 16px;
  }

  .menuContainer {
    background-color: white;
    padding: 16px 0;
    border-radius: 16px;
    margin-top: -25px;

    .menuItem {
      width: 20%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      span {
        font-size: $miniFontSize;
      }

      img {
        width: 32px;
        height: 32px;
      }
    }
  }
}
</style>