<template>
  <div class="MyContainer">
    <nut-row className="bothCenter pd16 wbg brd16 mb8">
      <nut-col :span="8" class="leftCenter">
        <img :src="avatar"
             style="width: 80px;height: 80px;border-radius:40px;box-shadow: 0px 2px 24px 0px rgba(222,237,255,0.8);"/>
      </nut-col>
      <nut-col :span="16" class="colFlex">
        <span class="baseFont boldFont vpd4">{{ userName }}</span>
        <div class="leftCenter">
          <span class="smallFont" style="color:#999999;">{{ phone ? phone : '-' }}&nbsp;</span>
          <img src="https://cdn.waityou.online/b48d07b8-e339-7a4d-687d-4e38e0c261bb.png"
               @click="$router.push('/my/setting')"
               style="width: 20px;"/>
        </div>
      </nut-col>
    </nut-row>

    <div class="colFlex vmg8">
      <nut-row className="wbg brd16 pd16 vmg8 bothCenter" @click="$router.push('/my/date')">
        <nut-col :span="2" class="leftCenter">
          <img src="https://cdn.waityou.online/768c306c-df81-24b3-e937-e7c1de0feb5e.png" style="width: 20px"/>
        </nut-col>
        <nut-col :span="10" class="leftCenter">
          <span class="baseFont">我的预约</span>
        </nut-col>
        <nut-col :span="12" class="rightCenter">
          <img src="https://cdn.waityou.online/cf1f1d55-c597-eb30-1b08-625fb7decc5e.png" style="width: 6px"/>
        </nut-col>
      </nut-row>
      <nut-row className="wbg brd16 pd16 vmg8 bothCenter" @click="$router.push('/my/contact')">
        <nut-col :span="2" class="leftCenter">
          <img src="https://cdn.waityou.online/768c306c-df81-24b3-e937-e7c1de0feb5e.png" style="width: 20px"/>
        </nut-col>
        <nut-col :span="10" class="leftCenter">
          <span class="baseFont">常用联系人</span>
        </nut-col>
        <nut-col :span="12" class="rightCenter">
          <img src="https://cdn.waityou.online/cf1f1d55-c597-eb30-1b08-625fb7decc5e.png" style="width: 6px"/>
        </nut-col>
      </nut-row>
      <!--      <nut-row className="wbg brd16 pd16 vmg8 bothCenter" @click="$router.push('/my/collection')">-->
      <!--        <nut-col :span="2" class="leftCenter">-->
      <!--          <img src="https://cdn.waityou.online/768c306c-df81-24b3-e937-e7c1de0feb5e.png" style="width: 20px"/>-->
      <!--        </nut-col>-->
      <!--        <nut-col :span="10" class="leftCenter">-->
      <!--          <span class="baseFont">我的收藏</span>-->
      <!--        </nut-col>-->
      <!--        <nut-col :span="12" class="rightCenter">-->
      <!--          <img src="https://cdn.waityou.online/cf1f1d55-c597-eb30-1b08-625fb7decc5e.png" style="width: 6px"/>-->
      <!--        </nut-col>-->
      <!--      </nut-row>-->
      <nut-row className="wbg brd16 pd16 vmg8 bothCenter" @click="$router.push('/my/certs')">
        <nut-col :span="2" class="leftCenter">
          <img src="https://cdn.waityou.online/768c306c-df81-24b3-e937-e7c1de0feb5e.png" style="width: 20px"/>
        </nut-col>
        <nut-col :span="10" class="leftCenter">
          <span class="baseFont">我的证书</span>
        </nut-col>
        <nut-col :span="12" class="rightCenter">
          <img src="https://cdn.waityou.online/cf1f1d55-c597-eb30-1b08-625fb7decc5e.png" style="width: 6px"/>
        </nut-col>
      </nut-row>
      <!--      <nut-row className="wbg brd16 pd16 vmg8 bothCenter">-->
      <!--        <nut-col :span="2" class="leftCenter">-->
      <!--          <img src="https://cdn.waityou.online/768c306c-df81-24b3-e937-e7c1de0feb5e.png" style="width: 20px"/>-->
      <!--        </nut-col>-->
      <!--        <nut-col :span="10" class="leftCenter">-->
      <!--          <span class="baseFont">我的留言或反馈</span>-->
      <!--        </nut-col>-->
      <!--        <nut-col :span="12" class="rightCenter">-->
      <!--          <img src="https://cdn.waityou.online/cf1f1d55-c597-eb30-1b08-625fb7decc5e.png" style="width: 6px"/>-->
      <!--        </nut-col>-->
      <!--      </nut-row>-->
      <nut-row className="wbg brd16 pd16 vmg8 bothCenter" @click="$router.push('/my/messages')">
        <nut-col :span="2" class="leftCenter">
          <img src="https://cdn.waityou.online/768c306c-df81-24b3-e937-e7c1de0feb5e.png" style="width: 20px"/>
        </nut-col>
        <nut-col :span="18" class="leftCenter">
          <span class="baseFont">我的消息</span>
        </nut-col>
        <nut-col :span="4" class="rightCenter">
          <img src="https://cdn.waityou.online/cf1f1d55-c597-eb30-1b08-625fb7decc5e.png" style="width: 6px"/>
        </nut-col>
      </nut-row>
      <nut-row className="wbg brd16 pd16 vmg8 bothCenter" @click="changeTextSize">
        <nut-col :span="2" class="leftCenter">
          <img src="https://cdn.waityou.online/768c306c-df81-24b3-e937-e7c1de0feb5e.png" style="width: 20px"/>
        </nut-col>
        <nut-col :span="18" class="leftCenter">
          <span class="baseFont">切换为{{ oldMode ? '常规字体' : '适老化' }}版本</span>
        </nut-col>
        <nut-col :span="4" class="rightCenter">
          <img src="https://cdn.waityou.online/cf1f1d55-c597-eb30-1b08-625fb7decc5e.png" style="width: 6px"/>
        </nut-col>
      </nut-row>
    </div>
  </div>
</template>

<script>

import api from "@/api"
import {Toast} from '@nutui/nutui';

export default {
  name: "MyView",
  components: {},
  data() {
    return {
      avatar: 'https://smarket.oss-cn-beijing.aliyuncs.com/default/avatar.png',
      userName: '...',
      realName: '...',
      phone: '1234567890',
      level: '',
      nextLevel: '',
      percent: 0,
      todayIncrease: 0,
      roleId: 0,
      balance: 0,
      oldMode: false,
    }
  },
  methods: {
    changeTextSize() {
      let fm = localStorage.getItem('font-mode');
      if (fm === 'old') {
        localStorage.setItem('font-mode', 'young');
      } else {
        localStorage.setItem('font-mode', 'old');
      }
      this.resetFontSize();
    },
    resetFontSize(){
      let fm = localStorage.getItem('font-mode');
      if (fm === 'old') {
        this.oldMode = true;
        document.getElementsByTagName('body')[0].style.setProperty('--max-font-size', '22px');
        document.getElementsByTagName('body')[0].style.setProperty('--bigger-font-size', '20px');
        document.getElementsByTagName('body')[0].style.setProperty('--base-font-size', '18px');
        document.getElementsByTagName('body')[0].style.setProperty('--smaller-font-size', '16px');
        document.getElementsByTagName('body')[0].style.setProperty('--mini-font-size', '14px');
        document.getElementsByTagName('body')[0].style.setProperty('--min-font-size', '12px');
      } else {
        this.oldMode = false;
        document.getElementsByTagName('body')[0].style.setProperty('--max-font-size', '20px');
        document.getElementsByTagName('body')[0].style.setProperty('--bigger-font-size', '18px');
        document.getElementsByTagName('body')[0].style.setProperty('--base-font-size', '16px');
        document.getElementsByTagName('body')[0].style.setProperty('--smaller-font-size', '14px');
        document.getElementsByTagName('body')[0].style.setProperty('--mini-font-size', '12px');
        document.getElementsByTagName('body')[0].style.setProperty('--min-font-size', '10px');
      }
    },
    noAvailable() {
      Toast.warn('敬请期待');
    },
    logout() {
      api.logout('wechat').then(() => {
        this.$router.push({
          path: decodeURIComponent('/logout')
        });
      })
    },
    getUserInfo() {
      api.getUserInfo().then(ret => {
        this.avatar = ret.avatar;
        this.phone = ret.phone;
        this.userName = ret.nickName;
        this.realName = ret.nickName;
        this.level = ret.level;
        this.nextLevel = ret.nextLevel;
        this.percent = ret.levelPercent;
        this.todayIncrease = ret.todayIncrease;
      })
    }
  },
  mounted() {
    this.getUserInfo();
    let fm = localStorage.getItem('font-mode');
    if (fm === 'old') {
      this.oldMode = true;
    }
  }
}
</script>

<style lang="scss" scoped>
.MyContainer {
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: #F4F6F9;
  min-height: 100vh;
  background-image: url("https://cdn.waityou.online/40845ab0-4ccd-9fad-9e59-0599301c403c.png");
  background-size: 100%;
  background-repeat: no-repeat;
}
</style>