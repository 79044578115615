<template>
  <div class="CertRewardContainer">
    <div style="width:375px;height:550px;
    background-image: url('https://cdn.waityou.online/03feac83-8801-9af1-5889-a88577beee3b.png');
background-size: contain;background-repeat: no-repeat;color: #2F438C;" class="bothCenter colFlex">
      <div style="width: 213px;">
        <span class="boldFont baseFont">祝贺{{ cert.name }}</span>
        <p class="smallFont">{{ cert.content }}</p>
        <div class="rightCenter">
          <span class="baseFont">杭州市消防救援支队</span>
        </div>
        <div class="rightCenter">
          <span class="baseFont">{{cert?.createdTime?.substring(0,10)}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CertReward",
  props: {
    cert: {
      type: Object,
      default: ()=>{
        return {

        }
      }
    },
  },
  methods: {
    init() {

    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.CertRewardContainer {
  display: flex;
  flex-direction: column;
}
</style>