<template>
  <div class="StationCollectionContainer vmg16 brd16">
    <nut-row className="leftCenter wbg pd8 brd16">
      <div class="stationImage">
        <span class="minFont">123已参观</span>
        <img src="https://cdn.waityou.online/461366a2-7bc4-2415-8f8d-90bd59923433.jpeg"/>
      </div>
      <div class="colFlex prd12">
        <span class="smallFont boldFont">杭州市上城区消防救援大队</span>
        <span class="miniFont vmg8" style="color:#999999;">距你9.3km | 上城区望江街道</span>
      </div>
    </nut-row>
    <div class="pd8" v-if="state>0">
      <nut-row className="leftCenter wbg pd8 brd12">
        <div class="stationImage2">
          <span class="minFont">123已参观</span>
          <img src="https://cdn.waityou.online/461366a2-7bc4-2415-8f8d-90bd59923433.jpeg"/>
        </div>
        <div class="colFlex prd12">
          <span class="smallFont boldFont">杭州市上城区消防救援大队</span>
          <span class="miniFont vmg4" style="color:#999999;">距你9.3km | 上城区望江街道</span>
        </div>
      </nut-row>
    </div>
    <nut-row class="bothCenter vmg8" v-if="more && state<1">
      <img src="https://cdn.waityou.online/6bdf076b-1333-90ad-0f0a-c3840c98fffc.png"
           style="width: 10px;padding: 0 4px"/>
      <span class="miniFont" @click="state=1">展开(1)</span>
    </nut-row>
    <nut-row class="bothCenter vmg8" v-if="state>0">
      <img src="https://cdn.waityou.online/e48d3b3b-a54d-6c3d-e169-af4c559b1b11.png"
           style="width: 10px;padding: 0 4px"/>
      <span class="miniFont" @click="state=0">收起(1)</span>
    </nut-row>
  </div>
</template>

<script>
export default {
  name: "StationCollection",
  props: {
    more: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      state: 0
    }
  },
}
</script>

<style lang="scss" scoped>
.StationCollectionContainer {
  display: flex;
  flex-direction: column;
  background-color: #F2F2F2;

  .stationImage{
    height: 70px;
    width: 120px;
    position: relative;
    span{
      background: #000000;
      border-radius: 8px 0px 8px 0px;
      opacity: 0.5;
      position: absolute;
      top: 0;
      left: 0;
      color:white;
      padding: 1px 2px;
    }
    img{
      height: 70px;
      width: 120px;
      object-fit: cover;
      border-radius: 8px;
    }
  }
  .stationImage2{
    height: 48px;
    width: 80px;
    position: relative;
    span{
      background: #000000;
      border-radius: 8px 0px 8px 0px;
      opacity: 0.5;
      position: absolute;
      top: 0;
      left: 0;
      color:white;
      padding: 1px 2px;
    }
    img{
      height: 48px;
      width: 80px;
      object-fit: cover;
      border-radius: 8px;
    }
  }
}
</style>