<template>
  <div class="DateHistoryDetailViewContainer">
    <span style="font-size: 24px" class="whiteFont boldFont prd8 vmg4">{{ visitState }}</span>
    <span class="whiteFont smallFont pd8">参观时间：{{ visit.bookingDate }} {{
        visit?.bookingDateTime
      }}-{{ visit?.finishDateTime }}</span>
    <div class="wbg pd16 colFlex brd16 vmg16">
      <span class="baseFont boldFont">预约信息</span>
      <nut-row class="bothCenter vmg16">
        <nut-col :span="6" class="leftCenter">
          <span class="smallFont" style="color:#999999;">参观站点</span>
        </nut-col>
        <nut-col :span="18" class="leftCenter">
          <span class="smallFont boldFont">{{ visit.stationName }}</span>
        </nut-col>
      </nut-row>
      <nut-row class="bothCenter">
        <nut-col :span="6" class="leftCenter">
          <span class="smallFont" style="color:#999999;">参观地址</span>
        </nut-col>
        <nut-col :span="18" class="leftCenter">
          <span class="smallFont boldFont">{{ visit?.station?.address }}</span>
        </nut-col>
      </nut-row>
    </div>
    <div class="wbg pd16 colFlex brd16 mb16">
      <span class="baseFont boldFont">{{ visit.type == 'GROUPING' ? '拼团信息' : '团队信息' }}</span>
      <nut-row class="bothCenter vmg16">
        <nut-col :span="6" class="leftCenter">
          <span class="smallFont" style="color:#999999;">团队名称</span>
        </nut-col>
        <nut-col :span="18" class="leftCenter">
          <span class="smallFont boldFont">{{ visit.teamName }}</span>
        </nut-col>
      </nut-row>
      <nut-row class="bothCenter mb16">
        <nut-col :span="6" class="leftCenter">
          <span class="smallFont" style="color:#999999;">团长姓名</span>
        </nut-col>
        <nut-col :span="18" class="leftCenter">
          <span class="smallFont boldFont">{{ visit.userName }}</span>
        </nut-col>
      </nut-row>
      <nut-row class="bothCenter">
        <nut-col :span="6" class="leftCenter">
          <span class="smallFont" style="color:#999999;">团长号码</span>
        </nut-col>
        <nut-col :span="18" class="leftCenter">
          <span class="smallFont boldFont">{{ leader.phone }}</span>
        </nut-col>
      </nut-row>
    </div>
    <div class="wbg pd16 colFlex brd16 mb16">
      <nut-row class="bothCenter">
        <nut-col :span="6" class="leftCenter">
          <span class="smallFont" style="color:#999999;">团员信息</span>
        </nut-col>
        <nut-col :span="18" class="leftCenter">
          <span class="smallFont boldFont">已加入{{
              visit.type == 'GROUPING'?visit.groupPeopleCount:visit.personNum
            }}人{{ visit.type == 'GROUPING'&&visit.groupSuccessMissCount > 0 ? `，缺${visit.groupSuccessMissCount}人` : '' }}</span>
        </nut-col>
<!--        <nut-col :span="8" class="leftCenter">-->
<!--          <img class="joiner" src="https://cdn.waityou.online/69c2bb8a-bcf9-7ac7-b74c-bddb295a4f2c.jpeg"/>-->
<!--          <img class="joiner coverLeft" src="https://cdn.waityou.online/69c2bb8a-bcf9-7ac7-b74c-bddb295a4f2c.jpeg"/>-->
<!--          <img class="joiner coverLeft" src="https://cdn.waityou.online/69c2bb8a-bcf9-7ac7-b74c-bddb295a4f2c.jpeg"/>-->
<!--          <img class="joiner coverLeft" src="https://cdn.waityou.online/69c2bb8a-bcf9-7ac7-b74c-bddb295a4f2c.jpeg"/>-->
<!--          <img class="joiner coverLeft" src="https://cdn.waityou.online/69c2bb8a-bcf9-7ac7-b74c-bddb295a4f2c.jpeg"/>-->
<!--          <img class="joiner coverLeft" src="https://cdn.waityou.online/69c2bb8a-bcf9-7ac7-b74c-bddb295a4f2c.jpeg"/>-->
<!--        </nut-col>-->
      </nut-row>
    </div>
    <div class="wbg pd16 colFlex brd16">
      <nut-row class="bothCenter">
        <nut-col :span="6" class="leftCenter">
          <span class="smallFont" style="color:#999999;">申请时间</span>
        </nut-col>
        <nut-col :span="18" class="leftCenter">
          <span class="smallFont boldFont">{{ visit.createdTime }}</span>
        </nut-col>
      </nut-row>
      <nut-row class="bothCenter mt16" v-if="visit.auditTime">
        <nut-col :span="6" class="leftCenter">
          <span class="smallFont" style="color:#999999;">审核时间</span>
        </nut-col>
        <nut-col :span="18" class="leftCenter">
          <span class="smallFont boldFont">{{ visit.auditTime }}</span>
        </nut-col>
      </nut-row>
      <nut-row class="bothCenter mt16" v-if="visit.startTime">
        <nut-col :span="6" class="leftCenter">
          <span class="smallFont" style="color:#999999;">开始参观</span>
        </nut-col>
        <nut-col :span="18" class="leftCenter">
          <span class="smallFont boldFont">{{ visit.startTime }}</span>
        </nut-col>
      </nut-row>
      <nut-row class="bothCenter mt16" v-if="visit.endTime">
        <nut-col :span="6" class="leftCenter">
          <span class="smallFont" style="color:#999999;">完成参观</span>
        </nut-col>
        <nut-col :span="18" class="leftCenter">
          <span class="smallFont boldFont">{{ visit.endTime }}</span>
        </nut-col>
      </nut-row>
      <nut-row class="bothCenter mt16 lightRed" v-if="visit.state == 'CANCEL'">
        <nut-col :span="6" class="leftCenter">
          <span class="smallFont">驳回时间</span>
        </nut-col>
        <nut-col :span="18" class="leftCenter">
          <span class="smallFont boldFont">{{ visit.auditTime }}</span>
        </nut-col>
      </nut-row>
      <div class="reject smallFont lightRed pd8 brd8 mt16" v-if="visit.state == 'CANCEL'">
        <span>理由：{{ visit.reason }}</span>
      </div>
    </div>
    <div class="pd16 colFlex mt8 bothCenter" v-if="visit.needEvaluate">
      <nut-button class="commentBtn" @click="$router.push('/visit/evaluate/'+visit.id)">我要评价</nut-button>
    </div>
  </div>
</template>

<script>
import api from "@/api";

export default {
  name: "DateHistoryDetailView",
  data() {
    return {
      dateId: this.$route.params.dateId,
      visit: {},
      visitState: '-',
      leader: {},
      stateMap: {
        INIT: "拼团中",
        COMMIT: "待审核",
        CANCEL: "已取消",
        PASS: "待参观",
        PROCESSING: "参观中",
        FINISH: "已完成",
      }
    }
  },
  methods: {
    init() {
      api.getMyVisitDateList({
        dateId: this.dateId,
      }).then(ret => {
        this.visit = ret[0];
        this.visitState = this.calcVisitState();
        if (this.visit.type == 'GROUPING') {
          for (let p of this.visit.partInList) {
            if (p.userId == this.visit.userId) {
              this.leader = p.members[0];
            }
          }
        } else {
          this.leader = this.visit.members[0];
        }
      })
    },
    calcVisitState() {
      // if (this.visit?.type == 'GROUPING') {
      //   if (this.visit?.state == 'COMMIT') {
      //     return '拼团中'
      //   }
      // }
      return this.stateMap[this?.visit?.state]
    },
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.DateHistoryDetailViewContainer {
  display: flex;
  flex-direction: column;
  background-color: #F4F6F9;
  padding: 16px;
  background-image: url("https://cdn.waityou.online/37cb1d79-9515-335c-c596-fceff43c4a7c.png");
  background-size: 100%;
  background-position: top;
  background-repeat: no-repeat;
  min-height: calc(100vh - 32px);

  .commentBtn{
    width: 50%;
    border: none;
    color:white;
    height: 44px;
    background: #2F438C;
    border-radius: 12px;
  }
  .reject {
    background-color: rgba(255, 30, 50, 0.1);;
  }

  .lightRed {
    color: #FF1E32;
  }

  .joiner {
    width: 28px;
    height: 28px;
    border-radius: 14px;
  }

  .coverLeft {
    margin-left: -14px;
  }
}
</style>