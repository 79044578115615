<template>
  <div class="LeaveMessageViewContainer">
    <div class="wbg pd16" style="border-radius: 16px">
      <textarea rows="10" class="wd100 smallFont" style="border: none"
                placeholder="请输入您要反馈的问题…" v-model="content"/>
    </div>
    <div class="bothCenter vmg16">
      <nut-button class="submitBtn" @click="save">保存</nut-button>
    </div>
  </div>
</template>

<script>
import {Toast} from "@nutui/nutui";
import api from "@/api";

export default {
  name: "LeaveMessageView",
  data() {
    return {
      content: null
    }
  },
  methods: {
    save() {
      if(!this.content||this.content.length < 1){
        Toast.fail('请输入您要反馈的问题')
        return
      }
      api.leaveMessage({
        content:this.content,
      }).then(()=>{
        Toast.success('反馈成功')
        this.$router.back();
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.LeaveMessageViewContainer {
  display: flex;
  flex-direction: column;
  background-color: #F4F6F9;
  padding: 16px;
  min-height: calc(100vh - 32px);

  .submitBtn {
    border: none;
    color: white;
    width: 160px;
    height: 44px;
    background: #2F438C;
    border-radius: 12px;
  }
  textarea:focus{
    outline: none;
  }
}
</style>