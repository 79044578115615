<template>
  <router-view v-slot="{ Component }">
    <keep-alive :include="cachedPages">
      <component :is="Component" :key="$route.name"></component>
    </keep-alive>
  </router-view>
</template>


<script>
export default {
  name: "App",
  mounted() {
    let fm = localStorage.getItem('font-mode');
    if (fm === 'old') {
      document.getElementsByTagName('body')[0].style.setProperty('--max-font-size', '22px');
      document.getElementsByTagName('body')[0].style.setProperty('--bigger-font-size', '20px');
      document.getElementsByTagName('body')[0].style.setProperty('--base-font-size', '18px');
      document.getElementsByTagName('body')[0].style.setProperty('--smaller-font-size', '16px');
      document.getElementsByTagName('body')[0].style.setProperty('--mini-font-size', '14px');
      document.getElementsByTagName('body')[0].style.setProperty('--min-font-size', '12px');
    } else {
      document.getElementsByTagName('body')[0].style.setProperty('--max-font-size', '20px');
      document.getElementsByTagName('body')[0].style.setProperty('--bigger-font-size', '18px');
      document.getElementsByTagName('body')[0].style.setProperty('--base-font-size', '16px');
      document.getElementsByTagName('body')[0].style.setProperty('--smaller-font-size', '14px');
      document.getElementsByTagName('body')[0].style.setProperty('--mini-font-size', '12px');
      document.getElementsByTagName('body')[0].style.setProperty('--min-font-size', '10px');
    }
  },
  computed: {
    cachedPages() {
      return this.$store.state.cachePages;
    }
  }
}
</script>

<style lang="scss">
@for $i from 2 through 100 {
  /* #{$i}px */
  .sqr#{$i} {
    width: #{$i}px;
    height: #{$i}px;
  }
  .wdp#{$i} {
    width: #{$i}px;
  }
  .thp#{$i} {
    height: #{$i}px;
  }
  .ft#{$i} {
    font-size: #{$i}px;
  }
  .brd#{$i} {
    border-radius: #{$i}px;
  }
  .vpd#{$i} {
    padding-top: #{$i}px;
    padding-bottom: #{$i}px;
  }
  .vmg#{$i} {
    margin-top: #{$i}px;
    margin-bottom: #{$i}px;
  }
  .omg#{$i} {
    margin-left: #{$i}px;
    margin-right: #{$i}px;
  }
  .opd#{$i} {
    padding-left: #{$i}px;
    padding-right: #{$i}px;
  }
  .pd#{$i} {
    padding: #{$i}px;
  }
  .ml#{$i} {
    margin-left: #{$i}px;
  }
  .mr#{$i} {
    margin-right: #{$i}px;
  }
  .mt#{$i} {
    margin-top: #{$i}px;
  }
  .mb#{$i} {
    margin-bottom: #{$i}px;
  }
  .pl#{$i} {
    padding-left: #{$i}px;
  }
  .pr#{$i} {
    padding-right: #{$i}px;
  }
  .pt#{$i} {
    padding-top: #{$i}px;
  }
}
body {
  padding: 0;
  margin: 0;
  background-color: white;
}
</style>