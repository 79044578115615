import axios from "../utils/http";
import path from "./path"

const stringFormat = (formatted, args) => {
    for (let i = 0; i < args.length; i++) {
        let regexp = new RegExp('\\{' + i + '\\}', 'gi')
        formatted = formatted.replace(regexp, args[i])
    }
    return formatted
}

const api = {
    get(url, query) {
        if (query) {
            return axios.get(path.baseUrl + url, {params: query})
        }
        return axios.get(path.baseUrl + url)
    },
    getUserInfo() {
        return axios.get(path.baseUrl + path.userInfo)
    },
    login(data) {
        return axios.post(path.baseUrl + path.login, data)
    },
    logout(env) {
        return axios.get(path.baseUrl + stringFormat(path.logout, [env]))
    },
    getOssKeySecret() {
        return axios.get(path.baseUrl + path.getOssKeySecret);
    },
    getWechatRedirectUrl(url) {
        return axios.get(path.baseUrl + stringFormat(path.getWechatRedirectUrl, [url]))
    },
    wechatCodeLogin(code) {
        return axios.get(path.baseUrl + stringFormat(path.wechatCodeLogin, [code]))
    },
    getAuthInfo() {
        return axios.get(path.baseUrl + path.getAuthInfo)
    },
    submitAuthInfo(data) {
        return axios.post(path.baseUrl + path.submitAuthInfo, data)
    },
    createPost(data) {
        return axios.post(path.baseUrl + path.createPost, data)
    },
    getBanners() {
        return axios.get(path.baseUrl + path.getBanners)
    },
    getPlatformMessage(query) {
        return axios.get(path.baseUrl + path.getPlatformMessage, {params: query})
    },
    getPostList(query) {
        return axios.get(path.baseUrl + path.getPostList, {params: query})
    },
    getGetComments(query) {
        return axios.get(path.baseUrl + path.getGetComments, {params: query})
    },
    markRead(mid) {
        return axios.get(path.baseUrl + stringFormat(path.markRead, [mid]))
    },
    getPostDetail(pid) {
        return axios.get(path.baseUrl + stringFormat(path.getPostDetail, [pid]))
    },
    getCommentCreatorInfo(commentId) {
        return axios.get(path.baseUrl + stringFormat(path.getCommentCreatorInfo, [commentId]))
    },
    readAllMessage() {
        return axios.get(path.baseUrl + path.readAllMessage)
    },
    postComment(data){
        return axios.post(path.baseUrl + path.postComment, data)
    },
    getHotList(){
        return axios.get(path.baseUrl + path.getHotList)
    },
    thumbPost(posterId) {
        return axios.get(path.baseUrl + stringFormat(path.thumbPost, [posterId]))
    },
    thumbComment(commentId) {
        return axios.get(path.baseUrl + stringFormat(path.thumbComment, [commentId]))
    },
    searchPostList(data) {
        return axios.post(path.baseUrl + path.searchPostList, data)
    },
    updateInfo(data) {
        return axios.post(path.baseUrl + path.updateInfo, data)
    },
    feedback(data) {
        return axios.post(path.baseUrl + path.feedback, data)
    },
    updatePass(data) {
        return axios.post(path.baseUrl + path.updatePass, data)
    },
    captcha4changePhone(phone) {
        return axios.get(path.baseUrl + stringFormat(path.captcha4changePhone, [phone]))
    },
    changePhone(data) {
        return axios.post(path.baseUrl + path.changePhone, data)
    },
    captcha4resetpass() {
        return axios.get(path.baseUrl + path.captcha4resetpass)
    },
    resetPass(data) {
        return axios.post(path.baseUrl + path.resetPass, data)
    },getRegionList(regionId) {
        return axios.get(path.baseUrl + stringFormat(path.getRegionList, [regionId]))
    },
    getStationByRegionId(query) {
        return axios.get(path.baseUrl + path.getStationByRegionId,{params: query})
    },
    getStationDetail(stationId) {
        return axios.get(path.baseUrl + stringFormat(path.getStationDetail, [stationId]))
    },
    getVisitPlan(query) {
        return axios.get(path.baseUrl + path.getVisitPlan,{params: query})
    },
    saveContact(info) {
        return axios.post(path.baseUrl + path.saveContact, info)
    },
    getMyContactors(query) {
        return axios.get(path.baseUrl + path.getMyContactors,{params: query})
    },
    deleteContact(id) {
        return axios.get(path.baseUrl + stringFormat(path.deleteContact, [id]))
    },
    createPinGroup(pin) {
        return axios.post(path.baseUrl + path.createPinGroup, pin)
    },
    getPinGroups(stationId) {
        return axios.get(path.baseUrl + stringFormat(path.getPinGroups, [stationId]))
    },
    getPinById(teamId) {
        return axios.get(path.baseUrl + stringFormat(path.getPinById, [teamId]))
    },
    joinPinGroup(teamId,members) {
        return axios.post(path.baseUrl + stringFormat(path.joinPinGroup, [teamId]),members)
    },
    teamBooking(group) {
        return axios.post(path.baseUrl + path.teamBooking, group)
    },
    getMyVisitDateList(query) {
        return axios.get(path.baseUrl + path.getMyVisitDateList,{params: query})
    },
    getMyTrainDateList(query) {
        return axios.get(path.baseUrl + path.getMyTrainDateList,{params: query})
    },
    getMyDrillDateList(query) {
        return axios.get(path.baseUrl + path.getMyDrillDateList,{params: query})
    },
    getTrainTopics() {
        return axios.get(path.baseUrl + path.getTrainTopics)
    },
    dateTrain(dateInfo) {
        return axios.post(path.baseUrl + path.dateTrain, dateInfo)
    },
    dateDrill(dateInfo) {
        return axios.post(path.baseUrl + path.dateDrill, dateInfo)
    },
    getDrillTopics() {
        return axios.get(path.baseUrl + path.getDrillTopics)
    },
    getInfiniteLoadingList(i, method, query) {
        if ('GET' == method) {
            return axios.get(path.baseUrl + path.infiniteLoadings[i], {params: query})
        }
        return axios.post(path.baseUrl + path.infiniteLoadings[i], query)
    },
    getStudyDataFilter() {
        return axios.get(path.baseUrl + path.getStudyDataFilter)
    },
    markStudyView(id) {
        return axios.get(path.baseUrl + stringFormat(path.markStudyView, [id]))
    },
    getActivedRepo() {
        return axios.get(path.baseUrl + path.getActivedRepo)
    },
    getRepoQuestionList(repoId) {
        return axios.get(path.baseUrl + stringFormat(path.getRepoQuestionList, [repoId]))
    },
    submitExamAnswer(data) {
        return axios.post(path.baseUrl + path.submitExamAnswer, data)
    },
    getExamResult(recordId) {
        return axios.get(path.baseUrl + stringFormat(path.getExamResult, [recordId]))
    },
    getMyCerts() {
        return axios.get(path.baseUrl + path.getMyCerts)
    },
    submitVisitEvaluation(data) {
        return axios.post(path.baseUrl + path.submitVisitEvaluation, data)
    },
    collect(data) {
        return axios.post(path.baseUrl + path.collect, data)
    },
    uncollect(query) {
        return axios.get(path.baseUrl + path.uncollect, {params: query})
    },
    isNeedAskPaper() {
        return axios.get(path.baseUrl + path.isNeedAskPaper)
    },
    getRandQuestionnaire() {
        return axios.get(path.baseUrl + path.getRandQuestionnaire)
    },
    completeQuestionnaire() {
        return axios.get(path.baseUrl + path.completeQuestionnaire)
    },
    leaveMessage(data) {
        return axios.post(path.baseUrl + path.leaveMessage, data)
    },
    submitTrainEvaluation(data) {
        return axios.post(path.baseUrl + path.submitTrainEvaluation, data)
    },
    submitDrillEvaluation(data) {
        return axios.post(path.baseUrl + path.submitDrillEvaluation, data)
    },
    getMapKey() {
        return axios.get(path.baseUrl + path.getMapKey)
    },
    getSubDepartments() {
        return axios.get(path.baseUrl + path.getSubDepartments)
    },
    getStationByQuery(query) {
        return axios.get(path.baseUrl + path.getStationByQuery, {params: query})
    },
    getReceptionCnt() {
        return axios.get(path.baseUrl + path.getReceptionCnt)
    },
    getOcrReadIdCard(query) {
        return axios.get(path.baseUrl + path.getOcrReadIdCard, {params: query})
    },
    getTeacherListVisible() {
        return axios.get(path.baseUrl + path.getTeacherListVisible)
    },
    getTeacherRankByStationId(stationId) {
        return axios.get(path.baseUrl + stringFormat(path.getTeacherRankByStationId, [stationId]))
    },
    uploadVisitPhotos(data) {
        return axios.post(path.baseUrl + path.uploadVisitPhotos, data)
    },
    getVisitPlanById(dateId) {
        return axios.get(path.baseUrl + stringFormat(path.getVisitPlanById, [dateId]))
    }
}

export default api;