<template>
  <div class="MessageViewContainer">
    <InfiniteLoadingList :query-id="3">
      <template v-slot="data">
        <MessageItem :data="data.data"/>
      </template>
    </InfiniteLoadingList>
  </div>
</template>

<script>
import MessageItem from "@/components/MessageItem"
import InfiniteLoadingList from "@/components/InfiniteLoadingList"
export default {
  name: "MessageView",
  components: {MessageItem,InfiniteLoadingList}

}
</script>

<style lang="scss" scoped>
.MessageViewContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 16px;
}
</style>