const base = {
    baseUrl: "",
    userInfo: "/api/user/info",
    login: "/login/accountLogin",
    logout: "/api/user/logout?channel=1&env={0}",
    getOssKeySecret: "/api/user/upload/getOssKeySecret",
    getWechatRedirectUrl: "/login/weChatLogin?url={0}",
    wechatCodeLogin: "/login/getUserInfoByCode?code={0}",
    getAuthInfo: "/api/user/auth/getAuthInfo",
    submitAuthInfo: "/api/user/auth/submitAuthInfo",
    getBanners: "/api/user/banner/list",
    createPost: '/api/user/post/createPost',
    getPlatformMessage: '/api/user/message/list',
    getPostList: '/api/user/index/postList',
    markRead: '/api/user/message/read?id={0}',
    readAllMessage: '/api/user/message/readAll',
    getPostDetail: '/api/user/post/detail?pid={0}',
    getCommentCreatorInfo: '/api/user/comment/creatorInfo?commentId={0}',
    getGetComments: '/api/user/comment/list',
    postComment: '/api/user/comment/post',
    getHotList: '/api/user/hot/hotList',
    thumbPost: '/api/user/thumb/thumb2post?postId={0}',
    thumbComment: '/api/user/thumb/thumb2comment?commentId={0}',
    searchPostList: '/api/user/post/search',
    updateInfo: '/api/user/update',
    feedback: '/api/user/feedback',
    updatePass: '/api/user/updatePass',
    captcha4changePhone: '/api/user/captcha4changePhone?phone={0}',
    changePhone: '/api/user/changePhone',
    captcha4resetpass: '/api/user/captcha4resetpass',
    resetPass: '/api/user/resetPass',
    getRegionList: '/api/user/setting/region/cascade?regionId={0}',
    getStationDetail: '/api/user/station/getDetailById?id={0}',
    getVisitPlan: '/api/user/station/visit/getPlanInfo',
    deleteContact: '/api/user/my/deletecontact?id={0}',
    getMyContactor: '/api/user/my/deletecontact?id={0}',
    getStationByRegionId: '/api/user/station/getByRegionId',
    saveContact: '/api/user/my/savecontact',
    getMyContactors: '/api/user/my/getcontact',
    createPinGroup: '/api/user/station/visit/groupbooking',
    getPinGroups: '/api/user/station/visit/getGroupInfo?id={0}',
    getPinById: '/api/user/station/visit/getGroupInfoById?id={0}',
    joinPinGroup: '/api/user/station/visit/groupadd?id={0}',
    teamBooking: '/api/user/station/visit/teambooking',
    getMyVisitDateList: '/api/user/my/getvisit',
    getMyTrainDateList: '/api/user/my/gettrain',
    getMyDrillDateList: '/api/user/my/getdrill',
    getTrainTopics: '/api/user/station/train/topics',
    getDrillTopics: '/api/user/station/drill/topics',
    dateTrain: '/api/user/station/train/booking',
    dateDrill: '/api/user/station/drill/booking',
    getStudyDataFilter: '/api/user/study/data/getTopicList',
    markStudyView: '/api/user/study/data/view?id={0}',
    getActivedRepo: '/api/user/study/question/getEnabledRepo',
    getRepoQuestionList: '/api/user/study/question/getRepoQuestionList?repoId={0}',
    submitExamAnswer: '/api/user/study/question/submitExamAnswer',
    getExamResult: '/api/user/study/question/getExamRecord?recordId={0}',
    getMyCerts: '/api/user/my/getcert',
    submitVisitEvaluation: '/api/user/station/visit/addevaluation',
    collect: '/api/user/my/savecollect',
    uncollect: '/api/user/my/deletecollect',
    isNeedAskPaper: '/api/user/questionnaire/need',
    getRandQuestionnaire: '/api/user/questionnaire/getRandomProblems',
    completeQuestionnaire: '/api/user/questionnaire/completeQuestionnaire',
    leaveMessage: '/api/user/my/saveleavemessage',
    submitTrainEvaluation: '/api/user/station/train/addevaluation',
    submitDrillEvaluation: '/api/user/station/drill/addevaluation',
    getMapKey: '/api/user/setting/map/getMapKey',
    getSubDepartments: '/api/user/department/getSubDepartments',
    getStationByQuery: '/api/user/station/getStationByQuery',
    getReceptionCnt: '/api/user/station/getReceiveCount',
    getOcrReadIdCard: '/api/user/ocr/readIdNo',
    getTeacherListVisible: '/api/user/station/train/getTeacherListVisible',
    getTeacherRankByStationId: '/api/user/station/train/getTeacherRankByStationId?stationId={0}',
    uploadVisitPhotos: '/api/user/station/visit/uploadVisitPhotos',
    getVisitPlanById: '/api/user/station/visit/getTemplateInfo?id={0}',

    infiniteLoadings: {
        1: '/api/user/study/data/getList',
        2: '/api/user/station/getEvaluatesById',
        3: '/api/user/message/list'
    }
}

export default base;