<template>
  <div class="CreatePinGroupViewContainer">
    <div class="blueBgNotice pd16" style="padding-bottom: 0">
      <span class="smallFont whiteFont">预约时间（至少提前1天预约）</span>
      <span class="baseFont whiteFont vpd4">{{ date.timeStart }} - {{ date?.timeEnd?.substring(11) }}</span>
      <span class="miniFont whiteFont">半年内有3次完成拼团但未参加，将半年内无法再创建拼团</span>
      <div class="vmg16 wbg pd16 br16 colFlex" style="padding-bottom: 0">
        <span class="baseFont boldFont mb16">团队信息</span>
        <nut-row class="bothCenter mb16">
          <nut-col :span="5" class="leftCenter">
            <span class="smallFont">团队名称</span>
          </nut-col>
          <nut-col :span="19" class="leftCenter">
            <input class="smallFont" style="width: 80%" placeholder="请输入团队名称" v-model="pin.teamName"/>
          </nut-col>
        </nut-row>
      </div>
      <div class="wbg pd16 br16 colFlex">
        <nut-row class="bothCenter mb16">
          <nut-col :span="16" class="leftCenter">
            <span class="baseFont boldFont">个人信息</span>
          </nut-col>
          <nut-col :span="8" class="rightCenter">
            <span class="oftenContact boldFont smallFont" @click="myContactVisible=true">常用联系人</span>
          </nut-col>
        </nut-row>
        <nut-row class="bothCenter mb16">
          <nut-col :span="6" class="leftCenter">
            <span class="smallFont">联系人<span class="redDot miniFont">*</span></span>
          </nut-col>
          <nut-col :span="18" class="leftCenter">
            <input class="smallFont" style="width: 80%" placeholder="请输入姓名" v-model="user.userName"/>
          </nut-col>
        </nut-row>
        <div class="divider mb16"></div>
        <nut-row class="bothCenter mb16">
          <nut-col :span="6" class="leftCenter">
            <span class="smallFont">证件类型</span>
          </nut-col>
          <nut-col :span="18" class="leftCenter">
            <span class="smallFont ">中国居民身份证</span>
          </nut-col>
        </nut-row>
        <div class="divider mb16"></div>
        <nut-row class="bothCenter mb16">
          <nut-col :span="6" class="leftCenter">
            <span class="smallFont">上传证件</span>
          </nut-col>
          <nut-col :span="18" class="leftCenter">
            <CustomOssUploader @onUploadedFiles="onUploadedFiles" :key="imageVersion">
              <img class="uploader" :src="user.idCard?user.idCard:defaultImg"/>
            </CustomOssUploader>
          </nut-col>
        </nut-row>
        <div class="divider mb16"></div>
        <nut-row class="bothCenter mb16">
          <nut-col :span="6" class="leftCenter">
            <span class="smallFont">证件号码<span class="redDot miniFont">*</span></span>
          </nut-col>
          <nut-col :span="18" class="leftCenter">
            <input class="smallFont" style="width: 80%" placeholder="请输入证件号码" v-model="user.idCardNo"/>
          </nut-col>
        </nut-row>
        <div class="divider mb16"></div>
        <nut-row class="bothCenter mb16">
          <nut-col :span="6" class="leftCenter">
            <span class="smallFont">联系号码<span class="redDot miniFont">*</span></span>
          </nut-col>
          <nut-col :span="18" class="leftCenter">
            <input class="smallFont" style="width: 80%" placeholder="请输入联系号码" v-model="user.phone"/>
          </nut-col>
        </nut-row>
        <div class="divider mb16"></div>
        <nut-row class="bothCenter mb16">
          <nut-col :span="6" class="leftCenter">
            <span class="smallFont">报名人数<span class="redDot miniFont">*</span></span>
          </nut-col>
          <nut-col :span="18" class="leftCenter">
            <input class="smallFont" style="width: 80%" placeholder="请输入报名人数" v-model="user.peopleCount"/>
          </nut-col>
        </nut-row>
      </div>
      <nut-row class="bothCenter miniFont mt16" style="color: #999999">
        <span>适用于20人以下个人和小团队预约</span>
      </nut-row>
      <nut-row class="bothCenter vpd16">
        <nut-button class="submitBtn" @click="save">创建拼团</nut-button>
      </nut-row>
    </div>
  </div>
  <nut-overlay :close-on-click-overlay="false" v-model:visible="myContactVisible">
    <MyContactList @close="myContactVisible=false"
                   v-if="myContactVisible"
                   @use="useContact"/>
  </nut-overlay>
</template>

<script>
import MyContactList from "@/components/contact/MyContactList";
import {Dialog, Toast} from "@nutui/nutui";
import api from "@/api";
import CustomOssUploader from "@/components/CustomOssUploader";

export default {
  name: "CreatePinGroupView",
  components: {MyContactList, CustomOssUploader},
  data() {
    return {
      imageVersion: 0,
      myContactVisible: false,
      stationId: this.$route.params.stationId,
      dateId: this.$route.params.dateId,
      pin: {
        stationId: this.$route.params.stationId,
        teamName: null,
        tplId: this.$route.params.dateId,
      },
      user: {
        leader: true,
        peopleCount: null,
      },
      date:{},
      defaultImg: 'https://cdn.waityou.online/7e5216bd-b764-2b2e-4a97-19613b2ed6e5.png'
    }
  },
  methods: {
    onUploadedFiles(files) {
      this.user.idCard = files[0];
      this.imageVersion++;

      api.getOcrReadIdCard({
        img: files[0]
      }).then(ret => {
        console.log('ocr', ret)
        if (ret) {
          this.user.idCardNo = ret;
          Toast.success('已为您自动识别证件号')
        }
      })
    },
    save() {
      if (!this.user.userName || !this.user.idCardNo || !this.user.phone
          || !this.user.phone || !this.user.peopleCount || !this.pin.teamName) {
        Toast.fail("请填写完整")
        return
      }
      if (this.user.peopleCount >= 20) {
        Dialog({
          title: '不适用拼团',
          content: '20人及以上请选择团队预约',
          noCancelBtn:true,
          okText:'好的'
        })
        return;
      }
      let userCpy = Object.assign({}, this.user);
      userCpy.leader = true;
      userCpy.idCardImg = this.user.idCard;
      userCpy.idCard = this.user.idCardNo;
      this.pin.members = [userCpy]
      this.pin.personNum = this.user.peopleCount;
      Toast.loading('请稍等...')
      api.createPinGroup(this.pin)
          .then(() => {
            Toast.hide()
            Toast.success('创建拼团成功')
            this.$router.replace('/my/date')
          })
    },
    useContact(c) {
      console.log('use', c)
      this.user = c;
      this.user.userName = c.name;
    },
    init(){
      api.getVisitPlanById(this.dateId)
          .then(ret=>{
            this.date = ret;
          })
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>

.CreatePinGroupViewContainer {
  display: flex;
  flex-direction: column;
  background-color: #F4F6F9;
  min-height: 120vh;

  .submitBtn {
    border: none;
    color: white;
    width: 40%;
    height: 44px;
    background: #2F438C;
    border-radius: 12px;
  }

  .uploader {
    width: 80px;
    height: 45px;
  }

  .redDot {
    color: red;
  }

  input {
    border: none;
  }

  input:focus {
    outline: none;
  }

  .oftenContact {
    color: #2F438C;
    background-color: rgba(0, 100, 255, 0.1);
    border-radius: 8px;
    padding: 6px 4px;
  }

  .blueBgNotice {
    display: flex;
    flex-direction: column;
    height: 196px;
    background: #2F438C;
    border-radius: 0px 0px 24px 24px;
  }
}
</style>