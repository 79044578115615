<template>
  <div class="UploadPhoneViewContainer">
    <OssUploader @onUploadedFiles="onUploadedFiles" :key="`upload_${version}`" :limit="9"/>


    <nut-button type="primary" class="wd100" style="margin-top: 30px;" @click="upload">上传</nut-button>
  </div>
</template>

<script>
import OssUploader from "@/components/OssUploader";
import api from "@/api";
import {Toast} from "@nutui/nutui";

export default {
  name: "UploadPhoneView",
  components: {OssUploader},
  data() {
    return {
      version: 0,
      fs: [],
      token: this.$route.params.token,
    }
  },
  methods: {
    upload() {
      api.uploadVisitPhotos({
        token: this.token,
        files: this.fs
      }).then(() => {
        this.version++;
        this.files =  [];
        Toast.success('上传成功')
      })
    },
    onUploadedFiles(fs) {
      this.fs = fs;
    },
    init() {

    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.UploadPhoneViewContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 16px;
}
</style>