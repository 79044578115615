<template>
  <div class="MyCertViewContainer">
    <div class="bothCenter colFlex" v-if="!certs || certs.length < 1">
      <img src="https://cdn.waityou.online/5be1d65f-64a0-a13b-125d-255257564901.png" style="margin-top:30%;width: 60%"/>
      <span class="miniFont" style="color: #999999">没有记录</span>
    </div>
    <nut-row v-else class="bothCenter" :gutter="10" v-for="(cs,csi) in certs" :key="csi">
      <nut-col :span="12" class="cert">
        <img src="https://cdn.waityou.online/f08f815b-7bfd-6f2f-a1f4-4c76fbce5a13.png"/>
        <div class="reward pd16 colFlex">
          <span class="smallFont">祝贺{{cs[0].name}}</span>
          <span class="miniFont">{{ cs[0].content }}</span>
          <div class="rightCenter mt8">
            <span class="miniFont">杭州市消防救援支队</span>
          </div>
          <div class="rightCenter">
            <span class="miniFont">{{ cs[0].createdTime.substring(0,10) }}</span>
          </div>
        </div>
      </nut-col>
      <nut-col :span="12" class="cert" v-if="cs.length > 1">
        <img src="https://cdn.waityou.online/f08f815b-7bfd-6f2f-a1f4-4c76fbce5a13.png"/>
        <div class="reward pd16 colFlex">
          <span class="smallFont">祝贺{{cs[1].name}}</span>
          <span class="miniFont">{{ cs[1].content }}</span>
          <div class="rightCenter mt8">
            <span class="miniFont">杭州市消防救援支队</span>
          </div>
          <div class="rightCenter">
            <span class="miniFont">{{ cs[1].createdTime.substring(0,10) }}</span>
          </div>
        </div>
      </nut-col>
      <nut-col :span="12" class="cert" v-else>
        <span>&nbsp;</span>
      </nut-col>
    </nut-row>
  </div>
</template>

<script>
import api from "@/api";

export default {
  name: "MyCertView",
  data() {
    return {
      certs: []
    }
  },
  methods: {
    init() {
      api.getMyCerts()
          .then(ret => {
            this.certs = this.splitIntoGroups(ret, 2);
          })
    },
    splitIntoGroups(list, groupSize) {
      var groups = [];
      for (var i = 0; i < list.length; i += groupSize) {
        var group = list.slice(i, i + groupSize);
        groups.push(group);
      }

      return groups;
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.MyCertViewContainer {
  display: flex;
  flex-direction: column;
  background-color: #F4F6F9;
  padding: 16px;
  min-height: 100vh;

  .cert {
    position: relative;

    img {
      width: 100%;
    }

    .reward {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      color: #005FF5;
      padding-top: 55px;
    }
  }
}
</style>