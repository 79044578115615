<template>
  <div class="DateHistoryViewContainer">
    <nut-row className="switcher brd8">
      <nut-col :span="8"
               @click="tab=0"
               :class="tab==0?'actived bothCenter brd8':'bothCenter'">
        <span class="baseFont pd8">参观记录</span>
      </nut-col>
      <nut-col :span="8"
               @click="tab=1"
               :class="tab==1?'actived bothCenter brd8':'bothCenter'">
        <span class="baseFont">培训记录</span>
      </nut-col>
      <nut-col :span="8"
               @click="tab=2"
               :class="tab==2?'actived bothCenter brd8':'bothCenter'">
        <span class="baseFont">演练记录</span>
      </nut-col>
    </nut-row>
    <div class="colFlex vmg16" v-if="tab == 0">
      <DateHistoryCard v-for="d in visitList" :key="d.id"
                       :data="d"
                       @click="$router.push('/my/date/detail/'+d.id)"/>
    </div>
    <div class="colFlex vmg16" v-if="tab == 1">
      <TrainDateHistoryCard v-for="d in trainList" :key="d.id"
                       :data="d"
                       @click="$router.push('/my/date/train/detail/'+d.id)"/>
    </div>
    <div class="colFlex vmg16" v-if="tab == 2">
      <TrainDateHistoryCard v-for="d in drillList" :key="d.id"
                       :data="d"
                       @click="$router.push('/my/date/drill/detail/'+d.id)"/>
    </div>
  </div>
</template>

<script>
import DateHistoryCard from "@/components/date/DateHistoryCard"
import TrainDateHistoryCard from "@/components/date/TrainDateHistoryCard"
import api from "@/api";
export default {
  name: "DateHistoryView",
  components:{DateHistoryCard,TrainDateHistoryCard},
  data() {
    return {
      tab: this.$route.query.t?this.$route.query.t:0,
      visitList:[],
      trainList:[],
      drillList:[],
    }
  },
  methods: {
    init() {
      api.getMyVisitDateList()
          .then(ret=>this.visitList = ret)
      api.getMyTrainDateList()
          .then(ret=>this.trainList = ret)
      api.getMyDrillDateList()
          .then(ret=>this.drillList = ret)
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.DateHistoryViewContainer {
  display: flex;
  flex-direction: column;
  background-color: #F4F6F9;
  padding: 16px;
  min-height: calc(100vh - 32px);

  .switcher{
    background-color: white;
    padding: 2px;
    display: flex;
    flex-direction: row;

    .actived{
      background-color: #2F438C;
      color:white;
    }
  }
}
</style>