<template>
  <div class="NickNameUpdateViewContainer">
    <nut-row className="wbg brd16 pd16 vmg4 bothCenter mb16">
      <nut-col :span="12" class="leftCenter">
        <span>名字</span>
      </nut-col>
      <nut-col :span="12" class="rightCenter">
        <input class="infoInput smallFont" v-model="user.nickName" placeholder="请输入"/>
      </nut-col>
    </nut-row>
    <nut-row class="bothCenter vmg16">
      <nut-button class="btnA" @click="save">保存</nut-button>
    </nut-row>
  </div>
</template>

<script>
import api from "@/api";

export default {
  name: "NickNameUpdateView",
  data() {
    return {
      user: {},
    }
  },
  methods: {
    save() {
      api.updateInfo(this.user)
          .then(() => {
            this.$router.back()
          })
    },
    init() {
      api.getUserInfo().then(ret => {
        this.user = ret;
      })
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.NickNameUpdateViewContainer {
  display: flex;
  flex-direction: column;
  background-color: #F4F6F9;
  padding: 16px;
  min-height: 100vh;

  .btnA {
    width: 160px;
    height: 44px;
    background: #2F438C;
    border-radius: 12px;
    color: white;
    border: none;
  }


  .btnB {
    width: 160px;
    height: 44px;
    background: rgba(0, 100, 255, 0.1);
    border-radius: 12px;
    color: #2F438C;
    border: none;
  }

  .infoInput {
    text-align: right;
    border: none;
    width: 45vw;
  }

  .infoInput:focus {
    outline: none;
  }

  .infoInput::placeholder {
    color: #999999;
  }
}
</style>