<template>
  <div class="FixViewContainer">
    <nut-row class="bothCenter">
      <img src="https://cdn.waityou.online/cceb7a0f-84f6-bd99-d74d-a1bd0933413a.png"
           style="width: 80px"/>
    </nut-row>
    <nut-row class="bothCenter">
      <span class="miniFont mt8 mb16" style="color: #eae9e9">泰和智谷前端修复工具</span>
    </nut-row>
    <nut-grid class="mt16">
      <nut-grid-item class="colFlex" @click="noModel">
        <img src="https://cdn.waityou.online/fe820605-5ef4-5558-a237-a93abe5705c9.png"
             style="width: 40px;"
             class="grayImage"/>
        <span class="miniFont">弱网模式</span>
      </nut-grid-item>
      <nut-grid-item class="colFlex" @click="noModel">
        <img src="https://cdn.waityou.online/ca03ef6d-935c-d631-f1fe-0c62550b6acf.png"
             style="width: 40px;"
             class="grayImage"/>
        <span class="miniFont">故障模拟</span>
      </nut-grid-item>
      <nut-grid-item class="colFlex" @click="resetCookie">
        <img src="https://cdn.waityou.online/a9d82f13-40cd-052c-a81a-288bf68898da.png"
             style="width: 40px;"/>
        <span class="miniFont">cookie清理</span>
      </nut-grid-item>
      <nut-grid-item class="colFlex" @click="noModel">
        <img src="https://cdn.waityou.online/d18e168c-a980-3c31-2553-98fa1fe70b2e.png"
             style="width: 40px;"
             class="grayImage"/>
        <span class="miniFont">无图模式</span>
      </nut-grid-item>
      <nut-grid-item class="colFlex" @click="noModel">
        <img src="https://cdn.waityou.online/fcf4cb58-37d6-3132-5a8a-62fe79beb97f.png"
             style="width: 40px;"
             class="grayImage"/>
        <span class="miniFont">js刷新</span>
      </nut-grid-item>
      <nut-grid-item class="colFlex" @click="noModel">
        <img src="https://cdn.waityou.online/f1b82445-578b-9d0b-e68d-349a2a7c615d.png"
             style="width: 40px;"
             class="grayImage"/>
        <span class="miniFont">HTTP模式</span>
      </nut-grid-item>
      <nut-grid-item class="colFlex" @click="noModel">
        <img src="https://cdn.waityou.online/c5a886bb-7325-d3ba-9000-b7f9a7ba6b79.png"
             style="width: 40px;"
             class="grayImage"/>
        <span class="miniFont">匿名应答</span>
      </nut-grid-item>
      <nut-grid-item class="colFlex" @click="resetAll">
        <img src="https://cdn.waityou.online/bf4b52a5-42f5-9b59-05db-09ca6567ef83.png"
             style="width: 40px;"/>
        <span class="miniFont">重置所有</span>
      </nut-grid-item>
    </nut-grid>
  </div>
</template>

<script>
import {Toast} from "@nutui/nutui";

export default {
  name: "FixView",
  methods: {
    resetCookie(){
      localStorage.clear()
      Toast.success('cookie已清理')
    },
    resetAll(){
      this.resetCookie()
      Toast.success('已重置')
    },
    noModel(){
      Toast.fail('没有找到模块')
    },
    init() {

    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.FixViewContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 16px;

  .grayImage {
    filter: grayscale(100%);
  }
}
</style>