<template>
  <div :class="data.visit ?'DateSelectorItemContainer disabled':
  (selected?'DateSelectorItemContainer selected':'DateSelectorItemContainer')">
    <span>{{ data.timeStart.substring(0,10) }}</span>
    <span>{{ data.timeStart.substring(11,16) }}~{{ data.timeEnd.substring(11,16) }} | {{ data.visit ? '不可约' : '可约' }}</span>
  </div>
</template>

<script>
export default {
  name: "DateSelectorItem",
  props: {
    selected: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => {
        return {
          disabled: false,
          date: '01月08日(星期六)',
          time: '09:30-10:30',
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.DateSelectorItemContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 12px;
  font-size: $smallerFontSize;
  border: #F2F2F2 1px solid;
  width: 100%;
  border-radius: 8px;


}

.selected {
  background-color: #2F438C;
  color: white;
  box-shadow: 0px 4px 10px 0px rgba(0, 100, 255, 0.3);
  background-image: url("https://cdn.waityou.online/506e7b0a-eeb1-5a40-b40e-9e1f489ef4f5.png");
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 23px 23px;
}

.disabled {
  color: #999999;
  background-color: #F2F2F2;
}
</style>