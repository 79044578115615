<template>
  <div class="ExamViewContainer">
    <CommonExamView
        ref="examView"
        :question-list="questions"
        @submit="submitAnswer"/>
  </div>
</template>

<script>
import api from "@/api";
import CommonExamView from "@/components/exam/CommonExamView";

export default {
  name: "ExamView",
  components: {CommonExamView},
  data() {
    return {
      questions: [],
      answerStates: [],
      startTime: Date.now(),
      repo: {},
    }
  },
  methods: {
    submitAnswer(answer) {
      api.submitExamAnswer({
        'repoId': this.repo.id,
        'startTime': this.startTime,
        'answer': answer
      }).then(ret => {
        this.$router.replace('/exam/result/' + ret.id)
      })
    },
    init() {
      api.getActivedRepo()
          .then(ret => {
            if (ret) {
              this.repo = ret;
              api.getRepoQuestionList(ret.id)
                  .then(list => {
                    this.questions = list;
                  })
            }
          })
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.ExamViewContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
}
</style>