<template>
  <div class="CommonExamViewContainer">
    <div class="qBody bothCenter" v-if="inited">
      <!--      题目头开始-->
      <nut-row class=" mt8">
        <span class="number whiteFont">{{ qTypes[questions[currentQIndex].type] }} {{ currentQIndex + 1 }}/{{ questions.length }}</span>
      </nut-row>
<!--      <nut-row class="bothCenter">-->
<!--        <nut-col :span="12" class="leftCenter">-->
<!--          <div class="dot"/>-->
<!--          <span>{{ qTypes[questions[currentQIndex].type] }}</span>-->
<!--          <span class="number">{{ currentQIndex + 1 }} / {{ questions.length }}</span>-->
<!--        </nut-col>-->
<!--        <nut-col :span="12" class="rightCenter">-->
<!--          <div class="fontSizeSelector bothCenter">-->
<!--            <span :class="biggerFontSize?'fontSizeSelectorActive':''" @click="changeFontSize(true)">大字体</span>-->
<!--            <span :class="biggerFontSize?'':'fontSizeSelectorActive'" @click="changeFontSize(false)">小字体</span>-->
<!--          </div>-->
<!--        </nut-col>-->
<!--      </nut-row>-->
      <!--      题目头结束-->
<!--      <div class="divider"/>-->
      <!--      题目-->
      <ExamQuestionItem v-for="(q,i) in questions" :data="q"
                        :key="i"
                        :q-index="i"
                        :ref="el => setQuestionItemRef(el, i)"
                        @correct="correctVisible=true"
                        @updateJudgeState="(q,s)=>$emit('updateJudgeState',q,s)"
                        @updateChooseState="(q,s)=>$emit('updateChooseState',q,s)"
                        v-show="currentQIndex == i"/>
      <!--      题目结束-->
    </div>

    <div class="bothCenter" v-if="removeBtn">
      <nut-button class="removeBtn" @click="onRemoveQuestion">移除错题本</nut-button>
    </div>
    <nut-row class="nextQBtns bothCenter" v-if="inited">
      <nut-col :span="12" class="bothCenter">
        <nut-button class="prevBtn" @click="onPrevClick">上一题</nut-button>
      </nut-col>
      <nut-col :span="12" class="bothCenter">
        <nut-button class="nextBtn" @click="onNextClick">
          {{ currentQIndex + 1 < questions.length ? '下一题' : '交卷' }}
        </nut-button>
      </nut-col>
    </nut-row>
  </div>
</template>

<script>
import ExamQuestionItem from "@/components/exam/ExamQuestionItem";
import {Dialog, Toast} from "@nutui/nutui";
import api from "@/api";

export default {
  name: "CommonExamView",
  components: {ExamQuestionItem},
  data() {
    return {
      correctVisible: false,
      inited: false,
      biggerFontSize: false,
      questions: [],
      currentQIndex: 0,
      qRefs: [],
      qTypes: {
        'SELECT': '单选题',
        'MULTIPLE': '多选题',
        'JUDGE': '判断题',
      },
    }
  },
  props: {
    removeBtn: {
      type: Boolean,
      default: false,
    },
    judgeRightNow: {//是否原地判题
      type: Boolean,
      default: true,
    },
    questionList: {
      type: Array,
      default: () => {
        return []
      }
    },
    questionListTile: {
      type: String,
      default: "练一练"
    }
  },
  watch: {
    questionList() {
      this.initByQuestions();
    }
  },
  methods: {
    onRemoveQuestion() {
      this.$emit('remove', this.questions[this.currentQIndex].id)
    },
    goToQuestion(index) {
      this.currentQIndex = index;
    },
    submitCorrection(data) {
      this.correctVisible = false;
      Toast.success('提交成功!')
      api.submitCorrection(data);
    },
    setQuestionItemRef(el, i) {
      this.qRefs[i] = el;
    },
    onPrevClick() {
      if (this.currentQIndex > 0) {
        this.currentQIndex -= 1;
      } else {
        Toast.warn('已到第1题')
      }
    },
    onNextClick() {
      let inst = this.qRefs[this.currentQIndex];
      if (this.judgeRightNow && inst.isAnswered()) {
        //已答题且需要原地判题
        if (!inst.judgeAnswer()) {
          //答错了，停止下一题
          api.markWrongQuestion(this.questions[this.currentQIndex].id);
          return
        }
      }
      if (this.currentQIndex + 1 >= this.questions.length) {
        //检查下是否还有未答题目
        let notAnswered = 0;
        for (let q of this.qRefs) {
          if (!q.isAnswered()) {
            notAnswered++;
          }
        }
        if (notAnswered > 0) {
          Dialog({
            title: `还有${notAnswered}题未答，是否交卷?`,
            onCancel: () => {
            },
            onOk: () => {
              this.submitAnswer()
            }
          });
        } else {
          this.submitAnswer()
        }
      } else {
        this.currentQIndex++;
      }
    },
    submitAnswer() {
      //交卷
      let answers = {}
      for (let q of this.qRefs) {
        answers[q.getQuestionId()] = q.getYourAnswer();
      }
      this.$emit('submit', answers)
    },
    changeFontSize(bigger) {
      this.biggerFontSize = bigger;
      if (bigger) {
        localStorage.setItem('font-mode', 'old');
        document.getElementsByTagName('body')[0].style.setProperty('--max-font-size', '22px');
        document.getElementsByTagName('body')[0].style.setProperty('--bigger-font-size', '20px');
        document.getElementsByTagName('body')[0].style.setProperty('--base-font-size', '18px');
        document.getElementsByTagName('body')[0].style.setProperty('--smaller-font-size', '16px');
        document.getElementsByTagName('body')[0].style.setProperty('--mini-font-size', '14px');
        document.getElementsByTagName('body')[0].style.setProperty('--min-font-size', '12px');
      } else {
        localStorage.setItem('font-mode', 'normal');
        document.getElementsByTagName('body')[0].style.setProperty('--max-font-size', '20px');
        document.getElementsByTagName('body')[0].style.setProperty('--bigger-font-size', '18px');
        document.getElementsByTagName('body')[0].style.setProperty('--base-font-size', '16px');
        document.getElementsByTagName('body')[0].style.setProperty('--smaller-font-size', '14px');
        document.getElementsByTagName('body')[0].style.setProperty('--mini-font-size', '12px');
        document.getElementsByTagName('body')[0].style.setProperty('--min-font-size', '10px');
      }
    },
    initByQuestions() {
      if (this.questionList.length < 1) {
        return
      }
      this.questions = this.questionList;
      this.currentQIndex = 0;
      this.inited = true;
    },
  },
  mounted() {
    this.initByQuestions();
    this.biggerFontSize = localStorage.getItem('font-mode') == 'old';
  }
}
</script>

<style lang="scss" scoped>
.CommonExamViewContainer {
  display: flex;
  flex-direction: column;
  background-image: url("https://cdn.waityou.online/3ddba072-a7f0-06c9-7b3f-584ad9b9d8cb.png");
  background-size: 100%;
  background-repeat: no-repeat;
  background-color: #EDEDED;
  min-height: calc(100vh - 160px);
  padding-top: 160px;

  .removeBtn {
    height: 30px;
    background: rgba(27, 54, 210, 0.1);
    border-radius: 8px;
    color: #1B36D2;
    border: none;
    margin-top: 40px;
  }

  .nextQBtns {
    background-color: white;
    position: fixed;
    bottom: 0;
    font-size: $baseFontSize;
    padding: 10px 0;

    .prevBtn {
      border-radius: 12px;
      opacity: 0.3;
      border: 1px solid #2F438C;
      color: #2F438C;
      width: 90%;
      font-size: $baseFontSize;
    }

    .nextBtn {
      background: #2F438C;
      border-radius: 12px;
      color: white;
      width: 90%;
      border: none;
      font-size: $baseFontSize;
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    background: #F1F1F1;
  }

  .qBody {
    background-color: white;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    padding: 5px 10px;
    font-size: $baseFontSize;
    margin: 0 16px 0 16px;

    .divider {
      width: 100%;
      height: 1px;
      background: #F1F1F1;
    }

    .fontSizeSelector {
      background: #EDEDED;
      border-radius: 6px;
      font-size: $miniFontSize;
      margin: 10px 0;
      padding: 2px;

      .fontSizeSelectorActive {
        background: #1B36D2;
        border-radius: 4px;
        color: white;
        padding: 2px;
        margin: 2px;
      }
    }

    .number {
      //font-weight: lighter;
      height: 20px;
      background: #2F438C;
      border-radius: 4px;
      padding: 0 4px;
      font-size: $smallerFontSize;
    }

    .dot {
      width: 2px;
      height: 16px;
      margin-right: 5px;
      background: #1B36D2;
    }
  }
}
</style>