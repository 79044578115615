<template>
  <div class="AddContactorViewContainer">
    <nut-row className="wbg brd16 pd16 vmg4 bothCenter">
      <nut-col :span="12" class="leftCenter">
        <span>名字</span>
      </nut-col>
      <nut-col :span="12" class="rightCenter">
        <input class="infoInput smallFont" placeholder="请输入" v-model="info.name"/>
      </nut-col>
    </nut-row>
    <nut-row className="wbg brd16 pd16 vmg4 bothCenter">
      <nut-col :span="12" class="leftCenter">
        <span>联系号码</span>
      </nut-col>
      <nut-col :span="12" class="rightCenter">
        <input class="infoInput smallFont" placeholder="请输入" v-model="info.phone"/>
      </nut-col>
    </nut-row>
    <nut-row className="wbg brd16 pd16 vmg4 colFlex">
      <div>
        <nut-col :span="12" class="leftCenter">
          <span>证件类型</span>
        </nut-col>
        <nut-col :span="12" class="rightCenter">
          <span class="smallFont" style="color: #999999">中国居民身份证</span>
        </nut-col>
      </div>
      <div class="divider vmg16"/>
      <div>
        <nut-col :span="12" class="leftCenter">
          <span>证件号</span>
        </nut-col>
        <nut-col :span="12" class="rightCenter">
          <input class="infoInput smallFont" placeholder="请输入证件号" v-model="info.idCardNo"/>
        </nut-col>
      </div>
    </nut-row>
    <nut-row className="wbg brd16 pd16 vmg4 colFlex ">
      <span class="mb16">上传证件</span>
      <CustomOssUploader @onUploadedFiles="onUploadedFiles" :key="imageVersion">
        <img style="width: 80px;height: 45px;object-fit: cover" :src="info.idCard?info.idCard:defaultImage"/>
      </CustomOssUploader>
    </nut-row>
    <nut-row class="bothCenter vmg16">
      <nut-button class="btnA" @click="save">保存</nut-button>
    </nut-row>
    <!--    <nut-row class="bothCenter vmg8">-->
    <!--      <nut-button class="btnB" @click="$router.back()">删除</nut-button>-->
    <!--    </nut-row>-->
  </div>
</template>

<script>
import CustomOssUploader from "@/components/CustomOssUploader";
import {Toast} from "@nutui/nutui";
import api from "@/api";

export default {
  name: "AddContactorView",
  components: {CustomOssUploader},
  data() {
    return {
      info: {
        name: null,
        idCard: null,
        idCardNo: null,
        phone: null,
      },
      imageVersion: 0,
      defaultImage: 'https://cdn.waityou.online/47edb977-c8df-0219-1f56-e88de467ee3d.png'
    }
  },
  methods: {
    onUploadedFiles(files) {
      this.info.idCard = files[0];
      this.imageVersion++;
      api.getOcrReadIdCard({
        img: files[0]
      }).then(ret => {
        console.log('ocr', ret)
        if (ret) {
          this.info.idCardNo = ret;
          Toast.success('已为您自动识别证件号')
        }
      })
    },
    save() {
      if (!this.info.name || !this.info.idCardNo || !this.info.phone) {
        Toast.fail("请填写完整")
        return
      }
      api.saveContact(this.info)
          .then(() => {
            this.$router.back()
          })
    }
  },
}
</script>

<style lang="scss" scoped>
.AddContactorViewContainer {
  display: flex;
  flex-direction: column;
  background-color: #F4F6F9;
  padding: 16px;
  min-height: 100vh;

  .btnA {
    width: 160px;
    height: 44px;
    background: #2F438C;
    border-radius: 12px;
    color: white;
    border: none;
  }

  .btnB {
    width: 160px;
    height: 44px;
    background: rgba(0, 100, 255, 0.1);
    border-radius: 12px;
    color: #2F438C;
    border: none;
  }

  .infoInput {
    text-align: right;
    border: none;
  }

  .infoInput:focus {
    outline: none;
  }

  .infoInput::placeholder {
    color: #999999;
  }
}
</style>