import {createRouter, createWebHashHistory} from 'vue-router'
import LoginView from "@/views/LoginView";
import IndexView from "@/views/IndexView";
import MyView from "@/views/my/MyView";
import ErrorPage from "@/views/ErrorPage";
import LogoutView from "@/views/LogoutView";
import TeachDateView from "@/views/date/TeachDateView";
import DateSuccessView from "@/views/date/DateSuccessView";
import BatterVisitDateView from "@/views/date/BatterVisitDateView";
import DrillDateView from "@/views/date/DrillDateView";
import VisitEvaluateView from "@/views/evaluate/VisitEvaluateView";
import AskPaperView from "@/views/ask/AskPaperView";
import BatterVisitView from "@/views/visit/BatterVisitView";
import BatterDrillView from "@/views/drill/BatterDrillView";
import BatterTrainView from "@/views/train/BatterTrainView";
import VisitMustKnow from "@/views/visit/VisitMustKnow";
import StudyListView from "@/views/study/StudyListView";
import CreatePinGroupView from "@/views/date/CreatePinGroupView";
import StudyDetailView from "@/views/study/StudyDetailView";
import ConsultView from "@/views/consult/ConsultView";
import LeaveMessageView from "@/views/consult/LeaveMessageView";
import SettingView from "@/views/my/SettingView";
import MyContactorView from "@/views/my/MyContactorView";
import AddContactorView from "@/views/my/AddContactorView";
import MyCollectionView from "@/views/my/MyCollectionView";
import DateHistoryView from "@/views/my/DateHistoryView";
import DateHistoryDetailView from "@/views/my/DateHistoryDetailView";
import NickNameUpdateView from "@/views/my/NickNameUpdateView";
import PasswordUpdateView from "@/views/my/PasswordUpdateView";
import UpdatePhoneView from "@/views/my/UpdatePhoneView";
import ForgetPassView from "@/views/my/ForgetPassView";
import EditContactorView from "@/views/my/EditContactorView";
import JoinPinGroupView from "@/views/date/JoinPinGroupView";
import CreateGroupVisitView from "@/views/date/CreateGroupVisitView";
import TrainDateHistoryDetailView from "@/views/my/TrainDateHistoryDetailView";
import DrillDateHistoryDetailView from "@/views/my/DrillDateHistoryDetailView";
import RepoDetailView from "@/views/study/RepoDetailView";
import ExamView from "@/views/study/ExamView";
import ExamResult from "@/views/study/ExamResult";
import MyCertView from "@/views/my/MyCertView";
import TrainEvaluateView from "@/views/evaluate/TrainEvaluateView";
import DrillEvaluateView from "@/views/evaluate/DrillEvaluateView";
import MessageView from "@/views/my/MessageView";
import ConsultLicenseView from "@/views/consult/ConsultLicenseView";
import ConsultSubListView from "@/views/consult/ConsultSubListView";
import ConsultRegionInfoView from "@/views/consult/ConsultRegionInfoView";
import FixView from "@/views/FixView";
import TrainTeacherListView from "@/views/train/TrainTeacherListView";
import UploadPhoneView from "@/views/visit/UploadPhoneView";


const routes = [{
    path: '/', name: 'index', component: IndexView, meta: {
        auth: false, title: '消防便民'
    }
}, {
    path: '/logout', name: 'logout', component: LogoutView, meta: {
        auth: false, title: '已退出登录'
    }
}, {
    path: "/login", name: "login", component: LoginView, meta: {
        auth: false, title: '登录消防便民'
    },
}, {
    path: "/error", name: "error", component: ErrorPage, meta: {
        auth: false, title: '错误'
    }
}, {
    path: '/my', name: 'my', component: MyView, meta: {
        auth: true, title: '我的'
    }
}, {
    path: '/visit', name: 'visit', component: BatterVisitView, meta: {
        auth: true, title: '我要参观'
    }
}, {
    path: '/visit/visit/must/known/:stationId', name: 'visitMustKnow', component: VisitMustKnow, meta: {
        auth: true, title: '参观须知'
    }
}, {
    path: '/ask', name: 'ask', component: AskPaperView, meta: {
        auth: true, title: '知晓率问卷'
    }
}, {
    path: '/train', name: 'Train', component: BatterTrainView, meta: {
        auth: true, title: '我要培训'
    }
}, {
    path: '/teach/date/:stationId', name: 'TeachDate', component: TeachDateView, meta: {
        auth: true, title: '我要培训'
    }
},{
    path: '/teach/date/teacher/list/:stationId', name: 'TeacherList', component: TrainTeacherListView, meta: {
        auth: true, title: '讲师'
    }
}, {
    path: '/my/date/train/detail/:dateId', name: 'TrainDetail', component: TrainDateHistoryDetailView, meta: {
        auth: true, title: '培训详情'
    }
}, {
    path: '/train/evaluate/:dateId', name: 'TrainEvaluate', component: TrainEvaluateView, meta: {
        auth: true, title: '培训评价'
    }
}, {
    path: '/drill/evaluate/:dateId', name: 'DrillEvaluate', component: DrillEvaluateView, meta: {
        auth: true, title: '演练评价'
    }
}, {
    path: '/my/date/drill/detail/:dateId', name: 'DrillDetail', component: DrillDateHistoryDetailView, meta: {
        auth: true, title: '演练详情'
    }
}, {
    path: '/date/dateSuccess', name: 'dateSuccess', component: DateSuccessView, meta: {
        auth: true, title: '预约成功'
    }
}, {
    path: '/date/visit/:stationId', name: 'visitDate', component: BatterVisitDateView, meta: {
        auth: true, title: '我要参观'
    }
}, {
    path: '/date/drill/:stationId', name: 'DrillDate', component: DrillDateView, meta: {
        auth: true, title: '演练预约'
    }
},
    {
        path: '/drill', name: 'drill', component: BatterDrillView, meta: {
            auth: true, title: '我要演练'
        }
    }, {
        path: '/visit/evaluate/:dateId', name: 'evaluate', component: VisitEvaluateView, meta: {
            auth: true, title: '评价'
        }
    }, {
        path: '/study', name: 'Study', component: StudyListView, meta: {
            auth: true, title: '我要学习'
        }
    }, {
        path: '/study/repo/detail', name: 'RepoDetail', component: RepoDetailView, meta: {
            auth: true, title: '答题测试'
        }
    }, {
        path: '/study/repo/exam/page', name: 'ExamPageView', component: ExamView, meta: {
            auth: true, title: '答题测试'
        }
    }, {
        path: '/exam/result/:recordId', name: 'ExamResult', component: ExamResult, meta: {
            auth: true, title: '测试结果'
        }
    }, {
        path: '/study/detail/:classId', name: 'StudyDetail', component: StudyDetailView, meta: {
            auth: true, title: '我要学习'
        }
    }, {
        path: '/date/pin/create/:stationId/:dateId',
        name: 'CreatePin',
        component: CreatePinGroupView,
        meta: {
            auth: true, title: '创建拼团'
        }
    }, {
        path: '/date/visit/group/create/:stationId/:dateId',
        name: 'CreateGroup', component: CreateGroupVisitView, meta: {
            auth: true, title: '团队预约'
        }
    }, {
        path: '/date/pin/join/:teamId', name: 'JoinPin', component: JoinPinGroupView, meta: {
            auth: true, title: '报名入团'
        }
    }, {
        path: '/consult', name: 'consult', component: ConsultView, meta: {
            auth: true, title: '我要咨询'
        }
    },  {
        path: '/consult/regions', name: 'consultRegions', component: ConsultRegionInfoView, meta: {
            auth: true, title: '全市消防行政服务窗口'
        }
    }, {
        path: '/consult/license/:typeId', name: 'licenseView', component: ConsultLicenseView, meta: {
            auth: true, title: '消防安全检查许可'
        }
    }, {
        path: '/consult/sub/:typeId', name: 'ConsultSubListView', component: ConsultSubListView, meta: {
            auth: true, title: '其他行政工作'
        }
    }, {
        path: '/leave/message', name: 'LeaveMessage', component: LeaveMessageView, meta: {
            auth: true, title: '留言反馈'
        }
    }, {
        path: '/my/setting', name: 'MySetting', component: SettingView, meta: {
            auth: true, title: '设置'
        }
    }, {
        path: '/my/setting/name', name: 'NickNameSet', component: NickNameUpdateView, meta: {
            auth: true, title: '名字设置'
        }
    }, {
        path: '/my/setting/pass', name: 'PassSet', component: PasswordUpdateView, meta: {
            auth: true, title: '密码设置'
        }
    }, {
        path: '/my/setting/forget', name: 'ForgetPass', component: ForgetPassView, meta: {
            auth: true, title: '忘记密码'
        }
    }, {
        path: '/my/setting/phone', name: 'PhoneSet', component: UpdatePhoneView, meta: {
            auth: true, title: '手机号设置'
        }
    }, {
        path: '/my/contact', name: 'MyContactSetting', component: MyContactorView, meta: {
            auth: true, title: '常用联系人'
        }
    }, {
        path: '/my/contact/edit/:contactorId', name: 'EditMyContact', component: EditContactorView, meta: {
            auth: true, title: '编辑常用联系人'
        }
    }, {
        path: '/add/contact', name: 'AddContact', component: AddContactorView, meta: {
            auth: true, title: '添加联系人'
        }
    }, {
        path: '/my/collection', name: 'MyCollection', component: MyCollectionView, meta: {
            auth: true, title: '我的收藏'
        }
    }, {
        path: '/my/date', name: 'MyDate', component: DateHistoryView, meta: {
            auth: true, title: '我的预约'
        }
    }, {
        path: '/my/date/detail/:dateId', name: 'MyDateDetail', component: DateHistoryDetailView, meta: {
            auth: true, title: '我的预约'
        }
    }, {
        path: '/my/certs', name: 'MyCerts', component: MyCertView, meta: {
            auth: true, title: '我的证书'
        }
    }, {
        path: '/my/messages', name: 'MyMessage', component: MessageView, meta: {
            auth: true, title: '我的消息'
        }
    }, {
        path: '/fix', name: 'FixView', component: FixView, meta: {
            auth: false, title: '前端修复工具'
        }
    },{
        path: '/upload/visit/phone/:token', name: 'FixView', component: UploadPhoneView, meta: {
            auth: false, title: '一键传图'
        }
    },]

const router = createRouter({
    history: createWebHashHistory(), routes
})
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})
export default router
