<template>
  <div class="DateHistoryCardContainer mb16 brd16">
    <nut-row class="bothCenter mb16">
      <nut-col :span="20" class="leftCenter">
        <img style="width: 20px"
             class="brd4"
             :src="data?.station?.icon"/>
        <span class="baseFont boldFont">&nbsp;{{ data.stationName }}</span>
      </nut-col>
      <nut-col :span="4" class="rightCenter">
        <span class="smallFont">{{stateMap[data.state]}}</span>
      </nut-col>
    </nut-row>
    <nut-row class="leftCenter">
      <img style="width: 110px;height: 70px;object-fit: contain"
           class="brd8"
           :src="getImage()"/>
      <div class="colFlex miniFont prd12">
        <span>领队：{{ data?.members[0]?.userName }}</span>
        <span>手机：{{ data?.members[0]?.phone }}</span>
        <span>时间：{{ data.bookingDate }} {{ data.bookingDateTime }}-{{ data.finishDateTime }}</span>
      </div>
    </nut-row>
  </div>
</template>

<script>
export default {
  name: "DateHistoryCard",
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  methods: {
    getImage() {
      if(this.data?.station?.dataUrls){
        for(let u of this.data?.station?.dataUrls){
          if(u.endsWith('mp4')){
            return u;
          }
          return u;
        }
      }
      return 'https://cdn.waityou.online/cf83afbe-b7c6-a20f-2cf3-457902fcf037.png';
    }
  },
  data() {
    return {
      stateMap: {
        INIT: "拼团中",
        COMMIT: "待审核",
        CANCEL: "已取消",
        PASS: "待参观",
        PROCESSING: "参观中",
        FINISH: "已完成",
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.DateHistoryCardContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 16px;
}
</style>