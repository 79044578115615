<template>

  <div class="videoCover bothCenter">
    <img src="https://cdn.waityou.online/cf83afbe-b7c6-a20f-2cf3-457902fcf037.png"/>
  </div>
  <div class="StudyDetailViewContainer">
    <nut-row class="bothCenter">
      <nut-col :span="12" class="leftCenter">
        <span class="baseFont boldFont">学习内容</span>
      </nut-col>
      <nut-col :span="12" class="rightCenter">
        <img class="like" src="https://cdn.waityou.online/fca27565-990f-702c-fb04-29e03ad10fa6.png"/>
      </nut-col>
    </nut-row>
    <p class="smallFont" style="white-space: pre-line;">
      {{content}}
    </p>
  </div>
</template>

<script>
export default {
  name: "StudyDetailView",
  data() {
    return {
      content: '一、目录\n' +
          '      我是学习内容我是学习内容我是学习内容我是学习内容我是学习内容我是学习内容我是学习内容我是学习内容我是学习内容我是学习内容我是学习内容我是学习内容我是学习内容我是学习内容我是学习内容我是学习内容我是学习内容我是学习内容我是学习内容我是学习内容我是学习内容我是学习内容我是学习内容我是学习内容'
    }
  },
}
</script>

<style lang="scss" scoped>
.videoCover {
  height: 210px;
  background-image: url("https://cdn.waityou.online/70b6ec12-b540-de60-583a-839761ea362f.png");
  background-size: cover;

  img {
    width: 32px;
    height: 32px;
  }
}
.StudyDetailViewContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 16px;

  .like{
    width: 24px;
    height: 23px;
  }
}
</style>