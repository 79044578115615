<template>
  <div class="MyContactorViewContainer">
    <nut-row className="wbg brd16 pd16 vmg4 bothCenter" v-for="c in list" :key="c.id">
      <nut-col :span="16" class="colFlex">
        <span class="baseFont">{{ c.name }}</span>
        <span class="smallFont" style="color:#999999;">{{ c.phone }}</span>
      </nut-col>
      <nut-col :span="8" class="rightCenter">
        <img src="https://cdn.waityou.online/fd302597-fe82-ea2e-c4e2-da02f06fb276.png"
             @click="deleteContact(c)"
             style="width: 20px;margin-right: 8px"/>
        <img src="https://cdn.waityou.online/b48d07b8-e339-7a4d-687d-4e38e0c261bb.png"
             @click="editContact(c)"
             style="width: 20px;"/>
      </nut-col>
    </nut-row>

    <nut-row class="bothCenter vmg16">
      <nut-button class="btnA" @click="$router.push('/add/contact')">添加联系人</nut-button>
    </nut-row>
  </div>
</template>

<script>
import api from "@/api";
import {Dialog} from "@nutui/nutui";

export default {
  name: "MyContactorView",
  data() {
    return {
      list: []
    }
  },
  methods: {
    deleteContact(c){
      Dialog({
        title:'删除联系人',
        content:'确定删除联系人'+c.name+'吗?',
        onOk:()=>{
          api.deleteContact(c.id)
              .then(()=>{
                this.init()
              })
        }
      })
    },
    editContact(c){
      this.$router.push('/my/contact/edit/'+c.id)
    },
    init() {
      api.getMyContactors()
          .then(ret=>{
            this.list = ret;
          })
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.MyContactorViewContainer {
  display: flex;
  flex-direction: column;
  background-color: #F4F6F9;
  padding: 16px;
  min-height: 100vh;

  .btnA{
    width: 160px;
    height: 44px;
    background: #2F438C;
    border-radius: 12px;
    color:white;
    border: none;
  }
}
</style>