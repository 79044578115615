<template>
  <div class="StudyListViewContainer">
    <nut-row class="bothCenter">
      <nut-col :span="22" class="leftCenter">
        <div class="searchInput leftCenter">
          <nut-icon name="search" style="color:black"></nut-icon>
          <input type="text" placeholder="请输入名称..." v-model="nameLike" @change="onNameLickChange">
        </div>
      </nut-col>
      <nut-col :span="2" class="filterBtn colFlex bothCenter" @click="filterVisible=true">
        <img src="https://cdn.waityou.online/1b5d306d-d6f2-4ec8-0a4e-fa9b7786f07a.png"/>
        <span>筛选</span>
      </nut-col>
    </nut-row>
    <nut-tabs v-model="mediaType" type="smile" auto-height>
      <nut-tabpane title="视频" pane-key="0" style="background: transparent;padding: 0">
        <InfiniteLoadingList :query-id="1" :init-query="initQuery1">
          <template v-slot="data">
            <StudyItem :data="data.data" @click="preview(data.data)"/>
          </template>
        </InfiniteLoadingList>
      </nut-tabpane>
      <nut-tabpane title="图片" pane-key="1" style="background: transparent;padding: 0">
        <InfiniteLoadingList :query-id="1" :init-query="initQuery2">
          <template v-slot="data">
            <StudyItem :data="data.data" @click="preview(data.data)"/>
          </template>
        </InfiniteLoadingList>
      </nut-tabpane>
    </nut-tabs>
  </div>

  <div class="bottomBtns" v-if="repoExist">
    <nut-button class="questionTest" @click="$router.push('/study/repo/detail')">答题测试</nut-button>
  </div>
  <nut-popup position="bottom"
             round
             :close-on-click-overlay="true"
             v-model:visible="filterVisible">
    <nut-row>
      <StudyFilter @confirm="onFilterConfirm"/>
    </nut-row>
  </nut-popup>
  <nut-overlay
      class="bothCenter"
      @close="playerVisible=false"
      :close-on-click-overlay="false"
      v-model:visible="playerVisible">
    <div class="colFlex bothCenter">
      <video v-if="playerVisible" :src="videoUrl" controls style="width: 100%" @ended="onPlayEnd"/>
      <div class="whiteFont smallFont bothCenter vmg16" @click="playerVisible=false">
        <nut-icon name="close" size="12"></nut-icon>
        <span>关闭视频</span>
      </div>
    </div>
  </nut-overlay>
</template>

<script>
import StudyItem from "@/components/study/StudyItem";
import StudyFilter from "@/components/study/StudyFilter";
import InfiniteLoadingList from "@/components/InfiniteLoadingList";
import api from "@/api";

export default {
  name: "StudyListView",
  components: {InfiniteLoadingList, StudyFilter, StudyItem},
  data() {
    return {
      repoExist: false,
      filterVisible: false,
      playerVisible: false,
      nameLike: null,
      videoUrl: null,
      categoryTypeId: null,
      initQuery1: {
        dataType: 1,
      },
      initQuery2: {
        dataType: 0,
      },
      mediaType: '0',
    }
  },
  methods: {
    onNameLickChange() {
      this.initQuery1 = {
        categoryTypeId: this.categoryTypeId,
        name: this.nameLike,
      }
      this.initQuery2 = {
        categoryTypeId: this.categoryTypeId,
        name: this.nameLike,
      }
    },
    onFilterConfirm(f) {
      this.filterVisible = false;
      this.categoryTypeId = f.id;
      this.initQuery1 = {
        categoryTypeId: this.categoryTypeId,
        name: this.nameLike,
      }
      this.initQuery2 = {
        categoryTypeId: this.categoryTypeId,
        name: this.nameLike,
      }
    },
    onPlayEnd() {
    },
    preview(data) {
      api.markStudyView(data.id);
      if (data.dataUrl.endsWith('mp4')) {
        this.videoUrl = data.dataUrl;
        this.playerVisible = true;
      }
    },
    init() {
      api.getActivedRepo()
          .then(ret => {
            if (ret) {
              this.repoExist = true;
            }
          })
    },
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.bottomBtns {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: white;
  padding: 10px 0;

  .questionTest {
    width: 80%;
    height: 44px;
    color: white;
    background: #2F438C;
    border-radius: 12px;
    border: none;
  }
}

.StudyListViewContainer {
  display: flex;
  flex-direction: column;
  background-color: #F4F6F9;
  padding: 8px 16px;
  min-height: 100vh;
  padding-bottom: 100px;

  .searchInput {
    margin-top: 4px;
    width: 90%;
    border-radius: 8px;
    background-color: white;
    display: flex;
    padding: 6px 10px;

    input {
      border: none;
      margin-left: 8px;
      width: 80%;
      font-size: $smallerFontSize;
    }

    input::placeholder {
      font-size: $smallerFontSize;
    }

    input:focus {
      outline: none;
    }
  }

  .filterBtn {
    img {
      height: 12px;
      width: 14px;
    }

    span {
      font-size: $minFontSize;
    }
  }
}
</style>