<template>
  <div class="StationCardContainer">
    <div class="bothCenter">
      <nut-icon name="arrow-up" @click="$emit('upClick')"></nut-icon>
    </div>
    <div class="mb6" style="display: flex">
      <span class="distance">距你距离{{ calDistance() }}</span>
      <div class="leftCenter"
           @click="goNavigation"
           style="background-color: #2FC25B;color: white;border-radius: 4px;padding: 0 4px">
        <img src="https://cdn.waityou.online/d1861330-05cd-934d-6ab1-a57d335ccb61.png" style="width: 8px"/>
        <span class="miniFont">
        导航前往</span>
      </div>
    </div>
    <div class="leftCenter mb6">
      <span class="stationName boldFont">{{ data?.station?.name }}</span>
      <span class="pinTag">拼团</span>
    </div>
    <div class="mb6">
      <span class="address">{{ data?.station?.address }}</span>
    </div>
    <nut-row class="leftCenter mb8">
      <span class="miniFont mr8">评价指数</span>
      <nut-rate active-color="orange" icon-size="12"
                checked-icon="https://cdn.waityou.online/a7daaf55-aeae-d157-9eb6-e9554fdeb5f7.png"
                unchecked-icon="https://cdn.waityou.online/6caf8edf-e720-47fa-8652-976426ee5b62.png"
                readonly
                v-model="avgScore"/>
    </nut-row>
<!--    <nut-row class="bothCenter mb8" :gutter="16">-->
<!--      <nut-col :span="12">-->
<!--        <div class="reputation bothCenter">-->
<!--          <img src="https://cdn.waityou.online/7142f4cc-1c89-daf6-5c87-fab80d5ef513.png"/>-->
<!--          <span>收到锦旗</span>-->
<!--          <span style="color:#FF4747">{{ data.pennantCount }}面</span>-->
<!--        </div>-->
<!--      </nut-col>-->
<!--      <nut-col :span="12">-->
<!--        <div class="reputation bothCenter">-->
<!--          <img src="https://cdn.waityou.online/9cfe88d1-9d2e-f61b-9828-ab9da13c1794.png"/>-->
<!--          <span>收到鲜花</span>-->
<!--          <span style="color:#FFB35F">{{ data.flowerCount }}朵</span>-->
<!--        </div>-->
<!--      </nut-col>-->
<!--    </nut-row>-->
    <div class="divider"/>
    <nut-row class="bothCenter mt6">
      <nut-col :span="3" class="stationFunction bothCenter" v-for="(m,i) in menus"
               @click="onIconClick(m)"
               :key="i">
        <img :src="m.img"/>
        <span>{{ m.name }}</span>
      </nut-col>
<!--      <nut-col :span="6" class="bothCenter">-->
<!--        <span class="btn1" @click="goNavigation">导航</span>-->
<!--      </nut-col>-->
      <nut-col :span="12" class="bothCenter">
        <span class="btn2" @click="$emit('onAction',this.data.station)">{{ actionBtn }}</span>
      </nut-col>
    </nut-row>
  </div>
</template>

<script>
import {Toast} from "@nutui/nutui";

export default {
  name: "StationCard",
  props: {
    myLocation: {
      type: Object,
      default: () => {
        return null
      }
    },
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    menus: {
      type: Array,
      default: () => {
        return []
      }
    },
    actionBtn: {
      type: String,
      default: '约参观'
    },
  },
  watch: {
    data() {
      this.init()
    }
  },
  data() {
    return {
      avgScore: 5,
    }
  },
  methods: {
    goNavigation() {
      if (!this.myLocation) {
        Toast.warn('请注意您的定位可能不准确')
      }
      window.open(`https://uri.amap.com/navigation?to=${this.data.station.longitude},${this.data.station.latitude},${this.data.station.name}&mode=car&policy=&src=xfbm&coordinate=gaode&callnative=1`)
    },
    onIconClick(icon) {
      if (icon.stationLink) {
        this.$router.push(icon.link + this.data.station.id)
      } else {
        this.$router.push(icon.link)
      }
    },
    calDistance() {
      if (!this.myLocation) {
        return '未知'
      }
      // eslint-disable-next-line no-undef
      return parseInt(AMap.GeometryUtil.distance(this.myLocation,
          // eslint-disable-next-line no-undef
          new AMap.LngLat(this.data.station.longitude, this.data.station.latitude))) + '米'
    },
    init(){
      this.avgScore = this.data.station.avgScore;
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.StationCardContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 16px;
  margin: 16px 0 16px 8px;
  padding: 8px 16px;
  width: 85vw;

  .divider {
    background-color: #DEEDFF;
    height: 1px;
    width: 100%;
    margin-bottom: 8px;
  }

  .btn1 {
    color: #2F438C;
    background: rgba(0, 100, 255, 0.1);
    border-radius: 8px;
    border: none;
    width: 90%;
    text-align: center;
    line-height: 38px;
  }

  .btn2 {
    color: white;
    background: #2F438C;
    border-radius: 8px;
    border: none;
    width: 90%;
    text-align: center;
    line-height: 38px;
  }

  .stationFunction {
    display: flex;
    flex-direction: column;

    img {
      width: 24px;
      height: 24px;
    }

    span {
      font-size: $minFontSize;
    }

  }

  .reputation {
    background-color: #F2F2F2;
    border-radius: 16px;
    height: 32px;
    margin: 8px 0;

    img {
      width: 13.75px;
      height: 16px;
    }

    span {
      margin: 4px;
      font-size: $miniFontSize;
    }
  }

  .address {
    font-size: $miniFontSize;
  }

  .stationName {
    font-size: $baseFontSize;
  }

  .pinTag {
    font-size: $minFontSize;
    color: #2FC25B;
    background-color: rgba(222, 237, 255, 0.5);
    padding: 1px;
    border-radius: 2px;
    margin-left: 6px;
  }

  .distance {
    font-size: $miniFontSize;
    color: #2F438C;
    margin-right: 6px;
  }
}
</style>