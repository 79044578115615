<template>
  <div class="evaluateContainer">
    <nut-row class="evaluateTitle">
      <span class="baseFont">综合体验评分</span>
    </nut-row>
    <nut-row style="padding: 10px 0">
      <nut-rate active-color="orange" icon-size="30"
                checked-icon="https://cdn.waityou.online/a7daaf55-aeae-d157-9eb6-e9554fdeb5f7.png"
                unchecked-icon="https://cdn.waityou.online/6caf8edf-e720-47fa-8652-976426ee5b62.png"
                v-model="score"/>
    </nut-row>
<!--    <nut-row :gutter="10">-->
<!--      <nut-col :span="12" :class="gifts.pennant?'bothCenter':'bothCenter gift'" @click="gifts.pennant=!gifts.pennant">-->
<!--        <div class="wbg wd100 brd16 pd16 bothCenter">-->
<!--          <img style="width: 25px" v-if="gifts.pennant" src="https://cdn.waityou.online/b1d851b5-dc1a-304e-ba89-320553c1b302.png"/>-->
<!--          <img style="width: 25px" v-else src="https://cdn.waityou.online/6846e10e-a6c8-259b-37b8-e7bba803f105.png"/>-->
<!--          <span>送一面锦旗</span>-->
<!--        </div>-->
<!--      </nut-col>-->
<!--      <nut-col :span="12" :class="gifts.flower?'bothCenter':'bothCenter gift'" @click="gifts.flower=!gifts.flower">-->
<!--        <div class="wbg wd100 brd16 pd16  bothCenter">-->
<!--          <img style="width: 25px" v-if="gifts.flower" src="https://cdn.waityou.online/8ea856d7-f248-6d84-aae7-6d21ec20668e.png"/>-->
<!--          <img style="width: 25px" v-else src="https://cdn.waityou.online/5de4dc67-a3e7-155a-6c98-74c17c6f46c7.png"/>-->
<!--          <span>送一朵鲜花</span>-->
<!--        </div>-->
<!--      </nut-col>-->
<!--    </nut-row>-->
    <nut-row class="vmg16">
      <nut-textarea placeholder="评价描述" rows="6" class="brd16" v-model="comment"></nut-textarea>
    </nut-row>
    <nut-row class="evaluateTitle vmg16">
      <span class="smallFont">参观图片(限制9张)</span>
    </nut-row>
    <nut-row>
      <OssUploader :multiple="true" :limit="9" @onUploadedFiles="onUploadedFiles"/>
    </nut-row>
    <nut-row class="bothCenter vmg16">
      <nut-button size="large" shape="rect" class="confirmBtn" @click="submit">提交评价</nut-button>
    </nut-row>
  </div>
</template>

<script>
import OssUploader from "@/components/OssUploader";
import api from "@/api";
import {Toast} from "@nutui/nutui";

export default {
  name: "DrillEvaluateView",
  components: {OssUploader},
  data() {
    return {
      score: 5,
      gifts: {
        pennant: false,
        flower: false
      },
      images: [],
      comment: null,
      dateId: this.$route.params.dateId
    }
  },
  methods: {
    submit() {
      if (!this.comment) {
        Toast.warn('说点什么吧')
        return
      }
      api.submitDrillEvaluation({
        bookingId: this.dateId,
        score: this.score,
        isPennant: this.gifts.pennant,
        isFlower: this.gifts.flower,
        imgs: this.images,
        content: this.comment
      }).then(() => {
        Toast.success('评价完成!')
        this.$router.back();
      })
    },
    onUploadedFiles(files) {
      this.images = files;
    }
  },
}
</script>
<style lang="scss" scoped>
.evaluateContainer {
  background-color: #F4F6F9;
  padding: 16px;
  min-height: calc(100vh - 32px);
  display: flex;
  flex-direction: column;

  .gift {
    opacity: 0.7;
  }

  .confirmBtn {
    border: none;
    color: white;
    width: 160px;
    height: 44px;
    background: #2F438C;
    border-radius: 12px;
  }

  .evaluateTitle {
    font-size: $baseFontSize;
  }

}
</style>