<template>
  <div class="RepoDetailViewContainer pd16">
    <div class="bothCenter mt16">
      <span class="whiteFont">{{ repo.name }}</span>
    </div>
    <div class="wbg colFlex pd16 bothCenter brd16 mt16">
      <nut-row class="bothCenter mt16">
        <nut-col :span="12" class="bothCenter">
          <span style="font-size: 26px" class="boldFont">{{ repo.questionCount }}题</span>
        </nut-col>
        <nut-col :span="12" class="bothCenter">
          <span style="font-size: 26px" class="boldFont">不限时</span>
        </nut-col>
      </nut-row>
      <nut-row class="bothCenter miniFont">
        <nut-col :span="12" class="bothCenter">
          <span>题目数量</span>
        </nut-col>
        <nut-col :span="12" class="bothCenter">
          <span>测试时长</span>
        </nut-col>
      </nut-row>
      <nut-row class="bothCenter mt16">
        <nut-col :span="12" class="bothCenter">
          <span style="font-size: 26px" class="boldFont">{{
              repo?.exam?.questionDetails[0].score*repo?.exam?.questionDetails[0].count+
              repo?.exam?.questionDetails[1].score*repo?.exam?.questionDetails[1].count+
              repo?.exam?.questionDetails[2].score*repo?.exam?.questionDetails[2].count
            }}分</span>
        </nut-col>
        <nut-col :span="12" class="bothCenter">
          <span style="font-size: 26px" class="boldFont">{{ repo?.exam?.passScore }}分</span>
        </nut-col>
      </nut-row>
      <nut-row class="bothCenter miniFont">
        <nut-col :span="12" class="bothCenter">
          <span>总分</span>
        </nut-col>
        <nut-col :span="12" class="bothCenter">
          <span>通过分数</span>
        </nut-col>
      </nut-row>

      <span class="mt16 miniFont" style="color:#999999;">若测试未通过可重复测试，次数不限</span>
    </div>
  </div>
  <div class="bothCenter wd100" style="position: fixed;bottom: 20px;">
    <div class="wd100 opd16" >
      <nut-button class="startBtn" @click="startTest">开始测试</nut-button>
    </div>
  </div>

</template>

<script>
import api from "@/api";

export default {
  name: "RepoDetailView",
  data() {
    return {
      repo: {}
    }
  },
  methods: {
    startTest(){
      this.$router.push('/study/repo/exam/page')
    },
    init() {
      api.getActivedRepo()
          .then(ret=>{
            if(ret){
              this.repo = ret;
            }
          })
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>

.startBtn {
  border: none;
  color: white;
  width: 100%;
  height: 44px;
  background: #2F438C;
  border-radius: 12px;
}
.RepoDetailViewContainer {
  display: flex;
  flex-direction: column;
  background-color: #F4F6F9;
  background-image: url("https://cdn.waityou.online/40845ab0-4ccd-9fad-9e59-0599301c403c.png");
  background-size: 100%;
  background-repeat: no-repeat;
  min-height: 90vh;

}
</style>