<template>
  <div class="MyContactListContainer bothCenter">
    <div class="wbg opd8 vpd16 brd16 colFlex bothCenter" style="width: 80%">
      <span class="baseFont boldFont vmg4">常用联系人</span>
      <nut-row className="wd90 bothCenter brd8 pd8 mb8"
               v-for="(c,ci) in list" :key="ci"
               @click="selected=ci"
               style="background: #F2F2F2">
        <nut-col :span="20" class="colFlex">
          <span class="smallFont">{{ c.name }}</span>
          <span class="miniFont" style="color:#999999;">{{ c.phone }}</span>
        </nut-col>
        <nut-col :span="4" class="rightCenter">
          <img v-if="selected == ci" src="https://cdn.waityou.online/973861e1-24d1-030a-5772-fd74e97dac8a.png"
               style="width: 22px"/>
          <span v-else>&nbsp;</span>
        </nut-col>
      </nut-row>
      <nut-row class="bothCenter" :gutter="10">
        <nut-col :span="12" class="rightCenter">
          <button class="cancelBtn" @click="$emit('close')">关闭</button>
        </nut-col>
        <nut-col :span="12" class="leftCenter">
          <button class="okBtn" @click="confirm">确定</button>
        </nut-col>
      </nut-row>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import {Toast} from "@nutui/nutui";

export default {
  name: "MyContactList",
  props: {},
  data() {
    return {
      selected: 0,
      list: []
    }
  },
  methods: {
    confirm() {
      this.$emit('use', this.list[this.selected])
      this.$emit('close')
    },
    init() {
      api.getMyContactors()
          .then(ret => {
            if (!ret || ret.length < 1) {
              Toast.warn("请先到'我的'添加常用联系人")
              this.$emit('close');
            } else {
              this.list = ret;
            }
          })
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.MyContactListContainer {
  display: flex;
  flex-direction: column;
  padding: 16px;
  height: 100%;

  .cancelBtn {
    width: 95%;
    border: none;
    height: 40px;
    color: #2F438C;
    border-radius: 8px;
    background: rgba(0, 100, 255, 0.1);
  }

  .okBtn {
    width: 95%;
    border: none;
    height: 40px;
    color: white;
    border-radius: 8px;
    background: #2F438C;
  }
}
</style>