<template>
  <div class="ConsultBusinessItemContainer">
    <img style="width: 40px;height: 40px;margin-bottom: 12px" :src="data.icon"/>
    <span class="smallFont">{{ data.name }}</span>
  </div>
</template>

<script>
export default {
  name: "ConsultBusinessItem",
  props: {
    data: {
      type: Object,
      default: () => {
        return {

        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.ConsultBusinessItemContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 16px;
  margin-bottom: 10px;
  border-radius: 16px;
  width: 100%;
}
</style>