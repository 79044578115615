<template>
  <div class="StudyFilterContainer">
    <nut-row class="bothCenter mb16">
      <nut-col :span="12" class="leftCenter">
        <span class="baseFont boldFont">筛选</span>
      </nut-col>
      <nut-col :span="12" class="rightCenter">
        <nut-button class="confirmBtn" @click="$emit('confirm',options[selected])">确认</nut-button>
      </nut-col>
    </nut-row>
    <div v-for="(op,idx) in options" :key="idx">
      <nut-row class="bothCenter" @click="selected=idx">
        <nut-col :span="12" class="leftCenter">
          <span class="baseFont">{{ op.topic }}</span>
        </nut-col>
        <nut-col :span="12" class="rightCenter">
          <img class="checked"
               v-show="selected==idx"
               src="https://cdn.waityou.online/fe0d4fae-e004-df33-17de-2624b405ded6.png"/>
        </nut-col>
      </nut-row>
      <div class="divider vmg16"></div>
    </div>
  </div>
</template>

<script>
import api from "@/api";

export default {
  name: "StudyFilter",
  data() {
    return {
      selected: 0,
      options: [
        '防火',
        '安全生产',
        '生活技能'
      ]
    }
  },
  methods: {
    init() {
      api.getStudyDataFilter()
          .then(ret=>{
            this.options = ret;
          })
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.StudyFilterContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 16px;

  .checked {
    width: 21px;
    height: 16px;
  }

  .confirmBtn {
    color: white;
    height: 32px;
    border: none;
    background: #2F438C;
    border-radius: 8px;
  }
}
</style>