<template>
  <div class="BatterVisitViewContainer">
    <StationMapView :menus="menus" action-btn="预约参观"
                    :station-type="3"
                    :evaluate="{
                      type: 'STATION_VISIT',
                      name: '参观评价'
                    }"
                    @action="onAction"></StationMapView>
  </div>
</template>

<script>
import StationMapView from "../map/StationMapView"
import {Toast} from "@nutui/nutui";

export default {
  name: "BatterVisitView",
  components: {StationMapView},
  data() {
    return {
      menus: [
        {
          img: 'https://cdn.waityou.online/441bc5aa-e449-848e-b02e-c03401e348f1.png',
          name: '培训',
          link: '/train'
        },
        {
          img: 'https://cdn.waityou.online/4b9948f5-57b7-5c07-6dc2-b97f7dc81c31.png',
          name: '演练',
          link: '/drill/'
        },
        {
          img: 'https://cdn.waityou.online/21ea60b1-bc20-6cdc-3c34-84f9b185bdfb.png',
          name: '学习',
          link: '/study'
        },
        {
          img: 'https://cdn.waityou.online/c323b885-0d6b-70b9-6eed-d5d1a51afd6c.png',
          name: '咨询',
          link: '/consult'
        },
      ]
    }
  },
  methods: {
    onAction(station) {
      // Toast.warn('即将上线,敬请期待');

      if (!station.stationTemplateId || station.openState == 'NO') {
        Toast.fail('很抱歉,该站点暂时无法接待')
        return
      }
      this.$router.push('/visit/visit/must/known/' + station.id)
    }
  },

}
</script>

<style lang="scss" scoped>
.BatterVisitViewContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
}
</style>