<template>
  <nut-row  class="loading" v-if="fail">
    <span>账号异常,登录失败</span>
  </nut-row>
  <nut-row class="loading" v-else>
    <h3>登录中...</h3>
    <nut-icon name="loading" size="large"></nut-icon>
  </nut-row>
</template>


<script>
import {Dialog, Toast} from '@nutui/nutui';
import api from "@/api";
import {mapMutations} from "vuex";
import md5 from 'js-md5'

export default {
  name: "LoginView",
  data() {
    return {
      fail: false,
      accountLoginType: false,
      account: '',
      password: '',
      captcha: '',
      fastLoginType: false,
      isWxEnv: false,
      captchaToken: '376233414898836402b069bdefault',
      wxBindToken: null,
    }
  },
  watch: {
    accountLoginType(b) {
      if (!b) {
        return
      }
      this.refreshCaptcha();
    }
  },
  methods: {
    ...mapMutations(['updateUser', 'updateEnv']),
    wxLogin() {
      Toast.loading('自动登录中...', {
        duration: 5000
      });
      let url = this.$route.query.redirect;
      let code = this.$route.query.code;
      if (code) {
        api.wechatCodeLogin(code).then(ret => {
          Toast.hide();
          if (ret.action === 0) {
            this.accountLoginType = true;
            this.wxBindToken = ret.token;
            Toast.fail("您未绑定账号，请使用账号密码登录");
            this.refreshCaptcha();
          } else {
            this.setAccessToken(ret.accessToken);
            this.loadUserInfo();
          }
        }).catch(()=>{
          Toast.hide()
          this.fail = true;
        })
      } else {
        api.getWechatRedirectUrl(url).then(ret => {
          window.location.href = ret;
        })
      }
    },
    passwordLogin() {
      if (!this.account || !this.password || !this.captcha) {
        Toast.warn('账号、密码、验证码都是必填项');
        return
      }
      Toast.loading('登录中...');
      api.login({
        phone: this.account,
        password: md5(this.password),
        captcha: this.captcha,
        token: this.captchaToken,
        channel: 1
      }).then(ret => {
        Toast.hide();
        this.setAccessToken(ret.accessToken);
        if (this.wxBindToken) {
          Dialog({
            title: '是否绑定该账号',
            content: '绑定后再次打开可免密登录',
            onCancel: () => {
              this.loadUserInfo();
            },
            onOk: () => {
              api.bindWechat(this.wxBindToken).then(() => {
                Toast.success("绑定成功!");
              });
              this.loadUserInfo();
            }
          });
        } else {
          this.loadUserInfo();
        }
      })
    },
    setAccessToken(accessToken) {
      localStorage.setItem('access_token', accessToken);
    },
    loadUserInfo() {
      api.getUserInfo().then(userInfo => {
        document.title = '消防便民';
        this.updateUser(userInfo);
        this.updateEnv(this.isWxEnv ? 'wechat' : 'h5');

        // 取到原页面路径
        let url = this.$route.query.redirect;
        // 跳转回原页面
        this.$router.push({
          path: decodeURIComponent(url ? url : '/')
        });
      })
    },
    refreshCaptcha() {
      api.getLoginCaptchaToken().then(ret => {
        this.captchaToken = ret;
      });
    }
  },
  mounted() {
    document.title = '消防便民登录';
    //检测快捷登录码
    // if (this.$route.query.accessToken) {
    //   Toast.loading('请稍等...', {
    //     duration: 500
    //   });
    //   this.fastLoginType = true;
    //   localStorage.setItem('access_token', this.$route.query.accessToken);
    //   this.$router.push("/");
    //   return;
    // }
    //环境检测
    // var userAgent = window.navigator.userAgent.toLowerCase();
    // console.log(userAgent);
    // if (userAgent.match(/MicroMessenger/i) == "micromessenger") {
      this.isWxEnv = true;
      this.wxLogin();
    // } else {
      //非自动化环境
      // this.accountLoginType = true;
    // }
    // this.refreshCaptcha();
  }
}
</script>

<style lang="scss" scoped>
.loading{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30%;
}
.loginBtn {
  font-size: $baseFontSize;
}

.login-input {
  border: 1px solid lightgray;
  width: 80%;
  margin-left: 10%;
  border-radius: 20px;
  padding: 5px 10px;
  margin-bottom: 10px;
  font-size: $baseFontSize;
}

.login-input-captcha {
  margin-left: auto;
}

.nut-row {
  overflow: hidden;

  &:not(:last-child) .nut-col {
    margin-bottom: 15px;
  }

  margin-bottom: 15px;
}

.flex-content {
  line-height: 40px;
  text-align: center;
  border: 0;
  font-size: $baseFontSize;

  p {
    padding: 0;
    margin: 0;
    line-height: 25px;
    font-size: $baseFontSize;
    color: #005FF5;
  }
}
</style>