<template>
  <div class="StudyItemContainer">
    <nut-row class="bothCenter" v-if="data.type == 'IMG'">
      <photo-provider>
        <photo-consumer :src="data.dataUrl" style="text-align: left;width: 100%">
          <div class="wd100">
            <nut-col :span="10" class="leftCenter">
              <div class="cover" :style="{backgroundImage:`url(${data.previewImage}?x-oss-process=image/resize,p_30)`}">
                <div class="tag">
                  <span>{{ data.viewCnt }}已看</span>
                </div>
              </div>
            </nut-col>
            <nut-col :span="14" class="colFlex">
              <span class="title">{{ data.name }}</span>
              <span class="postTime">{{ data.createdTime.substring(0, 10) }}</span>
            </nut-col>
          </div>
        </photo-consumer>
      </photo-provider>
    </nut-row>
    <nut-row class="bothCenter" v-else>
      <nut-col :span="10" class="leftCenter">
        <div class="cover" :style="{backgroundImage:`url(${data.previewImage}?x-oss-process=image/resize,p_30)`}">
          <div class="tag">
            <span>{{ data.viewCnt }}已看</span>
          </div>
        </div>
      </nut-col>
      <nut-col :span="14" class="colFlex">
        <span class="title">{{ data.name }}</span>
        <span class="postTime">{{ data.createdTime.substring(0, 10) }}</span>
      </nut-col>
    </nut-row>
  </div>
</template>

<script>
export default {
  name: "StudyItem",
  data() {
    return {
    }
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
}
</script>

<style lang="scss" scoped>

.StudyItemContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 8px;
  border-radius: 16px;
  margin-bottom: 8px;

  .cover {
    position: relative;
    background-image: url("https://cdn.waityou.online/81a925eb-cb14-b569-140e-dcdd74816bd5.png");
    background-size: cover;
    height: 20vw;
    width: 100%;
    border-radius: 8px;

    .tag {
      position: absolute;
      left: 0;
      top: 0;
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      display: flex;
      padding: 0 2px;
      border-radius: 8px 0px 8px 0px;

      span {
        padding: 2px;
        font-size: $minFontSize;
      }
    }
  }

  .title {
    margin-left: 6px;
    font-size: $smallerFontSize;
  }

  .postTime {
    margin-left: 6px;
    font-size: $miniFontSize;
  }
}
</style>