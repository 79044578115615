<template>
  <view class="dateContainer">
    <nut-row class="grayBlock"
             style="display:flex;justify-content: center;align-items:center; margin: 100px 0;flex-direction: column">
      <img style="width: 50px" src="https://cdn.waityou.online/a58b10ef-b1f2-9eca-6b76-3aceae64d21e.svg"/>
      <span class="dateSuccess">预约成功</span>
      <span class="notice">您已成功预约，请等待电话确认</span>
    </nut-row>
    <nut-row class="grayBlock">
      <nut-row>
        <nut-button size="large" shape="rect" class="confirmBtn">查看预约详情</nut-button>
        <nut-button size="large" shape="rect" class="toIndexBtn" @click="gotoIndex">返回首页</nut-button>
      </nut-row>
    </nut-row>
  </view>
</template>

<script>

export default {
  name: "DateSuccessView",
  components: {},
  methods: {
    gotoIndex() {
      this.$router.push('/')
    }
  },
}
</script>

<style lang="scss" scoped>

.dateSuccess {
  color: black;
  margin: 50px 0 20px 0;
}

.notice {
  font-size: $smallerFontSize;
}

.flexView {
  display: flex;
}

.toIndexBtn {
  margin-top: 20px;
  background-color: rgba(76, 133, 237, 0.1);
  color: #4C85ED;
  border-color:rgba(76, 133, 237, 0.1);
  border-radius: 5px;
}

.confirmBtn {
  background-color: #4C85ED;
  color: white;
  border-color: #4C85ED;
  border-radius: 5px;
}

.dateContainer {
  width: 100%;
  height: 100%;
  //background-color: rgba(222, 222, 222, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: $baseFontSize;

  span {
    padding: 10px;
  }

  .whiteBlock {
    margin: 5% 0;
    width: 90%;
    display: flex;
    background-color: white;
    border-radius: 10px;
    flex-direction: column;

    span {
      padding: 15px;
    }
  }

  .grayBlock {
    width: 90%;
    display: flex;
    border-radius: 10px;
    font-size: $smallerFontSize;
    color: gray;

    span {
      padding: 0;
    }
  }
}
page{
  background-color: rgba(222, 222, 222, 0.2);
}
</style>