<template>
  <div class="PinGroupContainer">
    <nut-row class="bothCenter">
      <nut-col :span="16" class="leftCenter">
        <img src="https://cdn.waityou.online/38a69ace-e033-f550-296c-c8461e7de932.png"/>
        <span class="title">{{ data.teamName }}</span>
      </nut-col>
      <nut-col :span="8" class="wantMore rightCenter">
        <span v-if="data.groupMinPeopleCount>data.groupPeopleCount">缺{{data.groupMinPeopleCount-data.groupPeopleCount}}人</span>
        <span v-else>还可拼{{data.groupMaxPeopleCount-data.groupPeopleCount}}人</span>
      </nut-col>
    </nut-row>
    <nut-row class="groupInfo">
      <nut-col :span="5" class="leftCenter">
        <span class="subTitle">拼团团长</span>
      </nut-col>
      <nut-col :span="19" class="leftCenter">
        <span>{{ data.userName }}</span>
      </nut-col>
    </nut-row>
    <nut-row class="groupInfo">
      <nut-col :span="5" class="leftCenter">
        <span class="subTitle">参观站点</span>
      </nut-col>
      <nut-col :span="19" class="leftCenter">
        <span>{{ data.stationName }}</span>
      </nut-col>
    </nut-row>
    <nut-row class="groupInfo">
      <nut-col :span="5" class="leftCenter">
        <span class="subTitle">参观时间</span>
      </nut-col>
      <nut-col :span="19" class="leftCenter">
        <span>{{ data.bookingDate }} {{ data.bookingDateTime }}-{{data.finishDateTime}}</span>
      </nut-col>
    </nut-row>
    <nut-row class="groupInfo">
      <nut-col :span="5" class="leftCenter">
        <span class="subTitle">开团人数</span>
      </nut-col>
      <nut-col :span="19" class="leftCenter">
        <span>{{data.groupMinPeopleCount}}人</span>
      </nut-col>
    </nut-row>
    <nut-row class="timeout">
      <span>{{convertMilliseconds(data.visitMills-new Date().getTime())}}</span>
    </nut-row>
<!--    <nut-row class="notice">-->
<!--      <span>参观前3天未满人数，拼团将自动过期</span>-->
<!--    </nut-row>-->
  </div>
</template>

<script>
export default {
  name: "PinGroup",
  props: {
    data: {
      type: Object,
      default: ()=>{
        return {

        }
      }
    },
  },
  methods: {
    convertMilliseconds(ms) {
      // 计算总秒数
      var totalSeconds = Math.floor(ms / 1000);
      // 计算天数
      var days = Math.floor(totalSeconds / (24 * 60 * 60));
      totalSeconds = totalSeconds % (24 * 60 * 60);
      // 计算小时数
      var hours = Math.floor(totalSeconds / (60 * 60));
      totalSeconds = totalSeconds % (60 * 60);
      // 计算分钟数
      var minutes = Math.floor(totalSeconds / 60);
      totalSeconds = totalSeconds % 60;
      // 剩余的秒数
      var seconds = totalSeconds;
      // 返回结果
      return `${days}天${hours}小时${minutes}分钟${seconds}秒`;
    }
  },
}
</script>

<style lang="scss" scoped>
.PinGroupContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 16px;
  margin: 8px 0;
  border-radius: 16px;


  .notice{
    padding: 2px 0;
    font-size: $minFontSize;
    color: #935511;
  }
  .timeout {
    margin-top: 15px;
    padding: 2px 0;
    font-size: $miniFontSize;
  }

  .groupInfo {
    padding: 2px 0;
    font-size: $smallerFontSize;

    .subTitle {
      color: #999999;
    }
  }

  .wantMore {
    font-size: $smallerFontSize;
    color: #2F438C;
  }

  img {
    width: 16px;
    height: 16px;
  }

  .title {
    font-weight: bold;
    padding: 0 4px;
  }
}
</style>